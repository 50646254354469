import React from "react";
import { View } from "react-native";
import { Paragraph } from "react-native-paper";

import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import { useWindowSize } from "../../shared/helpers";
import WeeklyPlanEntry from "./WeeklyPlanEntry";

export default function WeeklyPlanCategory({
    courseEntries,
    counter,
    course,
    props,
    reOrderCourse,
    deleteCustomerCourse,
    toggleHoverIn,
    toggleHoverOut,
    deleteCookingProcess,
    editCookingProcess,
    selectWeeklyPlanEntry,
    currentMonday,
    toggleCourseIndex,
    toggleDayIndex,
    selectedDay,
}) {
    const windowSize = useWindowSize();
    let isMobile = windowSize[0] <= 768;

    return (
        <View
            key={course.customerCoursesId}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
            }}
        >
            <View style={{ display: "flex", flexDirection: "row" }}>
                <View
                    style={{
                        width: 40,
                        backgroundColor: props.theme.colors.background,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Paragraph
                        style={{
                            transform: [{ rotate: "-90deg" }],
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: 108,
                        }}
                    >
                        {course.Course.name}
                    </Paragraph>
                </View>
            </View>
            {!isMobile && (
                <React.Fragment>
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        {getWeeklyPlanEntryByDayIndex(
                            courseEntries,
                            1,
                            counter,
                            course,
                            props,
                            toggleHoverIn,
                            toggleHoverOut,
                            deleteCookingProcess,
                            editCookingProcess,
                            selectWeeklyPlanEntry,
                            currentMonday,
                            toggleCourseIndex,
                            toggleDayIndex
                        )}
                    </View>
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        {getWeeklyPlanEntryByDayIndex(
                            courseEntries,
                            2,
                            counter,
                            course,
                            props,
                            toggleHoverIn,
                            toggleHoverOut,
                            deleteCookingProcess,
                            editCookingProcess,
                            selectWeeklyPlanEntry,
                            currentMonday,
                            toggleCourseIndex,
                            toggleDayIndex
                        )}
                    </View>
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        {getWeeklyPlanEntryByDayIndex(
                            courseEntries,
                            3,
                            counter,
                            course,
                            props,
                            toggleHoverIn,
                            toggleHoverOut,
                            deleteCookingProcess,
                            editCookingProcess,
                            selectWeeklyPlanEntry,
                            currentMonday,
                            toggleCourseIndex,
                            toggleDayIndex
                        )}
                    </View>
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        {getWeeklyPlanEntryByDayIndex(
                            courseEntries,
                            4,
                            counter,
                            course,
                            props,
                            toggleHoverIn,
                            toggleHoverOut,
                            deleteCookingProcess,
                            editCookingProcess,
                            selectWeeklyPlanEntry,
                            currentMonday,
                            toggleCourseIndex,
                            toggleDayIndex
                        )}
                    </View>
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        {getWeeklyPlanEntryByDayIndex(
                            courseEntries,
                            5,
                            counter,
                            course,
                            props,
                            toggleHoverIn,
                            toggleHoverOut,
                            deleteCookingProcess,
                            editCookingProcess,
                            selectWeeklyPlanEntry,
                            currentMonday,
                            toggleCourseIndex,
                            toggleDayIndex
                        )}
                    </View>
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        {getWeeklyPlanEntryByDayIndex(
                            courseEntries,
                            6,
                            counter,
                            course,
                            props,
                            toggleHoverIn,
                            toggleHoverOut,
                            deleteCookingProcess,
                            editCookingProcess,
                            selectWeeklyPlanEntry,
                            currentMonday,
                            toggleCourseIndex,
                            toggleDayIndex
                        )}
                    </View>
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        {getWeeklyPlanEntryByDayIndex(
                            courseEntries,
                            7,
                            counter,
                            course,
                            props,
                            toggleHoverIn,
                            toggleHoverOut,
                            deleteCookingProcess,
                            editCookingProcess,
                            selectWeeklyPlanEntry,
                            currentMonday,
                            toggleCourseIndex,
                            toggleDayIndex
                        )}
                    </View>
                </React.Fragment>
            )}
            {isMobile && (
                <React.Fragment>
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        {getWeeklyPlanEntryByDayIndex(
                            courseEntries,
                            selectedDay,
                            counter,
                            course,
                            props,
                            toggleHoverIn,
                            toggleHoverOut,
                            deleteCookingProcess,
                            editCookingProcess,
                            selectWeeklyPlanEntry,
                            currentMonday,
                            toggleCourseIndex,
                            toggleDayIndex
                        )}
                    </View>
                </React.Fragment>
            )}
            <View
                style={{
                    width: 40,
                    backgroundColor: props.theme.colors.background,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CustomIconButton
                    size={18}
                    color={props.theme.colors.error}
                    style={{
                        backgroundColor: props.theme.colors.background,
                    }}
                    icon={"delete"}
                    tooltip="Löschen"
                    position="left"
                    onPress={() => deleteCustomerCourse(course.customerCoursesId)}
                />
                <CustomIconButton
                    size={18}
                    color={props.theme.colors.primary}
                    style={{
                        backgroundColor: props.theme.colors.background,
                    }}
                    icon="chevron-up"
                    tooltip="Nach oben verschieben"
                    position="left"
                    onPress={() => reOrderCourse(course, -1)}
                />
                <CustomIconButton
                    size={18}
                    color={props.theme.colors.primary}
                    style={{
                        backgroundColor: props.theme.colors.background,
                    }}
                    icon="drag"
                    tooltip="Verschieben"
                    position="left"
                    onPress={() => reOrderCourse(course, 1)}
                />
                <CustomIconButton
                    size={18}
                    color={props.theme.colors.primary}
                    style={{
                        backgroundColor: props.theme.colors.background,
                    }}
                    icon="chevron-down"
                    tooltip="Nach unten verschieben"
                    position="left"
                    onPress={() => reOrderCourse(course, 1)}
                />
            </View>
        </View>
    );
}

function getWeeklyPlanEntryByDayIndex(
    courseEntries,
    dayIndex,
    counter,
    course,
    props,
    toggleHoverIn,
    toggleHoverOut,
    deleteCookingProcess,
    editCookingProcess,
    selectWeeklyPlanEntry,
    currentMonday,
    toggleCourseIndex,
    toggleDayIndex
) {
    return (
        <WeeklyPlanEntry
            course={courseEntries}
            dayIndex={dayIndex}
            counter={counter}
            customerCoursesId={course.customerCoursesId}
            toggleHoverIn={toggleHoverIn}
            toggleHoverOut={toggleHoverOut}
            deleteCookingProcess={deleteCookingProcess}
            editCookingProcess={editCookingProcess}
            selectWeeklyPlanEntry={selectWeeklyPlanEntry}
            currentMonday={currentMonday}
            toggleCourseIndex={toggleCourseIndex}
            toggleDayIndex={toggleDayIndex}
            theme={props.theme}
        />
    );
}
