import React from "react";
import { View } from "react-native";

import { useWindowSize } from "../../../shared/helpers";
import ColumnContent from "./ColumnContent";

export default function RowColumn({ width, articleDetailsContents }) {
    const windowSize = useWindowSize();
    return (
        <View nativeID="rowColumn" style={{ width: windowSize[0] > 767 ? width : "100%", paddingHorizontal: 10 }}>
            {articleDetailsContents
                .sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1))
                .map((articleDetailsContent) => {
                    return (
                        <ColumnContent
                            key={articleDetailsContent.articleDetailsContentsId}
                            size={articleDetailsContent.size}
                            {...articleDetailsContent}
                        />
                    );
                })}
        </View>
    );
}
