import React, { useState, useEffect } from "react";
import { View, FlatList, Text, Image, Pressable, StyleSheet } from "react-native";
import { TextInput } from "react-native-paper";
import Config from "../../../../Config";

const config = new Config();

const AutocompleteRecipe = ({
    recipes = [],
    onRecipeSelect = () => {},
    searchLabel = "Rezept suchen",
    selectedRecipeLabel = "Ausgewähltes Rezept:",
    inputBorderColor = "black",
    inputBackgroundColor = "white",
    inputTextColor = "black",
    listBorderColor = "black",
    listBackgroundColor = "white",
    listTextColor = "black",
    hoverBackgroundColor = "#f2f2f2",
    hoverTextColor = "black",
}) => {
    const [filteredRecipes, setFilteredRecipes] = useState([]);
    const [query, setQuery] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [hoveredItemId, setHoveredItemId] = useState(null);

    useEffect(() => {
        setFilteredRecipes(recipes);
    }, [recipes]);

    const handleSearch = (text) => {
        setQuery(text);
        const formattedQuery = text.toLowerCase();
        const filteredData = recipes.filter((recipe) => {
            return recipe.name.toLowerCase().includes(formattedQuery);
        });
        setFilteredRecipes(filteredData);
    };

    const handleSelectRecipe = (item) => {
        setSelectedRecipe(item);
        setIsFocused(false);
        onRecipeSelect(item);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!selectedRecipe) {
                setIsFocused(false);
            }
        }, 100);
    };

    const renderItem = ({ item }) => (
        <Pressable
            style={[styles.item, hoveredItemId === item.id && { backgroundColor: hoverBackgroundColor }]}
            onPress={() => handleSelectRecipe(item)}
            onMouseEnter={() => setHoveredItemId(item.id)}
            onMouseLeave={() => setHoveredItemId(null)}
        >
            {item.guidFileName && (
                <Image source={{ uri: config.imageStorageBlobURL + item.guidFileName }} style={styles.image} />
            )}
            <Text style={[styles.text, { color: hoveredItemId === item.id ? hoverTextColor : listTextColor }]}>
                {item.name}
            </Text>
        </Pressable>
    );

    return (
        <View style={styles.container}>
            <TextInput
                mode="outlined"
                label={searchLabel}
                value={query}
                onChangeText={handleSearch}
                style={[styles.input, { borderColor: inputBorderColor, backgroundColor: inputBackgroundColor }]}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                theme={{ colors: { text: inputTextColor } }}
            />
            {selectedRecipe && (
                <View style={[styles.selectedRecipeContainer]}>
                    <Text style={styles.selectedRecipeLabel}>{selectedRecipeLabel}</Text>
                    <View
                        style={[
                            styles.selectedRecipe,
                            { borderColor: listBorderColor, backgroundColor: listBackgroundColor },
                        ]}
                    >
                        {selectedRecipe.guidFileName && (
                            <Image
                                source={{ uri: config.imageStorageBlobURL + selectedRecipe.guidFileName }}
                                style={styles.image}
                            />
                        )}
                        <Text style={[styles.text, { color: listTextColor }]}>{selectedRecipe.name}</Text>
                    </View>
                </View>
            )}
            {isFocused && (
                <View
                    style={[
                        styles.listContainer,
                        { borderColor: listBorderColor, backgroundColor: listBackgroundColor },
                    ]}
                >
                    <FlatList
                        data={filteredRecipes}
                        renderItem={renderItem}
                        keyExtractor={(item) => item.id.toString()}
                        style={styles.list}
                    />
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 10,
    },
    input: {
        marginBottom: 10,
    },
    list: {
        maxHeight: 200,
    },
    listContainer: {
        position: "absolute",
        top: 60,
        width: "100%",
        zIndex: 1,
    },
    item: {
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: "#ddd",
    },
    image: {
        width: 40,
        height: 40,
        marginRight: 10,
        borderRadius: 20,
    },
    text: {
        fontSize: 16,
    },
    selectedRecipeContainer: {
        marginTop: 10,
    },
    selectedRecipeLabel: {
        fontSize: 16,
        fontWeight: "bold",
    },
    selectedRecipe: {
        flexDirection: "row",
        alignItems: "center",
        borderWidth: 2,
        borderRadius: 7,
        padding: 10,
        marginTop: 10,
    },
});

export default AutocompleteRecipe;
