import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export function postOrder(order) {
    if (order) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ webShopOrder: order, printBill: order.printBill, printRecipe: order.printRecipe }),
        };
        return function (dispatch) {
            return fetch(config.backendHost + "/OnlineShop/postOrder", requestOptions).then((response) =>
                handleResponse(response)
            );
        };
    }
}

export function getOrderStatusByOrderId(orderId) {
    if (orderId) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ orderId: orderId }),
        };
        return function (dispatch) {
            return fetch(config.backendHost + "/Orders/getOrderStatusByOrderId", requestOptions).then((response) =>
                handleResponse(response)
            );
        };
    }
}

export function getOrderStatusByUid(uid) {
    if (uid) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(uid),
        };
        return function (dispatch) {
            return fetch(config.backendHost + "/Orders/getOrderStatusByUid", requestOptions).then((response) =>
                handleResponse(response)
            );
        };
    }
}

export const SET_ORDER = "SET_ORDER";

export function setOrder(order) {
    return {
        type: SET_ORDER,
        order: order,
    };
}
