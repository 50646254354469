import React from "react";
import { DataTable } from "react-native-paper";

export default function Additives(data) {
    let additives = data.data;
    return (
        <DataTable>
            {(!additives || additives.length === 0) && (
                <DataTable.Row>
                    <DataTable.Cell>Keine</DataTable.Cell>
                </DataTable.Row>
            )}
            {additives && additives.length > 0 && (
                <>
                    {additives.map((item, index) => (
                        <DataTable.Row key={index}>
                            <DataTable.Cell>{item.name}</DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </>
            )}
        </DataTable>
    );
}
