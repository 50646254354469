import React, { Component } from "react";
import { ActivityIndicator, View } from "react-native";
import { withTheme } from "react-native-paper";

import { withRouter } from "../../../routers/routing";
import { withHooksHOC } from "../../../shared/helpers";
import { getArticleDetailsByArticlesId } from "../../../store/actions/onlineShopCategoriesActions";
import { store } from "../../../store/store";
import DetailsRow from "./DetailsRow";

class ArticleDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            articleDetailsRows: null,
            isDesktop: false,
        };
    }

    render() {
        const { articleDetailsRows, isDesktop } = this.props;
        return (
            <View
                nativeID="articleDetails"
                style={{ marginBottom: 50, marginLeft: isDesktop ? 0 : 30, marginRight: isDesktop ? 0 : 30 }}
            >
                {articleDetailsRows && (
                    <View nativeID="articleDetailsContainer">
                        {articleDetailsRows.map((articleDetailsRow) => (
                            <DetailsRow
                                key={articleDetailsRow.articleDetailsRowsId}
                                articleDetailsRow={articleDetailsRow}
                            />
                        ))}
                    </View>
                )}
            </View>
        );
    }
}

export default withRouter(ArticleDetails);
