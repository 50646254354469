import React, { Component } from "react";
import { ActivityIndicator, View } from "react-native";
import { Card, Divider, Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { desktopBreakpoint, withHooksHOC } from "../../shared/helpers";
import { removeAllOrderItems } from "../../store/actions/cartActions";
import { getOrderStatusByUid } from "../../store/actions/orderActions";
import { getOrderStatus, setOrderStatus, start } from "../../store/actions/orderStatusActions";
import { checkAndBookPayment } from "../../store/actions/paymentActions";
import { store } from "../../store/store";
import ArticleList from "./ArticleList";
import CustomerInformations from "./CustomerInformations";
import OrderInformations from "./OrderInformations";

class OrderTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ loading: true });
        store.dispatch(setOrderStatus(undefined));
        store.dispatch(getOrderStatusByUid(id)).then((result) => {
            this.setState({ loading: false });
            store.dispatch(removeAllOrderItems());
            store.dispatch(setOrderStatus(result));
        });
        setInterval(() => {
            if (!this.props.orderStatus.data || this.props.orderStatus.data.orderStateString !== "Abgeschlossen") {
                store.dispatch(getOrderStatusByUid(id)).then((result) => {
                    store.dispatch(setOrderStatus(result));
                });
            }
        }, 10000);
    }

    render() {
        const { orderStatus } = this.props;
        const id = orderStatus.data ? orderStatus.data.uid : null;
        const { windowSize } = this.props;
        let mobile = windowSize[0] < desktopBreakpoint;
        return (
            <View
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <View style={{ width: mobile ? 350 : 800 }}>
                    {(!id || orderStatus.data === false) && (
                        <Text variant="headlineSmall">Keine passende Bestellung gefunden</Text>
                    )}
                    {id && orderStatus.data === null && (
                        <View>
                            <ActivityIndicator size="large" />
                        </View>
                    )}
                    {id && (
                        <View>
                            {orderStatus.data && (
                                <Card style={{ marginTop: 50, marginLeft: mobile ? 5 : 50, borderRadius: 13 }}>
                                    <Card.Title
                                        style={{ marginTop: 30 }}
                                        title={
                                            <Text variant="headlineSmall">
                                                Ihre Bestellung (Nr. {orderStatus.data.pizzaManagerId})
                                            </Text>
                                        }
                                    />
                                    <Card.Content>
                                        <View>
                                            <OrderInformations data={orderStatus.data} />
                                            <Divider style={{ marginVertical: 20 }} />
                                            <CustomerInformations customer={orderStatus.data.customer} />
                                            <Divider style={{ marginVertical: 20 }} />
                                            <ArticleList cartJson={orderStatus.data.cartJson} />
                                        </View>
                                    </Card.Content>
                                </Card>
                            )}
                        </View>
                    )}
                </View>
                {this.state.loading && (
                    <ActivityIndicator
                        size={"large"}
                        style={{ marginTop: 100 }}
                        animated
                        color={this.props.theme.colors.primary}
                    />
                )}
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { orderStatus } = state;
    return { orderStatus };
}

export default connect(mapStateToProps)(withRouter(withTheme(withHooksHOC(OrderTracking))));
