import React, { useEffect, useState } from "react";
import { View } from "react-native";

import { useWindowSize, withHooksHOC } from "../../../shared/helpers";
import RowColumn from "./RowColumn";

export default function DetailsRow({ articleDetailsRow }) {
    const [columns, setColumns] = useState([]);
    const { articleDetailsRowsId, widths, columnsCount, ArticleDetailsContents } = articleDetailsRow;
    const windowSize = useWindowSize();
    useEffect(() => {
        let columnsWidths = widths.split(",");
        let tempColumns = [];
        for (let i = 1; i <= columnsCount; i++) {
            tempColumns.push(
                <RowColumn
                    key={i}
                    articleDetailsRowsId={articleDetailsRowsId}
                    column={i}
                    width={columnsWidths[i - 1]}
                    articleDetailsContents={getArticleDetailsContentsByColumn(i)}
                />
            );
        }

        if (columns.length !== tempColumns.length) {
            setColumns(tempColumns);
        }
    });

    const getArticleDetailsContentsByColumn = (column) => {
        let articleDetailsContents = ArticleDetailsContents.filter(
            (articleDetailsContent) => articleDetailsContent.column === column
        );
        return articleDetailsContents;
    };

    return (
        <View nativeID="detailsRow" style={{ flexDirection: windowSize[0] > 767 ? "row" : "column", padding: 10 }}>
            {columns}
        </View>
    );
}
