import Color from "color";
import { DefaultTheme } from "react-native-paper";

import Config from "../../../Config";
import { loadFont } from "../../helpers/fontLoader/fontLoader";
import { handleResponse } from "../../helpers/requestHelpers";
import { store } from "../store";

const config = new Config();

export const SET_SETTINGS = "SET_SETTINGS";

export function setSettings(settings) {
    return {
        type: SET_SETTINGS,
        store: settings,
    };
}

export function getSettings() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/getShopSettings", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                const theme = {
                    ...DefaultTheme,
                    title: json.title,
                    logoUrl: json.logoUrl,
                    // roundness: 1,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: json.primaryColor,
                        accent: json.accentColor,
                        background: json.backgroundColor,
                        surface: json.surfaceColor,
                        error: json.errorColor,
                        text: json.textColor,
                        disabled: json.disabledColor,
                        placeholder: json.placeholderColor,
                        backdrop: json.backdropColor,
                        notification: json.notificationColor,
                        onSurface: json.onSurfaceColor,
                    },
                    fonts: {
                        ...DefaultTheme.fonts,
                        regular: {
                            ...DefaultTheme.fonts.regular,
                            fontFamily: "regular",
                            fontWeight: json.regularFontWeight,
                        },
                        medium: {
                            ...DefaultTheme.fonts.medium,
                            fontFamily: "medium",
                            fontWeight: json.mediumFontWeight,
                        },
                        light: {
                            ...DefaultTheme.fonts.light,
                            fontFamily: "light",
                            fontWeight: json.lightFontWeight,
                        },
                        thin: {
                            ...DefaultTheme.fonts.thin,
                            fontFamily: "thin",
                            fontWeight: json.thinFontWeight,
                        },
                    },
                    customCSS: json.customCSS,
                    regularFontPath: json.regularFontPath,
                    mediumFontPath: json.mediumFontPath,
                    lightFontPath: json.lightFontPath,
                    thinFontPath: json.thinFontPath,
                };

                loadFont(json);
                const styleElement = document.createElement("style");
                styleElement.textContent = json.customCSS;
                document.head.appendChild(styleElement);

                store.dispatch(setSettings(json));
                store.dispatch(set_theme(theme));

                return theme;
            });
    };
}

export const SET_THEME = "SET_THEME";

export function set_theme(theme) {
    if (theme) {
        // if (document.getElementById("customCSS")) {
        //     document.getElementById("customCSS").remove();
        // }

        // const customStyle = document.createElement("style");
        // customStyle.setAttribute("id", "customCSS");
        // document.head.appendChild(customStyle);
        // customStyle.innerHTML = theme.customCSS ? theme.customCSS : "";

        return {
            type: SET_THEME,
            theme: theme,
        };
    }

    return { type: SET_THEME };
}

export const SET_SEARCHTEXT = "SET_SEARCHTEXT";

export function setSearchText(searchText) {
    return {
        type: SET_SEARCHTEXT,
        searchText,
    };
}
