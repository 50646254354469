import React, { Component } from "react";
import { Dimensions, Image, Pressable, StyleSheet, View } from "react-native";
import { Appbar, Icon, IconButton, Text, TextInput, withTheme } from "react-native-paper";
import { connect } from "react-redux";

import Config from "../../../Config";
import { Link, withRouter } from "../../routers/routing";
import { withHooksHOC, getContrastColor, desktopBreakpoint } from "../../shared/helpers";
import { SET_SEARCHTEXT } from "../../store/actions/settingsActions";
import { store } from "../../store/store";
import { headerStyles } from "./HeaderStyle";
import CustomMenu from "../../helpers/overlayMenu/CustomMenu";
import CustomMenuItem from "../../helpers/overlayMenu/CustomMenuItem";

const config = new Config();

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            searchActive: true,
        };
        this.searchInputRef = React.createRef();
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.toggleSearchBar = this.toggleSearchBar.bind(this);
        this.handleSearchTextChanged = this.handleSearchTextChanged.bind(this);
        this.deleteSearchInput = this.deleteSearchInput.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    handleLogin() {
        const { account } = this.props;

        if (account.customer) {
            this.props.logout();
        } else {
            this.props.toggleLoginPopup();
        }
    }

    toggleSearchBar() {
        if (!this.state.searchActive) {
            this.searchInputRef.current.focus();
        }
        store.dispatch({ type: SET_SEARCHTEXT, searchText: "" });
        this.setState({
            searchActive: true,
        });
    }

    deleteSearchInput() {
        if (!this.state.searchActive) {
            this.searchInputRef.current.focus();
        }
        store.dispatch({ type: SET_SEARCHTEXT, searchText: "" });
        this.setState({
            searchActive: true,
        });
    }

    handleSearchTextChanged(text) {
        store.dispatch({ type: SET_SEARCHTEXT, searchText: text });
    }

    render() {
        const { theme, account, cart, onCartClick } = this.props;
        const { windowWidth } = this.state;
        return (
            <React.Fragment>
                <Appbar.Header
                    style={{
                        backgroundColor: theme.colors.primary,
                        ...(windowWidth >= desktopBreakpoint ? headerStyles.appbar : headerStyles.appbarMobile),
                    }}
                >
                    {theme.logoUrl && (
                        <Image
                            source={config.imageStorageBlobURL + theme.logoUrl}
                            style={windowWidth >= desktopBreakpoint ? headerStyles.logo : headerStyles.logoMobile}
                            resizeMode="contain"
                            onClick={() => this.props.history.push("/")}
                        />
                    )}
                    {theme && theme.title !== "" && (
                        <Text
                            style={{
                                fontFamily: theme.fonts.regular.fontFamily,
                                fontWeight: theme.fonts.regular.fontWeight,
                                color: getContrastColor(theme.colors.primary),
                                display: windowWidth >= desktopBreakpoint ? "flex" : "none",
                            }}
                        >
                            {theme.title}
                        </Text>
                    )}
                    <View
                        style={{
                            ...StyleSheet.flatten(
                                windowWidth >= desktopBreakpoint
                                    ? headerStyles.searchActiveView
                                    : headerStyles.searchActiveViewMobile
                            ),
                            borderColor: getContrastColor(theme.colors.primary),
                        }}
                    >
                        <IconButton
                            iconColor={getContrastColor(theme.colors.primary)}
                            icon="magnify"
                            onPress={this.toggleSearchBar}
                        />
                        <TextInput
                            style={
                                this.state.searchActive
                                    ? {
                                          ...StyleSheet.flatten(headerStyles.searchActiveTextInput),
                                          backgroundColor: "transparent",
                                      }
                                    : headerStyles.searchInactiveTextInput
                            }
                            onChangeText={(text) => this.handleSearchTextChanged(text)}
                            ref={this.searchInputRef}
                            value={this.props.settings.searchText}
                            underlineColor="transparent"
                            placeholder="Suche..."
                            textColor={getContrastColor(theme.colors.primary)}
                            placeholderTextColor={getContrastColor(theme.colors.primary)}
                        />
                        {this.props.settings.searchText !== "" && (
                            <IconButton
                                iconColor={getContrastColor(theme.colors.primary)}
                                icon="alpha-x-circle-outline"
                                onPress={this.deleteSearchInput}
                            />
                        )}
                    </View>
                    {windowWidth > desktopBreakpoint && (
                        <View nativeID="menu" style={headerStyles.menu}>
                            {account.customer && (
                                <View nativeID="links" style={headerStyles.links}>
                                    <Pressable
                                        onPress={() => this.props.history.push("/wochenplan")}
                                        style={headerStyles.buttonIcons}
                                    >
                                        <Icon
                                            source="calendar-month-outline"
                                            size={20}
                                            color={getContrastColor(theme.colors.primary)}
                                        />

                                        <Text
                                            style={[
                                                headerStyles.buttonsText,
                                                { color: getContrastColor(theme.colors.primary) },
                                            ]}
                                        >
                                            Wochenplan
                                        </Text>
                                    </Pressable>
                                    <Pressable>
                                        <a
                                            style={{
                                                ...StyleSheet.flatten(headerStyles.link),
                                                ...StyleSheet.flatten(headerStyles.buttonIcons),
                                                display: "flex",
                                                flexDirection: "column",
                                                color: getContrastColor(theme.colors.primary),
                                            }}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                            href={
                                                this.props.settings.store.urlBasePath +
                                                "guest/allCookingProcessesCustomer/" +
                                                account.customer.customerUid +
                                                "/p/"
                                            }
                                        >
                                            <Icon
                                                source="monitor"
                                                size={20}
                                                color={getContrastColor(theme.colors.primary)}
                                            />
                                            <Text
                                                style={[
                                                    headerStyles.buttonsText,
                                                    { color: getContrastColor(theme.colors.primary) },
                                                ]}
                                            >
                                                Kochmonitor
                                            </Text>
                                        </a>
                                    </Pressable>
                                    <Pressable
                                        style={headerStyles.buttonIcons}
                                        onPress={() => this.props.history.push("/shoppingList")}
                                    >
                                        <Icon
                                            source="file-document-edit-outline"
                                            size={20}
                                            color={getContrastColor(theme.colors.primary)}
                                        />
                                        <Text
                                            style={[
                                                headerStyles.buttonsText,
                                                { color: getContrastColor(theme.colors.primary) },
                                            ]}
                                        >
                                            Einkaufsliste
                                        </Text>
                                    </Pressable>
                                </View>
                            )}
                            <View nativeID="buttons" style={headerStyles.buttons}>
                                <Pressable style={headerStyles.buttonIcons} onPress={onCartClick}>
                                    <Icon
                                        source="cart-outline"
                                        size={20}
                                        color={getContrastColor(theme.colors.primary)}
                                    />
                                    <Text
                                        style={[
                                            headerStyles.buttonsText,
                                            { color: getContrastColor(theme.colors.primary) },
                                        ]}
                                    >
                                        Warenkorb{cart.orderItems ? " (" + cart.orderItems.length + ")" : ""}
                                    </Text>
                                </Pressable>
                                {account.customer && (
                                    <View style={{ zIndex: 2000, marginRight: 20 }}>
                                        <CustomMenu
                                            anchor={
                                                <>
                                                    <Icon
                                                        source="account-outline"
                                                        size={20}
                                                        color={getContrastColor(theme.colors.primary)}
                                                    />
                                                    <Text
                                                        style={[
                                                            headerStyles.buttonsText,
                                                            { color: getContrastColor(theme.colors.primary) },
                                                        ]}
                                                    >
                                                        Hallo, {account.customer.firstName}!
                                                    </Text>
                                                </>
                                            }
                                            direction="down"
                                            menuItemBackgroundColor={theme.colors.accent}
                                            menuItemTextColor={getContrastColor(theme.colors.accent)}
                                        >
                                            <CustomMenuItem
                                                onPress={() => this.props.history.push("/customer/")}
                                                title="Mein Konto"
                                            />
                                            <CustomMenuItem onPress={() => this.handleLogin()} title="Logout" />
                                        </CustomMenu>
                                    </View>
                                )}
                                {!account.customer && (
                                    <Pressable onPress={() => this.handleLogin()} style={headerStyles.buttonIcons}>
                                        <Icon source="login" size={20} color={getContrastColor(theme.colors.primary)} />
                                        <Text
                                            style={[
                                                headerStyles.buttonsText,
                                                { color: getContrastColor(theme.colors.primary) },
                                            ]}
                                        >
                                            Anmelden
                                        </Text>
                                    </Pressable>
                                )}
                            </View>
                        </View>
                    )}
                </Appbar.Header>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, cart } = state;
    return { settings, account, cart };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(withTheme(Header))));
