import { Platform, StyleSheet } from "react-native";
import { Colors, useTheme } from "react-native-paper";

export const ShopStyles = StyleSheet.create({
    container: {
        zIndex: 1,
        top: -210,
        marginTop: 20,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        flexWrap: "wrap",
        maxWidth: "100%",
    },
    containerMobile: {
        zIndex: 1,
        top: -110,
        marginTop: 20,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        flexWrap: "wrap",
        maxWidth: "100%",
    },
    CardWrapperHover: {
        zIndex: 50,
    },
    CardMobileWrapper: {
        // paddingBottom: 120,
        // marginBottom: 20,
        height: 220,
    },
    Card: {
        marginRight: 10,
        marginLeft: 10,
        width: 300,
        height: 200,

        borderRadius: 8,
        overflow: "hidden",
    },
    HoveredCard: {
        marginRight: 10,
        marginLeft: 10,
        width: 300,
        height: 200,
        borderRadius: 8,
        boxShadow: "0 0 0.5em black",
        zIndex: 3,
        overflow: "hidden",
    },
    CardMobile: {
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 20,
        width: 170,
        height: 120,
        borderRadius: 8,
        overflow: "hidden",
    },
    Category: { height: 350 },
    CategoryMobile: {
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // flexDirection: "row",
        // height: 120,
        height: 220,
        // marginBottom: 10,
    },
    nextButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: 0,
        marginBottom: "auto",
        marginTop: "auto",
        height: 350,
        width: 50,
        zIndex: 2,
    },

    previousButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: 0,
        marginBottom: "auto",
        marginTop: "auto",
        height: 350,
        width: 50,
        zIndex: 2,
    },
    CategoryContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        overflow: "visible",
    },
    CategoryRow: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
    },
    Icon: {
        position: "absolute",
        top: -5,
        left: -5,
        paddingLeft: 10,
        paddingTop: 10,
        borderRadius: 8,
    },
    Rating: {
        position: "absolute",
        top: -5,
        right: -5,
        paddingRight: 10,
        borderRadius: 8,
        height: 50,
        width: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    ShopItemInfoAreaMobile: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        marginLeft: 5,
        marginRight: 5,
    },
    ShopItemInfoAreaMobileTitle: {
        fontSize: 12,
        width: 170,
        textAlign: "center",
    },
    ShopItemInfoAreaMobilePriceWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
    },
    ShopItemInfoAreaMobilePrice: {
        fontSize: 18,
        marginTop: 5,
        marginRight: 5,
    },
    ShopItemInfoAreaMobileBasePrice: {
        fontSize: 10,
        marginRight: 5,
        marginTop: 5,
        marginBottom: 5,
    },
    ShopItemInfoArea: {
        position: "absolute",
        width: "100%",
        height: "40%",
        bottom: 0,
        left: 0,
        paddingLeft: 10,
        paddingTop: 10,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },
    ShopItemInfoAreaIconButtonWrapper: {
        borderWidth: 1,
        borderRadius: 25,
        zIndex: 1000,
        padding: 5,
        marginLeft: 5,
    },
    Banner: {
        position: "absolute",
        bottom: 50,
        left: -5,
        fontSize: 16,
        padding: 5,
        paddingLeft: 10,
        borderRadius: 6,
        maxWidth: 260,
    },
    BannerMobile: {
        position: "absolute",
        bottom: 50,
        left: -5,
        fontSize: 12,
        padding: 5,
        paddingLeft: 10,
        borderRadius: 6,
        maxWidth: 140,
    },
    HeaderItemContainer: {
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    HeaderItemContainerMobile: {
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    HeaderImage: {
        width: "100vw",
        minHeight: 600,
        display: "flex",
    },
    HeaderImageMobile: {
        width: "100vw",
        minHeight: 300,
        display: "flex",
    },
    ProductDetailsHeader: {
        width: "100vw",
        display: "flex",
    },
    HeaderLeft: {
        width: "100vw",
        marginLeft: 90,
        paddingTop: 30,
        marginBottom: 20,
        paddingRight: 120,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    HeaderLeftMobile: {
        width: "100vw",
        marginLeft: 0,
        paddingTop: 30,
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    HeaderButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        maxWidth: "100vw",
    },
    HeaderButtonsMobile: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100vw",
    },
    HeaderContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginBottom: 10,
    },
    HeaderContainerMobile: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginBottom: 70,
    },
    HeaderListContainer: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100vw",
    },
    HeaderList: {
        width: "100%",
        height: 126,
        flex: 1,
        height: "95%",
        marginBottom: 10,
    },
    description: {
        // width: 400,
        maxWidth: 550,
        height: "auto",
        fontSize: 18,
        fontWeight: 400,
        fontFamily: "sans-serif",
        textAlign: "left",
        textShadowRadius: 5,
    },
    descriptionMobile: {
        width: "80vw",
        fontSize: 14,
        fontWeight: 400,
        fontFamily: "sans-serif",
        textAlign: "center",
        textShadowRadius: 5,
    },
    HeaderCategory: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 15,
        marginTop: 0,
    },
    ShopItemCategory: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 15,
        marginTop: 0,
    },
    HeaderCategoryMobile: {
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 15,
        marginTop: 10,
        width: "70vw",
        textAlign: "center",
    },
});
