import React, { Component } from "react";
import { View } from "react-native";
import { AirbnbRating } from "react-native-ratings";
import { Dialog, TextInput, Title, Button, withTheme, Text, Portal } from "react-native-paper";

class RateArticleDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            rating: this.props.rating,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rating !== this.props.rating) {
            this.setState({ rating: this.props.rating });
        }
    }

    handleRatingComplete = (rating) => {
        this.setState({ rating });
    };

    render() {
        const { theme, mobile } = this.props;
        return (
            <Portal style={{}}>
                <Dialog
                    visible={this.props.visible}
                    onDismiss={this.props.toggleRateArticleDialog}
                    style={{
                        left: "30vw",
                        top: 90,
                        position: "fixed",
                        width: mobile ? 300 : 500,
                        height: 300,
                    }}
                >
                    <Dialog.Title>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Title>{this.props.type == "0" ? "Artikel bewerten" : "Rezept bewerten"}</Title>

                            <AirbnbRating
                                type="star"
                                count={5}
                                defaultRating={this.state.rating}
                                size={22}
                                onFinishRating={this.handleRatingComplete}
                                showRating={false}
                                ratingContainerStyle={{
                                    backgroundColor: "transparent",
                                }}
                            />
                        </View>
                    </Dialog.Title>
                    <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <TextInput
                            multiline
                            mode="outlined"
                            label="Fügen Sie Ihrer Bewertung einen Text hinzu"
                            value={this.state.text}
                            onChangeText={(text) => this.setState({ text: text })}
                            style={{ width: mobile ? 200 : 350 }}
                        />
                    </View>
                    <Button
                        style={{
                            backgroundColor: theme.colors.primary,
                            position: "absolute",
                            bottom: 20,
                            right: 20,
                        }}
                        onPress={() => this.props.rateArticle(this.state.rating, this.state.text)}
                    >
                        <Text>Bewertung abgeben</Text>
                    </Button>
                </Dialog>
            </Portal>
        );
    }
}

export default withTheme(RateArticleDialog);
