const { StyleSheet } = require("react-native");

export const styles = StyleSheet.create({
    chooseRecipeDialogSelect: {
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 5,
    },
    chooseRecipeDialogSelectLabel: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 15,
    },
    chooseRecipeDialogButton: {
        paddingHorizontal: 15,
        paddingVertical: 2,
        marginLeft: 5,
    },
});
