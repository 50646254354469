import React, { Component, useState } from "react";
import { ScrollView, View, Dimensions, Pressable } from "react-native";
import { Button, Dialog, Portal, RadioButton, Text, TextInput, Title } from "react-native-paper";
import { connect } from "react-redux";

import { updateAddress } from "../../store/actions/accountActions";
import { store } from "../../store/store";
import CustomInputField from "../../helpers/inputField/CustomInputField";
import * as yup from "yup";
import { desktopBreakpoint, getContrastColor } from "../../shared/helpers";

const yupObject = yup.object().shape({
    firstName: yup.string().required("Bitte einen Vornamen eingeben"),
    lastName: yup.string().required("Bitte einen Nachnamen eingeben"),
    zipCode: yup
        .string()
        .matches(/^\d{5}$/, "Ungültige Postleitzahl")
        .required("Bitte Postleitzahl eingeben"),
    city: yup.string().required("Bitte eine Stadt eingeben"),
    street: yup.string().required("Bitte den Straßennamen eingeben"),
    houseNumber: yup.string().required("Bitte geben Sie eine Hausnummer ein"),
});

class AddAddressPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: "",
            street: "",
            houseNumber: "",
            zipCode: "",
            firstName: "",
            lastName: "",
            title: "Herr",
            company: "",
            addressDetails: "",
            addressIndex: undefined,
            isDefaultDeliveryAddress: false,
            isDefaultBillingAddress: false,
            tempTitle: "Herr",
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            errors: {},
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        const { customer } = this.props.account;
        Dimensions.addEventListener("change", this.updateDimensions);

        if (this.props.edit) {
            this.setState({
                street: this.props.addressToEdit.Street.name,
                city: this.props.addressToEdit.Street.City.name,
                company: this.props.addressToEdit.company,
                addressDetails: this.props.addressToEdit.addressDetails,
                houseNumber: this.props.addressToEdit.housenumber,
                zipCode: this.props.addressToEdit.Street.City.zipCode,
                title: this.props.addressToEdit.title,
                tempTitle: this.props.addressToEdit.title,
                firstName: this.props.addressToEdit.firstName,
                lastName: this.props.addressToEdit.lastName,
                isDefaultDeliveryAddress: this.props.addressToEdit.isDefaultDeliveryAddress,
                isDefaultBillingAddress: this.props.addressToEdit.isDefaultBillingAddress,
                addressIndex: this.props.addressIndex,
                addressUid: this.props.addressToEdit.addressUid,
            });
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { visible, togglePopup, numberOfAddressesSaved, account, update, theme } = this.props;
        const { customer } = this.props.account;

        var hasErrors = {
            formErrors: true,
        };

        let data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            zipCode: this.state.zipCode,
            city: this.state.city,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
        };
        this.setState({ errors: {} });
        yupObject
            .validate(data, { abortEarly: false })
            .then(() => {
                hasErrors.formErrors = false;

                let indexToUpdate = store
                    .dispatch(
                        updateAddress(
                            account.customer.customerUid,
                            this.state.title ? this.state.title : customer.title,
                            this.state.firstName ? this.state.firstName : customer.firstName,
                            this.state.lastName ? this.state.lastName : customer.familyName,
                            this.props.edit ? this.state.addressIndex : numberOfAddressesSaved,
                            this.state.city,
                            this.state.street,
                            this.state.houseNumber,
                            this.state.zipCode,
                            this.state.company,
                            this.state.addressDetails,
                            this.state.isDefaultBillingAddress,
                            this.state.isDefaultDeliveryAddress,
                            this.state.addressUid
                        )
                    )
                    .then(() => {
                        update();
                        togglePopup();
                    });
            })
            .catch((err) => {
                const errors = {};
                err.inner.forEach((errElement) => {
                    errors[errElement.path] = errElement.message;
                });
                this.setState({ errors });
            });
    };

    render() {
        const { visible, togglePopup, numberOfAddressesSaved, account, update, theme } = this.props;
        const { customer } = this.props.account;
        const { windowWidth, errors } = this.state;

        return (
            <Portal>
                <Dialog
                    visible={this.props.visible}
                    onDismiss={() => {
                        this.props.togglePopup();
                    }}
                    style={{
                        width: windowWidth <= desktopBreakpoint ? "90%" : 400,
                        top: "10vh",
                        left: windowWidth <= desktopBreakpoint ? "5%" : "calc(50% - 200px)",
                        marginLeft: "auto",
                        marginRight: "auto",
                        position: "fixed",
                        zIndex: 1,
                        maxHeight: "90vh",
                    }}
                    dismissable={true}
                >
                    <Dialog.ScrollArea>
                        <ScrollView>
                            <Dialog.Title>
                                {this.props.edit ? "Adresse bearbeiten" : "Neue Adresse hinzufügen"}
                            </Dialog.Title>

                            <Dialog.Content>
                                <Text>Anrede</Text>

                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <Pressable
                                        onPress={(event) => {
                                            this.setState({ title: "Herr" });
                                            this.setState({ tempTitle: "Herr" });
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                                            <RadioButton.IOS
                                                value="Herr"
                                                status={this.state.tempTitle === "Herr" ? "checked" : "unchecked"}
                                                onPress={(event) => {
                                                    this.setState({ title: "Herr" });
                                                    this.setState({ tempTitle: "Herr" });
                                                }}
                                            />
                                            <Text>Herr</Text>
                                        </View>
                                    </Pressable>
                                    <Pressable
                                        onPress={(event) => {
                                            this.setState({ title: "Frau" });
                                            this.setState({ tempTitle: "Frau" });
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                                            <RadioButton.IOS
                                                value="Frau"
                                                status={this.state.tempTitle === "Frau" ? "checked" : "unchecked"}
                                                onPress={(event) => {
                                                    this.setState({ title: "Frau" });
                                                    this.setState({ tempTitle: "Frau" });
                                                }}
                                            />
                                            <Text>Frau</Text>
                                        </View>
                                    </Pressable>
                                    <Pressable
                                        onPress={(event) => {
                                            this.setState({ title: "Divers" });
                                            this.setState({ tempTitle: "Divers" });
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                                            <RadioButton.IOS
                                                value="Divers"
                                                status={this.state.tempTitle === "Divers" ? "checked" : "unchecked"}
                                                onPress={(event) => {
                                                    this.setState({ title: "Divers" });
                                                    this.setState({ tempTitle: "Divers" });
                                                }}
                                            />
                                            <Text>Divers</Text>
                                        </View>
                                    </Pressable>
                                </View>

                                <CustomInputField
                                    label="Vorname"
                                    error={errors.firstName ? true : false}
                                    helperText={errors.firstName ? errors.firstName : ""}
                                    onChange={(value) => this.setState({ firstName: value })}
                                    value={this.state.firstName}
                                />

                                <CustomInputField
                                    label="Nachname"
                                    error={errors.lastName ? true : false}
                                    helperText={errors.lastName ? errors.lastName : ""}
                                    onChange={(value) => this.setState({ lastName: value })}
                                    value={this.state.lastName}
                                />

                                <CustomInputField
                                    label="Postleitzahl"
                                    error={errors.zipCode ? true : false}
                                    helperText={errors.zipCode ? errors.zipCode : ""}
                                    onChange={(value) => this.setState({ zipCode: value })}
                                    value={this.state.zipCode}
                                />

                                <CustomInputField
                                    label="Stadt"
                                    error={errors.city ? true : false}
                                    helperText={errors.city ? errors.city : ""}
                                    onChange={(value) => this.setState({ city: value })}
                                    value={this.state.city}
                                />

                                <CustomInputField
                                    label="Straße"
                                    error={errors.street ? true : false}
                                    helperText={errors.street ? errors.street : ""}
                                    onChange={(value) => this.setState({ street: value })}
                                    value={this.state.street}
                                />

                                <CustomInputField
                                    label="Hausnummer"
                                    error={errors.houseNumber ? true : false}
                                    helperText={errors.houseNumber ? errors.houseNumber : ""}
                                    onChange={(value) => this.setState({ houseNumber: value })}
                                    value={this.state.houseNumber}
                                />

                                <CustomInputField
                                    label="Firma"
                                    onChange={(value) => this.setState({ company: value })}
                                    value={this.state.company}
                                />

                                <CustomInputField
                                    label="Addreszusatz"
                                    onChange={(value) => this.setState({ addressDetails: value })}
                                    value={this.state.addressDetails}
                                />
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button
                                    textColor={getContrastColor(theme.colors.primary)}
                                    style={{
                                        backgroundColor: theme.colors.primary,
                                    }}
                                    uppercase={false}
                                    onPress={this.handleSubmit}
                                >
                                    {this.props.edit ? "Änderungen speichern" : "Neue Adresse speichern"}
                                </Button>
                            </Dialog.Actions>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>
        );
    }
}

function mapStateToProps(state) {
    const { account } = state;
    return { account };
}

export default connect(mapStateToProps)(AddAddressPopup);
