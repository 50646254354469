import React from "react";
import { HelperText, TextInput } from "react-native-paper";

export default function CustomInputField({
    disabled = false,
    error = false,
    helperText = "",
    id = 0,
    index = 0,
    label = "",
    minRows = 1,
    multiline = false,
    onBlur = () => {},
    onChange = () => {},
    placeholder = "",
    style = {},
    type = "text",
    value = "",
    mode = "outlined",
}) {
    const isPassword = type === "password";
    const secureTextEntry = isPassword;
    const keyboardType = type === "numeric" ? "numeric" : type === "email" ? "email-address" : "default";
    const textInputHeight = multiline ? minRows * 20 : undefined;

    return (
        <>
            <TextInput
                disabled={disabled}
                error={error}
                testID={id}
                label={label}
                key={index}
                multiline={multiline}
                onBlur={onBlur}
                onChangeText={onChange}
                placeholder={placeholder}
                style={[{ ...style }, multiline && { minHeight: textInputHeight }]}
                mode={mode}
                value={value}
                secureTextEntry={secureTextEntry}
                keyboardType={keyboardType}
            />
            <HelperText type="error" visible={error}>
                {helperText}
            </HelperText>
        </>
    );
}
