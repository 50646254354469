import moment from "moment";
import React, { Component } from "react";
import CustomDatePicker from "../../../helpers/datepicker/CustomDatePicker";
import { withTheme, ActivityIndicator, Button, Dialog, Paragraph } from "react-native-paper";
import { View, Text } from "react-native";
import { connect } from "react-redux";
import { getContrastColor } from "../../../shared/helpers";
import { Picker } from "@react-native-picker/picker";
import { styles } from "./AddToWeeklyPlanDialogStyles";

class AddToWeeklyPlanDialog extends Component {
    constructor(props) {
        super(props);

        const sortedArticleSizes =
            props.articleSizes && props.articleSizes.length > 0
                ? props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder)
                : [];

        let selectedArticleSizeId;
        if (props.selectedCookingProcess && props.selectedCookingProcess.CookingProcessArticleSizes) {
            selectedArticleSizeId = props.selectedCookingProcess.CookingProcessArticleSizes[0].articleSizesId;
        } else if (sortedArticleSizes.length > 0) {
            selectedArticleSizeId = sortedArticleSizes[0].articleSizesId;
        } else {
            selectedArticleSizeId = undefined;
        }

        this.state = {
            selectedCustomerCourse: undefined,
            selectedCustomerCourseId: this.props.account.customer.customerCourses[0].customerCoursesId,

            selectedArticleSizeId: selectedArticleSizeId,

            amount: this.props.selectedCookingProcess
                ? this.props.selectedCookingProcess.CookingProcessArticleSizes[0].amount
                : 1,
            selectedDate: this.props.selectedCookingProcess
                ? new moment(this.props.selectedCookingProcess.dayToCook)
                : new moment(),
        };

        this.addRecipeToWeeklyPlan = this.addRecipeToWeeklyPlan.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        this.setState({ selectedCustomerCourse: this.props.account.customer.customerCourses[0] });
    }

    addRecipeToWeeklyPlan() {
        var errorFound = false;
        if (!this.state.selectedArticleSizeId && !this.props.selectedCookingProcess) {
            this.setState({
                selectedArticleSizesIdError: true,
            });
            errorFound = true;
        } else {
            this.setState({
                selectedArticleSizesIdError: false,
            });
        }

        if (!errorFound) {
            if (this.props.selectedCookingProcess) {
                this.props.updateCookingProcess(
                    this.state.selectedCustomerCourseId,
                    this.state.selectedArticleSizeId,
                    this.state.selectedDate,
                    this.state.amount,
                    this.props.selectedCookingProcess.cookingProcessId
                );
            } else {
                this.props.addRecipeToWeeklyPlan(
                    this.state.selectedCustomerCourseId,
                    this.state.selectedArticleSizeId,
                    this.props.recipesId,
                    this.state.selectedDate,
                    this.state.amount,
                    this.props.settings.urlBasePath
                );
            }
        }
    }

    handleDateChange(dateObj) {
        let date;
        if (dateObj && dateObj.date) {
            date = new Date(dateObj.date);
        } else if (dateObj instanceof Date && !isNaN(dateObj)) {
            date = dateObj;
        }

        if (date) {
            this.setState({
                selectedDate: moment(date),
            });
        } else {
            console.error("Ungültiges Datum erhalten:", dateObj);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.recipesId !== prevProps.recipesId) {
            this.setState({
                selectedCustomerCourse: undefined,
                selectedArticleSize: undefined,
                amount: 0,
                selectedDate: new moment(),
            });
        }
        if (this.props.selectedCookingProcess !== prevProps.selectedCookingProcess) {
            this.setState({
                selectedCustomerCourse: this.props.selectedCookingProcess
                    ? this.props.selectedCookingProcess.CustomerCourse
                    : undefined,
                selectedArticleSize: this.props.selectedCookingProcess
                    ? this.props.selectedCookingProcess.CookingProcessArticleSizes[0]
                    : undefined,
                amount: this.props.selectedCookingProcess
                    ? this.props.selectedCookingProcess.CookingProcessArticleSizes[0].amount
                    : 0,
                selectedDate: this.props.selectedCookingProcess
                    ? new moment(this.props.selectedCookingProcess.dayToCook)
                    : new moment(),
            });
        }
        if (
            this.props.articlesId !== prevProps.articlesId ||
            this.props.selectedArticleSize !== prevProps.selectedArticleSize
        ) {
            const sortedArticleSizes = this.props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);

            this.setState({
                selectedArticleSize: this.props.selectedArticleSize || sortedArticleSizes[0],
            });
        }
    }

    render() {
        const sortedArticleSizes =
            this.props.articleSizes && this.props.articleSizes.length > 0
                ? [...this.props.articleSizes].sort((a, b) => a.Size.sortorder - b.Size.sortorder)
                : [];

        return (
            <Dialog
                visible={this.props.addToWeeklyPlanVisible}
                onDismiss={this.props.toggleAddToWeeklyPlanDialog}
                style={{ marginLeft: "auto", marginRight: "auto" }}
            >
                <Dialog.Title>
                    {this.props.selectedRecipe ? this.props.selectedRecipe.name + " in den Wochenplan legen" : ""}
                    {this.props.selectedCookingProcess ? this.props.selectedCookingProcess.name + " anpassen" : ""}
                </Dialog.Title>
                <Dialog.Content>
                    {this.props.loading && (
                        <View
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ActivityIndicator
                                size={"large"}
                                animating={true}
                                color={this.props.theme.colors.primary}
                            />
                        </View>
                    )}
                    <View style={{ marginBottom: 20 }}>
                        <Text style={styles.addToWeeklyPlanDialogSelectLabel}>Gang</Text>
                        <Picker
                            selectedValue={this.state.selectedCustomerCourseId}
                            onValueChange={(itemValue) => this.setState({ selectedCustomerCourseId: itemValue })}
                            style={[
                                styles.addToWeeklyPlanDialogSelect,
                                {
                                    backgroundColor: this.props.theme.colors.surface,
                                    borderColor: this.props.theme.colors.primary,
                                    color: getContrastColor(this.props.theme.colors.surface),
                                },
                            ]}
                        >
                            {this.props.account.customer.customerCourses.map((course) => (
                                <Picker.Item
                                    key={course.customerCoursesId}
                                    label={course.Course.name}
                                    value={course.customerCoursesId}
                                />
                            ))}
                        </Picker>
                        {this.state.selectedCustomerCoursesIdError && (
                            <Paragraph style={{ color: this.props.theme.colors.error }}>
                                Dies ist eine Pflichtangabe
                            </Paragraph>
                        )}
                    </View>
                    {sortedArticleSizes.length > 1 && (
                        <View style={{ marginBottom: 20 }}>
                            <Text style={styles.addToWeeklyPlanDialogSelectLabel}>Größe</Text>
                            <Picker
                                selectedValue={this.state.selectedArticleSizeId}
                                onValueChange={(itemValue) => this.setState({ selectedArticleSizeId: itemValue })}
                                style={[
                                    styles.addToWeeklyPlanDialogSelect,
                                    {
                                        backgroundColor: this.props.theme.colors.surface,
                                        borderColor: this.props.theme.colors.primary,
                                        color: getContrastColor(this.props.theme.colors.surface),
                                    },
                                ]}
                            >
                                {sortedArticleSizes.map((articleSize) => (
                                    <Picker.Item
                                        key={articleSize.articleSizesId}
                                        label={articleSize.Size.name}
                                        value={articleSize.articleSizesId}
                                    />
                                ))}
                            </Picker>
                            {this.state.selectedArticleSizesIdError && (
                                <Paragraph style={{ color: this.props.theme.colors.error }}>
                                    Dies ist eine Pflichtangabe
                                </Paragraph>
                            )}
                        </View>
                    )}
                    {!this.props.startCookingProcessImmediately && (
                        <CustomDatePicker
                            loading={this.props.loading}
                            label="Wähle einen Tag aus"
                            handleDateChange={this.handleDateChange}
                            selectedDate={this.state.selectedDate}
                            backgroundColor={this.props.theme.colors.surface}
                            textColor={getContrastColor(this.props.theme.colors.surface)}
                            borderColor={this.props.theme.colors.primary}
                            iconColor={getContrastColor(this.props.theme.colors.surface)}
                        />
                    )}
                    <View style={{ marginTop: 20 }}>
                        <Text style={styles.addToWeeklyPlanDialogSelectLabel}>Menge</Text>
                        <Picker
                            selectedValue={this.state.amount}
                            onValueChange={(itemValue) => this.setState({ amount: itemValue })}
                            style={[
                                styles.addToWeeklyPlanDialogSelect,
                                {
                                    backgroundColor: this.props.theme.colors.surface,
                                    borderColor: this.props.theme.colors.primary,
                                    color: getContrastColor(this.props.theme.colors.surface),
                                },
                            ]}
                        >
                            {[...Array(this.props.settings.store.maxPortionNumber).keys()].map((number) => (
                                <Picker.Item key={number} label={`${number + 1}`} value={number + 1} />
                            ))}
                        </Picker>
                    </View>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button
                        textColor={getContrastColor(this.props.theme.colors.primary)}
                        style={{
                            backgroundColor: this.props.theme.colors.notification,
                            marginRight: 20,
                            paddingLeft: 15,
                            paddingRight: 15,
                        }}
                        uppercase={false}
                        onPress={this.props.toggleAddToWeeklyPlanDialog}
                        disabled={this.props.loading}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        textColor={getContrastColor(this.props.theme.colors.primary)}
                        style={{
                            backgroundColor: this.props.theme.colors.primary,
                            marginRight: 0,
                            paddingLeft: 15,
                            paddingRight: 15,
                        }}
                        uppercase={false}
                        onPress={this.addRecipeToWeeklyPlan}
                        disabled={this.props.loading}
                    >
                        {this.props.selectedCookingProcess
                            ? "Speichern"
                            : this.props.startCookingProcessImmediately
                            ? "Jetzt kochen"
                            : "Hinzufügen"}
                    </Button>
                </Dialog.Actions>
            </Dialog>
        );
    }
}
function mapStateToProps(state) {
    const { account, settings } = state;
    return { account, settings };
}
export default connect(mapStateToProps)(withTheme(AddToWeeklyPlanDialog));
