import React, { Component } from "react";
import { Animated, Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Button, Portal, withTheme, Text } from "react-native-paper";
import { connect } from "react-redux";
import { styles } from "../../shared/styles";
import { cartStyles } from "./CartStyles";
import CartItem from "./CartItem";
import { store } from "../../store/store";
import { REMOVE_ORDER_ITEM } from "../../store/actions/cartActions";
import { getContrastColor } from "../../shared/helpers";

function removeOrderItem(orderItem) {
    store.dispatch({ type: REMOVE_ORDER_ITEM, orderItem });
}

function sortByDayToCook(a, b) {
    return new Date(a.dayToCook) - new Date(b.dayToCook);
}

class Cart extends Component {
    constructor(props) {
        super(props);

        this.widthValue = new Animated.Value(0);

        this.slideIn = this.slideIn.bind(this);
        this.slideOut = this.slideOut.bind(this);
    }

    componentDidUpdate() {
        const { open } = this.props;

        if (open) {
            this.slideIn();
        } else {
            this.slideOut();
        }
    }

    slideIn() {
        Animated.timing(this.widthValue, {
            toValue: 1,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }

    slideOut() {
        Animated.timing(this.widthValue, {
            toValue: 0,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }

    calculateTotal() {
        let total = 0;

        if (this.props.cart.orderItems.length > 0) {
            let i = 0;

            while (this.props.cart.orderItems[i]) {
                const orderItem = this.props.cart.orderItems[i];

                if (orderItem) {
                    const price = orderItem.Price ? orderItem.Price.price1 : 0;
                    const { amount } = orderItem.entry;

                    if (price && amount) {
                        total += price * amount;
                    }
                }

                i++;
            }
        }

        total = Math.round(total * 100) / 100;

        return total;
    }

    render() {
        const { open, onBackdropPress, theme, settings, account } = this.props;
        const width = this.widthValue.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 200],
        });

        return (
            <Portal>
                <Animated.View
                    style={{
                        backgroundColor: theme.colors.primary,
                        width: width,
                        height: "100vh",
                        zIndex: 1,
                        overflow: "hidden",
                        position: "fixed",
                        right: 0,
                    }}
                >
                    {open && (
                        <View style={cartStyles.container}>
                            <ScrollView style={cartStyles.articleContainer}>
                                {this.props.cart.orderItems.length < 1 && (
                                    <Text
                                        style={[
                                            cartStyles.emptyCartText,
                                            { color: getContrastColor(theme.colors.primary) },
                                        ]}
                                    >
                                        Warenkorb ist leer
                                    </Text>
                                )}
                                {this.props.cart.orderItems.length > 0 && (
                                    <>
                                        {this.props.cart.orderItems.sort(sortByDayToCook).map((orderItem, index) => (
                                            <CartItem
                                                key={index}
                                                orderItem={orderItem}
                                                removeOrderItem={removeOrderItem}
                                            />
                                        ))}
                                    </>
                                )}
                            </ScrollView>
                            {this.props.settings.store.minOrderValue && (
                                <div
                                    style={{
                                        height: "auto",
                                        color: getContrastColor(theme.colors.primary),
                                        marginBottom: 20,
                                        display: "flex",
                                        flexDirection: "column",
                                        borderTop: true,
                                        borderTopStyle: "solid",
                                        borderTopWidth: 2,
                                    }}
                                >
                                    <Text style={{ marginTop: 10, color: getContrastColor(theme.colors.primary) }}>
                                        {("Warenkorb: " + this.calculateTotal().toFixed(2) + " €").replace(".", ",")}
                                    </Text>

                                    <Text
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 2,
                                            color: getContrastColor(theme.colors.primary),
                                        }}
                                    >
                                        {(
                                            "zzgl. Liefergebühr: " +
                                            (this.calculateTotal() < this.props.settings.store.deliverFreeValue
                                                ? this.props.settings.store.deliverLumpSum
                                                : "0") +
                                            " €"
                                        ).replace(".", ",")}
                                    </Text>
                                    <Text style={{ marginTop: 10, color: getContrastColor(theme.colors.primary) }}>
                                        <b>
                                            {"Summe: " +
                                                (
                                                    this.calculateTotal() +
                                                    (this.calculateTotal() < this.props.settings.store.deliverFreeValue
                                                        ? Number(this.props.settings.store.deliverLumpSum)
                                                        : 0)
                                                )
                                                    .toFixed(2)
                                                    .replace(".", ",") +
                                                " €**"}
                                        </b>
                                    </Text>
                                    <Text style={{ fontSize: 10, color: getContrastColor(theme.colors.primary) }}>
                                        {"*ab " +
                                            this.props.settings.store.deliverFreeValue +
                                            " € Lieferung kostenfrei"}
                                    </Text>
                                    <Text style={{ fontSize: 10, color: getContrastColor(theme.colors.primary) }}>
                                        **Preise inkl. MwSt.
                                    </Text>
                                    {this.calculateTotal() < this.props.settings.store.minOrderValue && (
                                        <View
                                            style={{
                                                backgroundColor: theme.colors.error,
                                                paddingTop: 7,
                                                paddingRight: 10,
                                                paddingBottom: 5,
                                                paddingLeft: 10,
                                                marginTop: 20,
                                                borderRadius: 7,
                                                textAlign: "center",
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: getContrastColor(theme.colors.primary),
                                                    fontSize: 12,
                                                    textAlign: "center",
                                                }}
                                            >
                                                {"Mindestbestellwert (" +
                                                    this.props.settings.store.minOrderValue +
                                                    " €) " +
                                                    "nicht erreicht"}
                                            </Text>
                                        </View>
                                    )}
                                </div>
                            )}
                            <Button
                                onPress={this.props.toggleCustomerData}
                                mode="contained"
                                buttonColor={theme.colors.accent}
                                textColor={getContrastColor(theme.colors.accent)}
                                disabled={
                                    this.calculateTotal() === 0 ||
                                    this.calculateTotal() < this.props.settings.store.minOrderValue
                                }
                            >
                                Bestellen
                            </Button>
                        </View>
                    )}
                </Animated.View>
                {open && (
                    <Pressable onPress={onBackdropPress} style={StyleSheet.absoluteFill}>
                        <View
                            nativeID="cartBackground"
                            style={{ ...StyleSheet.flatten(styles.backdrop), backgroundColor: theme.colors.backdrop }}
                        ></View>
                    </Pressable>
                )}
            </Portal>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, cart } = state;
    return { settings, account, cart };
}

export default connect(mapStateToProps)(withTheme(Cart));
