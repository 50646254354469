import React, { Component } from "react";
import { Button, Dialog, Portal, Text } from "react-native-paper";
import { getContrastColor } from "../../shared/helpers";

export default class ConfirmDeletePopup extends Component {
    render() {
        const { theme } = this.props;
        return (
            <Portal>
                <Dialog
                    visible={this.props.visible}
                    onDismiss={this.props.togglePopup}
                    style={{
                        height: 150,
                        width: 300,
                        left: "calc(50% - 150px)",
                        position: "fixed",
                        top: "calc(50% - 75px)",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <Dialog.Title title="Diese Adresse wirklich löschen?">
                        <Text>Diese Adresse wirklich löschen?</Text>
                    </Dialog.Title>
                    <Dialog.Actions>
                        <Button
                            textColor={getContrastColor(theme.colors.primary)}
                            style={{
                                backgroundColor: theme.colors.primary,
                                marginRight: 20,
                                paddingLeft: 15,
                                paddingRight: 15,
                            }}
                            uppercase={false}
                            onPress={this.props.togglePopup}
                        >
                            Nein
                        </Button>
                        <Button
                            textColor={getContrastColor(theme.colors.primary)}
                            style={{
                                backgroundColor: theme.colors.notification,
                                paddingLeft: 15,
                                paddingRight: 15,
                            }}
                            onPress={() => {
                                this.props.delete();
                                this.props.togglePopup();
                            }}
                            uppercase={false}
                        >
                            Ja
                        </Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        );
    }
}
