import { StyleSheet } from "react-native";

export const footerStyles = StyleSheet.create({
    appbar: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        paddingLeft: 30,
        paddingRight: 30,
        zIndex: 1000,
        display: "flex",
    },
    buttons: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
    },
});
