import React, { Component } from "react";
import { store } from "../../../store/store";
import { getPriceOfArticleSize, withHooksHOC } from "../../../shared/helpers";
import { getAssignedNutritionalValues } from "../../../store/actions/onlineShopCategoriesActions";
import AccordionView from "./AccordionView";
import moment from "moment";
import TabView from "./TabView";
import { connect } from "react-redux";
import { ADD_ORDER_ITEM } from "../../../store/actions/cartActions";
import { View } from "react-native";
import { styles } from "./AdditionalProductDetailsStyles";

class AdditionalProductDetails extends Component {
    constructor(props) {
        super(props);

        this.addIngredientToCart = this.addIngredientToCart.bind(this);
    }

    componentDidMount() {
        store.dispatch(getAssignedNutritionalValues()).then((response) => {
            if (response.length > 0) {
                for (var i = 0; i < response.length; i++) {
                    var assignedValue = this.props.product.nutritionalValues.find(
                        (obj) => obj.id === response[i].assignmentNutritionalValueId
                    );
                    if (assignedValue != null) {
                        if (
                            !this.props.product.nutritionalValues.some((obj) => {
                                return obj.id === response[i].nutritionalValuesId;
                            })
                        ) {
                            var newNutritionalValue = {
                                NutritionalValue: response[i],
                                NutritionalValueDummy: response[i],
                                id: response[i].nutritionalValuesId,
                                nullableValue:
                                    assignedValue.unit === "mg"
                                        ? assignedValue.nullableValue / 1000
                                        : assignedValue.unit === "µg"
                                        ? assignedValue.nullableValue / 1000000
                                        : assignedValue.nullableValue,
                                value:
                                    assignedValue.unit === "mg"
                                        ? assignedValue.nullableValue / 1000
                                        : assignedValue.unit === "µg"
                                        ? assignedValue.nullableValue / 1000000
                                        : assignedValue.nullableValue,
                                nutritionalValuesId: response[i].nutritionalValuesId,
                            };

                            this.props.product.nutritionalValues.push(newNutritionalValue);
                        }
                    }
                }
            }
        });
    }

    addIngredientToCart(article) {
        const orderItem = {
            entry: { ...article, articleSizes: article.ArticleSizesWithoutDefaultSize },
            ArticleSize: article.ArticleSizes.find((size) => !size.Size.isDefault),
            Price: getPriceOfArticleSize(
                article.ArticleSizes.find((size) => !size.Size.isDefault),
                this.props.settings.store.orderTypes[0].id,
                this.props.settings.store.priceGroups[0].priceGroupsId
            ),
            orderType: this.props.settings.store.orderTypes[0],
            dayToCook: new moment(),
        };

        orderItem.entry.amount = 1;
        document.body.style.overflow = "auto";
        store.dispatch({ type: ADD_ORDER_ITEM, orderItem });
    }

    render() {
        const { product, windowSize, additives } = this.props;
        const mobile = windowSize[0] < 700;

        return (
            <View
                style={[
                    styles.additionalProductDetailsContainer,
                    mobile ? styles.additionalProductDetailsContainerMobile : null,
                ]}
            >
                {!mobile && (
                    <TabView product={product} additives={additives} addIngredientToCart={this.addIngredientToCart} />
                )}
                {mobile && (
                    <AccordionView
                        product={product}
                        additives={additives}
                        addIngredientToCart={this.addIngredientToCart}
                    />
                )}
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { account, settings } = state;
    return { account, settings };
}

export default connect(mapStateToProps)(withHooksHOC(AdditionalProductDetails));
