import React, { Component } from "react";
import { Dimensions, Pressable, StyleSheet, View } from "react-native";
import { Appbar, Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";

import Config from "../../../Config";
import { Link, withRouter } from "../../routers/routing";
import { desktopBreakpoint, getContrastColor, withHooksHOC } from "../../shared/helpers";
import { headerStyles } from "./HeaderStyle";

const config = new Config();

class SubHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            showRecipes: false,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
        let instances = this.props.biosInstances;
        instances.push({ url: config.backendHost });
        instances.forEach((instance) => {
            if (this.props.onlineShopCategories["recipes_" + instance.url]) {
                const category = this.props.onlineShopCategories["recipes_" + instance.url][0];

                if (category && Array.isArray(category)) {
                    if (
                        category.filter((category) => category.OnlineShopItems && category.OnlineShopItems.length > 0)
                            .length > 0
                    ) {
                        this.setState({ showRecipes: true });
                    }
                }
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            let instances = this.props.biosInstances;
            instances.push({ url: config.backendHost });
            instances.forEach((instance) => {
                if (this.props.onlineShopCategories["recipes_" + instance.url]) {
                    const category = this.props.onlineShopCategories["recipes_" + instance.url][0];

                    if (category && Array.isArray(category)) {
                        if (
                            category.filter(
                                (category) => category.OnlineShopItems && category.OnlineShopItems.length > 0
                            ).length > 0
                        ) {
                            this.setState({ showRecipes: true });
                        }
                    }
                }
            });
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    render() {
        const { theme } = this.props;
        let mobile = this.props.windowSize[0] < desktopBreakpoint;
        return (
            <React.Fragment>
                <Appbar.Header
                    statusBarHeight={0}
                    style={{
                        ...StyleSheet.flatten(headerStyles.appsubbar),
                        backgroundColor: theme.colors.accent,
                    }}
                >
                    <View nativeID="menu" style={headerStyles.submenu}>
                        <View nativeID="links" style={headerStyles.links}>
                            <Pressable key={"home"}>
                                <Text>
                                    <Link
                                        style={{
                                            ...StyleSheet.flatten(headerStyles.link),
                                            color: getContrastColor(theme.colors.accent),
                                        }}
                                        to="/"
                                    >
                                        {this.props.onlineShopCategories.onlineShopItemType === -1 ? (
                                            <u>Startseite</u>
                                        ) : (
                                            "Startseite"
                                        )}
                                    </Link>
                                </Text>
                            </Pressable>
                            <Pressable key={"shop"}>
                                <Text>
                                    <Link
                                        style={{
                                            ...StyleSheet.flatten(headerStyles.link),
                                            color: getContrastColor(theme.colors.accent),
                                        }}
                                        to="/shop"
                                    >
                                        {this.props.onlineShopCategories.onlineShopItemType === 0 ? (
                                            <u>Shop</u>
                                        ) : (
                                            "Shop"
                                        )}
                                    </Link>
                                </Text>
                            </Pressable>
                            {this.state.showRecipes && (
                                <Pressable key={"recipes"}>
                                    <Text>
                                        <Link
                                            style={{
                                                ...StyleSheet.flatten(headerStyles.link),
                                                color: getContrastColor(theme.colors.accent),
                                            }}
                                            to="/recipes"
                                        >
                                            {this.props.onlineShopCategories.onlineShopItemType === 1 ? (
                                                <u>Rezepte</u>
                                            ) : (
                                                "Rezepte"
                                            )}
                                        </Link>
                                    </Text>
                                </Pressable>
                            )}
                        </View>
                    </View>
                </Appbar.Header>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, onlineShopCategories } = state;
    return { settings, account, onlineShopCategories };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(withTheme(SubHeader))));
