import React, { PureComponent } from "react";
import { View } from "react-native";
import { Text, Title } from "react-native-paper";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";

import { desktopBreakpoint, filterOnlineShopItems, withHooksHOC } from "../../shared/helpers";
import {
    getArticlesForOnlineShop,
    getRecipesForOnlineShop,
    getRecommendationsForOnlineShop,
} from "../../store/actions/onlineShopCategoriesActions";
import { store } from "../../store/store";
import Category from "./Category";
import { ShopStyles } from "./ShopStyles";

class Store extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            recommendations: [],
            recipes: [],
            articles: [],
        };
    }

    componentDidMount() {
        store.dispatch(getRecommendationsForOnlineShop(this.props.url)).then((result) => {
            this.setState({
                recommendations: result,
            });
        });

        store.dispatch(getRecipesForOnlineShop(this.props.url)).then((result) => {
            this.setState({
                recipes: result,
            });
        });

        store.dispatch(getArticlesForOnlineShop(this.props.url)).then((result) => {
            this.setState({
                articles: result,
            });
        });
    }

    render() {
        let windowWidth = this.props.windowSize[0];
        let mobile = this.props.windowSize[0] < desktopBreakpoint;
        let numberOfTilesPerRow = Math.floor(windowWidth / (mobile ? 180 : 300));
        let empty =
            this.props.settings.searchText !== "" &&
            this.props.onlineShopCategories["articles_" + this.props.url][0].filter((item) => {
                return filterOnlineShopItems(item.OnlineShopItems, this.props.settings.searchText).length > 0;
            }).length === 0 &&
            this.props.onlineShopCategories["recipes_" + this.props.url][0].filter((item) => {
                return filterOnlineShopItems(item.OnlineShopItems, this.props.settings.searchText).length > 0;
            }).length === 0 &&
            this.props.onlineShopCategories["recommendations_" + this.props.url][0].filter((item) => {
                return filterOnlineShopItems(item.OnlineShopItems, this.props.settings.searchText).length > 0;
            }).length === 0;

        return (
            <React.Fragment>
                {empty && (
                    <View style={mobile ? ShopStyles.CategoryMobile : ShopStyles.Category}>
                        <Title
                            style={{
                                marginLeft: mobile ? 20 : 50,
                                fontSize: mobile ? 18 : 28,
                                marginBottom: mobile ? 0 : -70,
                                marginTop: mobile ? 10 : -40,
                                marginRight: mobile ? 20 : 50,
                            }}
                        >
                            Zu deiner Suchanfrage konnten keine Rezepte oder Artikel gefunden werden
                        </Title>
                    </View>
                )}
                {this.props.onlineShopCategories["recommendations_" + this.props.url] &&
                    this.props.onlineShopCategories["recommendations_" + this.props.url][0] &&
                    this.props.onlineShopCategories["recommendations_" + this.props.url][0].OnlineShopItems &&
                    this.props.onlineShopCategories["recommendations_" + this.props.url][0].map((item, index) => {
                        var filteredOnlineShopItems = filterOnlineShopItems(
                            item.OnlineShopItems,
                            this.props.settings.searchText
                        );
                        if (
                            filteredOnlineShopItems.length === 0 ||
                            (this.props.onlineShopItemType !== -1 &&
                                this.props.onlineShopItemType !== item.onlineShopItemType)
                        ) {
                            return <React.Fragment key={index} />;
                        } else {
                            return (
                                <Category
                                    category={item}
                                    onlineShopItems={filteredOnlineShopItems}
                                    numberOfTiles={numberOfTilesPerRow}
                                    showAddRecipeToWeeklyPlanPopup={this.props.showAddRecipeToWeeklyPlanPopup}
                                    showAddArticleToCartPopup={this.props.showAddArticleToCartPopup}
                                    key={index}
                                    mobile={mobile}
                                    cookRecipe={this.props.cookRecipe}
                                    toggleLoginPopup={this.props.toggleLoginPopup}
                                />
                            );
                        }
                    })}
                {this.props.onlineShopCategories["articles_" + this.props.url] &&
                    this.props.onlineShopCategories["articles_" + this.props.url][0] &&
                    Array.isArray(this.props.onlineShopCategories["articles_" + this.props.url][0]) &&
                    this.props.onlineShopCategories["articles_" + this.props.url][0].map((item, index) => {
                        var filteredOnlineShopItems = filterOnlineShopItems(
                            item.OnlineShopItems,
                            this.props.settings.searchText
                        );
                        if (
                            filteredOnlineShopItems.length === 0 ||
                            (this.props.onlineShopItemType !== -1 &&
                                this.props.onlineShopItemType !== item.onlineShopItemType)
                        ) {
                            return <React.Fragment key={index}></React.Fragment>;
                        } else {
                            return (
                                <Category
                                    category={item}
                                    onlineShopItems={filteredOnlineShopItems}
                                    numberOfTiles={numberOfTilesPerRow}
                                    showAddRecipeToWeeklyPlanPopup={this.props.showAddRecipeToWeeklyPlanPopup}
                                    showAddArticleToCartPopup={this.props.showAddArticleToCartPopup}
                                    key={index}
                                    mobile={mobile}
                                    cookRecipe={this.props.cookRecipe}
                                    toggleLoginPopup={this.props.toggleLoginPopup}
                                />
                            );
                        }
                    })}
                {this.props.onlineShopCategories["recipes_" + this.props.url] &&
                    this.props.onlineShopCategories["recipes_" + this.props.url][0] &&
                    Array.isArray(this.props.onlineShopCategories["recipes_" + this.props.url][0]) &&
                    this.props.onlineShopCategories["recipes_" + this.props.url][0].map((item, index) => {
                        var filteredOnlineShopItems = filterOnlineShopItems(
                            item.OnlineShopItems,
                            this.props.settings.searchText
                        );
                        if (
                            filteredOnlineShopItems.length === 0 ||
                            (this.props.onlineShopItemType !== -1 &&
                                this.props.onlineShopItemType !== item.onlineShopItemType)
                        ) {
                            return <React.Fragment key={index} />;
                        } else {
                            return (
                                <Category
                                    category={item}
                                    onlineShopItems={filteredOnlineShopItems}
                                    numberOfTiles={numberOfTilesPerRow}
                                    showAddRecipeToWeeklyPlanPopup={this.props.showAddRecipeToWeeklyPlanPopup}
                                    showAddArticleToCartPopup={this.props.showAddArticleToCartPopup}
                                    key={index}
                                    mobile={mobile}
                                    cookRecipe={this.props.cookRecipe}
                                    toggleLoginPopup={this.props.toggleLoginPopup}
                                />
                            );
                        }
                    })}
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    const { settings, account, onlineShopCategories } = state;
    return { settings, account, onlineShopCategories };
}
export default compose(connect(mapStateToProps), withRouter, withHooksHOC)(Store);
