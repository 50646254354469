import { Picker } from "@react-native-picker/picker";
import React, { Component } from "react";
import { Text, View } from "react-native";
import { Button, Dialog } from "react-native-paper";
import { connect } from "react-redux";
import { styles } from "./AddToCardDialogStyles";
import { getContrastColor } from "../../../shared/helpers";

class AddToCartDialog extends Component {
    constructor(props) {
        super(props);

        const sortedArticleSizes = props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);

        this.state = {
            selectedArticleSizeId: props.selectedArticleSize
                ? props.selectedArticleSize.articleSizesId
                : sortedArticleSizes[0].articleSizesId,
            amount: 1,
        };

        this.addArticleToCart = this.addArticleToCart.bind(this);
    }
    addArticleToCart() {
        const selectedArticleSizeId = parseInt(this.state.selectedArticleSizeId, 10);
        const selectedArticleSize = this.props.articleSizes.find(
            (size) => size.articleSizesId === selectedArticleSizeId
        );
        this.props.addArticleToCart(selectedArticleSize, this.props.selectedArticle, this.state.amount);
    }

    handleDateChange(newDate) {
        this.setState({
            selectedDate: newDate,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.articlesId !== prevProps.articlesId ||
            this.props.selectedArticleSize !== prevProps.selectedArticleSize
        ) {
            const sortedArticleSizes = this.props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);

            this.setState({
                selectedArticleSize: this.props.selectedArticleSize || sortedArticleSizes[0],
                amount: 1,
            });
        }
    }

    menuItems() {
        let menuItems = [];
        for (let i = 1; i <= this.props.settings.store.maxPortionNumber; i++) {
            menuItems.push(<Picker.Item key={i} label={i.toString()} value={i} />);
        }
        return menuItems;
    }

    render() {
        const { theme } = this.props.settings;
        const sortedArticleSizes = this.props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);
        return (
            <Dialog
                visible={this.props.addToCartVisible}
                onDismiss={this.props.toggleAddToCartDialog}
                style={{ marginLeft: "auto", marginRight: "auto" }}
            >
                <Dialog.Title>
                    {this.props.selectedArticle ? this.props.selectedArticle.name : ""} in den Warenkorb legen
                </Dialog.Title>
                <Dialog.Content>
                    {sortedArticleSizes.length > 1 && (
                        <View style={{ marginBottom: "10px" }}>
                            <Text style={styles.addToCartDialogSelectLabel}>Größe</Text>
                            <Picker
                                selectedValue={this.state.selectedArticleSizeId}
                                onValueChange={(itemValue, itemIndex) => {
                                    this.setState({ selectedArticleSizeId: itemValue });
                                }}
                                style={[
                                    styles.addToCartDialogSelect,
                                    {
                                        backgroundColor: theme.colors.surface,
                                        borderColor: theme.colors.primary,
                                        color: getContrastColor(theme.colors.surface),
                                    },
                                ]}
                            >
                                {sortedArticleSizes.map((articleSize, index) => (
                                    <Picker.Item
                                        key={index}
                                        label={articleSize.Size.name}
                                        value={articleSize.articleSizesId}
                                    />
                                ))}
                            </Picker>
                        </View>
                    )}
                    <View style={{ marginTop: 20 }}>
                        <Text style={styles.addToCartDialogSelectLabel}>Menge</Text>
                        <Picker
                            selectedValue={this.state.amount}
                            onValueChange={(itemValue, itemIndex) => this.setState({ amount: itemValue })}
                            style={[
                                styles.addToCartDialogSelect,
                                {
                                    backgroundColor: theme.colors.surface,
                                    borderColor: theme.colors.primary,
                                    color: getContrastColor(theme.colors.surface),
                                },
                            ]}
                        >
                            {this.menuItems()}
                        </Picker>
                    </View>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={[
                            styles.addToCartDialogButton,
                            {
                                backgroundColor: theme.colors.notification,
                            },
                        ]}
                        onPress={this.props.toggleAddToCartDialog}
                    >
                        Abbrechen
                    </Button>

                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={[
                            styles.addToCartDialogButton,
                            {
                                backgroundColor: theme.colors.primary,
                            },
                        ]}
                        onPress={this.addArticleToCart}
                    >
                        Hinzufügen
                    </Button>
                </Dialog.Actions>
            </Dialog>
        );
    }
}
function mapStateToProps(state) {
    const { settings } = state;
    return {
        settings: settings,
    };
}

export default connect(mapStateToProps)(AddToCartDialog);
