import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Checkbox, Divider, Paragraph, Surface, withTheme } from "react-native-paper";
import { connect } from "react-redux";

import CustomDatePicker from "../../helpers/datepicker/CustomDatePicker";
import { desktopBreakpoint, withHooksHOC } from "../../shared/helpers";
import { checkShoppingItem, getShoppingList } from "../../store/actions/accountActions";
import { setOnlineShopItemType } from "../../store/actions/onlineShopCategoriesActions";
import { store } from "../../store/store";
import { ShoppingListStyles } from "./ShoppingListSytles";

class ShoppingList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shoppingItems: [],
            isLoading: true,
            selectedDateFrom: new moment(),
            selectedDateTo: new moment().add(7, "days"),
        };

        this.checkShoppingItem = this.checkShoppingItem.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.loadShoppingItems = this.loadShoppingItems.bind(this);
    }

    componentDidMount() {
        store.dispatch(setOnlineShopItemType(-2));
        this.loadShoppingItems();
    }

    loadShoppingItems() {
        this.setState({
            isLoading: true,
        });
        store
            .dispatch(
                getShoppingList(
                    this.props.account.customer.customerUid,
                    this.state.selectedDateFrom.toDate(),
                    this.state.selectedDateTo.toDate()
                )
            )
            .then((result) => {
                if (result) {
                    this.setState({
                        shoppingItems: result,
                        isLoading: false,
                    });
                }
            });
    }

    checkShoppingItem(shoppingItem) {
        store.dispatch(checkShoppingItem(shoppingItem)).then((result) => {
            store
                .dispatch(
                    getShoppingList(
                        this.props.account.customer.customerUid,
                        this.state.selectedDateFrom.toDate(),
                        this.state.selectedDateTo.toDate()
                    )
                )
                .then((result) => {
                    if (result) {
                        this.setState({
                            shoppingItems: result,
                        });
                    }
                });
        });
    }

    handleFromDateChange(dateObj) {
        this.updateDate("selectedDateFrom", dateObj, () => this.loadShoppingItems());
    }

    handleToDateChange(dateObj) {
        this.updateDate("selectedDateTo", dateObj, () => this.loadShoppingItems());
    }

    updateDate(stateKey, dateObj, callback) {
        let date;
        if (dateObj && dateObj.date) {
            date = new Date(dateObj.date);
        } else if (dateObj instanceof Date && !isNaN(dateObj)) {
            date = dateObj;
        }

        if (date) {
            this.setState({ [stateKey]: moment(date) }, callback);
        } else {
            console.error("Ungültiges Datum erhalten:", dateObj);
        }
    }

    render() {
        const mobile = this.props.windowSize[0] < desktopBreakpoint;
        const { theme } = this.props;
        return (
            <View
                style={{
                    ...StyleSheet.flatten(ShoppingListStyles.container),
                    marginTop: mobile ? 0 : 16,
                    backgroundColor: theme.colors.surface,
                }}
            >
                <Surface
                    style={{
                        ...StyleSheet.flatten(ShoppingListStyles.dateSurface),
                        flexDirection: mobile ? "column" : "row",
                    }}
                >
                    <View style={{ marginBottom: mobile ? 16 : 0, flexGrow: 1, marginRight: mobile ? 0 : 8 }}>
                        <CustomDatePicker
                            label="Von"
                            handleDateChange={this.handleFromDateChange}
                            selectedDate={this.state.selectedDateFrom}
                        />
                    </View>
                    <View style={{ flexGrow: 1, marginLeft: mobile ? 0 : 8 }}>
                        <CustomDatePicker
                            label="Bis"
                            handleDateChange={this.handleToDateChange}
                            selectedDate={this.state.selectedDateTo}
                        />
                    </View>
                </Surface>
                <View style={ShoppingListStyles.listContainer}>
                    {this.state.isLoading && <ActivityIndicator size="large" style={{ marginVertical: 16 }} />}
                    {!this.state.isLoading && this.state.shoppingItems.length === 0 && (
                        <View>
                            <Paragraph style={{ padding: 16 }}>
                                Für diesen Zeitraum gibt es keine Einkaufsliste
                            </Paragraph>
                        </View>
                    )}
                    {!this.state.isLoading &&
                        this.state.shoppingItems.length > 0 &&
                        this.state.shoppingItems.map((shoppingItem) => {
                            return (
                                <React.Fragment>
                                    <Checkbox.Item
                                        status={shoppingItem.isChecked ? "checked" : "unchecked"}
                                        label={
                                            new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                                                shoppingItem.weight
                                            ) +
                                            " g " +
                                            shoppingItem.name
                                        }
                                        labelStyle={{
                                            textDecorationLine: shoppingItem.isChecked ? "line-through" : "none",
                                        }}
                                        onPress={() => this.checkShoppingItem(shoppingItem)}
                                    />
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                </View>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { account, settings } = state;
    return { account, settings };
}

export default connect(mapStateToProps)(withHooksHOC(withTheme(ShoppingList)));
