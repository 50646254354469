import React, { Component } from "react";
import { Avatar, Card, Text, withTheme } from "react-native-paper";
import { View } from "react-native-web";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import {
    deleteAddress,
    updateStandardBillingAddress,
    updateStandardDeliveryAddress,
} from "../../store/actions/accountActions";
import { store } from "../../store/store";
import AddAddressPopup from "./AddAddressPopup";
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import { Dimensions } from "react-native";
import { desktopBreakpoint, getContrastColor, tabletBreadpoint } from "../../shared/helpers";

class CustomerAddresses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
            showAddAddressPopup: false,
            test: false,
            additionalAddressToEdit: 0,
            edit: false,
            showConfirmDeletePopup: false,
            addressToDelete: undefined,
            indexToDelete: 0,
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };

        this.toggleAddAddressPopup = this.toggleAddAddressPopup.bind(this);
        this.toggleConfirmDeletePopup = this.toggleConfirmDeletePopup.bind(this);
        this.editAddress = this.editAddress.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        const { customer } = this.props.account;
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    toggleAddAddressPopup() {
        if (this.state.edit && this.state.showAddAddressPopup) this.setState({ edit: false });
        document.body.style.overflow = !this.state.showAddAddressPopup ? "hidden" : "auto";
        this.setState({ showAddAddressPopup: !this.state.showAddAddressPopup });
    }

    toggleConfirmDeletePopup() {
        if (this.state.edit && this.state.showConfirmDeletePopup) this.setState({ edit: false });
        document.body.style.overflow = !this.state.showConfirmDeletePopup ? "hidden" : "auto";
        this.setState({ showConfirmDeletePopup: !this.state.showConfirmDeletePopup });
    }

    deleteAddress() {
        let address = this.state.addressToDelete;
        const { customer } = this.props.account;
        toast.show("Adresse wird gelöscht...", { type: "info" });
        store
            .dispatch(
                deleteAddress(
                    this.props.account.customer.customerUid,
                    this.state.indexToDelete,
                    address.Street.City.name,
                    address.Street.name,
                    address.housenumber,
                    address.Street.City.zipCode,
                    address.addressUid
                )
            )
            .then(() => {
                this.props.update(customer.azureId);
                toast.show("Adresse gelöscht", { type: "success" });
            });
    }

    editAddress(address) {
        const { customer } = this.props.account;
        this.setState(
            {
                additionalAddressToEdit: address,
                indexOfAddressToEdit: customer.addresses.indexOf(address),
                edit: true,
            },
            () => {
                this.toggleAddAddressPopup();
            }
        );
    }

    changeDefaultBillingAddress(address) {
        const { customer } = this.props.account;
        toast.show("Standardrechnungsadresse wird geändert...", { type: "info" });
        store
            .dispatch(
                updateStandardBillingAddress(
                    this.props.account.customer.customerUid,
                    customer.addresses.indexOf(address)
                )
            )
            .then(() => {
                this.props.update(customer.azureId);
                toast.show("Standardrechnungsadresse geändert", { type: "success" });
            });
    }

    changeDefaultDeliveryAddress(address) {
        const { customer } = this.props.account;
        toast.show("Standardlieferadresse wird geändert...", { type: "info" });
        store
            .dispatch(
                updateStandardDeliveryAddress(
                    this.props.account.customer.customerUid,
                    customer.addresses.indexOf(address)
                )
            )
            .then(() => {
                this.props.update(customer.azureId);
                toast.show("Standardlieferadresse geändert", { type: "success" });
            });
    }

    render() {
        const { customer } = this.props.account;
        const { theme } = this.props;
        const { windowWidth } = this.state;
        return (
            <React.Fragment>
                <View>
                    {this.props.showBackButton && (
                        <CustomIconButton
                            icon="arrow-left"
                            tooltip="Zurück"
                            position="right"
                            onPress={() => this.props.history.push("/customer/")}
                        ></CustomIconButton>
                    )}
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        maxWidth: "1280px",
                        width: "100%",
                        marginLeft: windowWidth <= desktopBreakpoint ? "auto" : 15,
                        marginRight: windowWidth <= desktopBreakpoint ? "auto" : 15,
                    }}
                >
                    {customer.addresses &&
                        customer.addresses.map((address, index) => {
                            return (
                                <Card
                                    style={{
                                        width:
                                            windowWidth <= tabletBreadpoint
                                                ? "calc(100% - 10px)"
                                                : windowWidth <= desktopBreakpoint
                                                ? "calc( (100% / 3) - 10px)"
                                                : "calc( (100% / 4) - 10px)",
                                        margin: 5,
                                        marginBottom: 10,
                                        minHeight: 200,
                                    }}
                                    contentStyle={{ flex: 1, flexDirection: "column", justifyContent: "space-between" }}
                                    key={index}
                                >
                                    <Card.Title title={address.firstName + " " + address.lastName} />
                                    <Card.Content>
                                        <Text>
                                            {address.Street.name +
                                                " " +
                                                address.housenumber +
                                                "\n" +
                                                address.Street.City.zipCode +
                                                " " +
                                                address.Street.City.name +
                                                "\n\n" +
                                                (address.company ? address.company : "") +
                                                "\n" +
                                                (address.addressDetails ? address.addressDetails : "")}
                                        </Text>
                                    </Card.Content>

                                    <Card.Actions
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "flex-end",
                                            flexDirection: "row",
                                            marginVertical: 15,
                                        }}
                                    >
                                        <View
                                            style={{
                                                justifyContent: "center",
                                                alignItems: "flex-end",
                                                flexDirection: "row",
                                                marginTop: 0,
                                                marginBottom: 0,
                                                width: "100%",
                                            }}
                                        >
                                            <CustomIconButton
                                                icon="pencil"
                                                color={getContrastColor(theme.colors.primary)}
                                                tooltip="Adresse bearbeiten"
                                                onPress={() => this.editAddress(address)}
                                                backgroundStyle={{
                                                    backgroundColor: theme.colors.primary,
                                                    borderRadius: 7,
                                                    marginRight: 15,
                                                }}
                                            ></CustomIconButton>

                                            <CustomIconButton
                                                icon="receipt"
                                                tooltip="als Standardrechnungsadresse wählen"
                                                color={
                                                    address.isDefaultBillingAddress
                                                        ? getContrastColor(theme.colors.accent)
                                                        : getContrastColor(theme.colors.primary)
                                                }
                                                onPress={() => this.changeDefaultBillingAddress(address)}
                                                backgroundStyle={{
                                                    backgroundColor: address.isDefaultBillingAddress
                                                        ? theme.colors.accent
                                                        : theme.colors.primary,
                                                    borderRadius: 7,
                                                    marginRight: 15,
                                                }}
                                            ></CustomIconButton>
                                            <CustomIconButton
                                                icon="truck"
                                                tooltip="als Standardlieferadresse wählen"
                                                color={
                                                    address.isDefaultDeliveryAddress
                                                        ? getContrastColor(theme.colors.accent)
                                                        : getContrastColor(theme.colors.primary)
                                                }
                                                onPress={() => this.changeDefaultDeliveryAddress(address)}
                                                backgroundStyle={{
                                                    backgroundColor: address.isDefaultDeliveryAddress
                                                        ? theme.colors.accent
                                                        : theme.colors.primary,
                                                    borderRadius: 7,
                                                    marginRight: 15,
                                                }}
                                            ></CustomIconButton>
                                            <CustomIconButton
                                                icon="delete"
                                                tooltip="Adresse löschen"
                                                color={getContrastColor(theme.colors.primary)}
                                                onPress={() => {
                                                    if (customer.addresses.length === 1) {
                                                        toast.show("Sie müssen mindestens eine Adresse angeben", {
                                                            type: "danger",
                                                        });
                                                        return;
                                                    } else {
                                                        this.setState(
                                                            {
                                                                addressToDelete: address,
                                                                indexToDelete: customer.addresses.indexOf(address),
                                                            },
                                                            () => this.toggleConfirmDeletePopup()
                                                        );
                                                    }
                                                }}
                                                backgroundStyle={{
                                                    backgroundColor: theme.colors.primary,
                                                    borderRadius: 7,
                                                    marginRight: 0,
                                                }}
                                            ></CustomIconButton>
                                        </View>
                                    </Card.Actions>
                                </Card>
                            );
                        })}
                    <Card
                        style={{
                            backgroundColor: "transparent",
                            borderWidth: 3,
                            borderStyle: "dashed",
                            borderColor: theme.colors.primary,
                            width:
                                windowWidth <= 450
                                    ? "calc(100% - 10px)"
                                    : windowWidth <= desktopBreakpoint
                                    ? "calc( (100% / 3) - 10px)"
                                    : "calc( (100% / 4) - 10px)",
                            display: "flex",
                            flexWrap: "wrap",
                            margin: 5,
                            cursor: "pointer",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onPress={() => this.toggleAddAddressPopup()}
                    >
                        <Card.Content
                            style={{ margin: "auto", flex: 1, justifyContent: "center", alignItems: "center" }}
                        >
                            <View
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Avatar.Icon
                                    icon="plus"
                                    style={{
                                        backgroundColor: theme.colors.primary,
                                    }}
                                    color={getContrastColor(theme.colors.primary)}
                                    size={38}
                                />
                                <Text style={{ color: theme.colors.text, marginTop: 15 }}>Adresse hinzufügen</Text>
                            </View>
                        </Card.Content>
                    </Card>
                </View>
                {this.state.showAddAddressPopup && (
                    <AddAddressPopup
                        visible={this.state.showAddAddressPopup}
                        togglePopup={this.toggleAddAddressPopup}
                        numberOfAddressesSaved={customer.addresses.length}
                        addressIndex={this.state.indexOfAddressToEdit}
                        addressToEdit={this.state.additionalAddressToEdit}
                        edit={this.state.edit}
                        theme={theme}
                        update={() => this.props.update(customer.azureId)}
                    />
                )}
                {this.state.showConfirmDeletePopup && (
                    <ConfirmDeletePopup
                        visible={this.state.showConfirmDeletePopup}
                        togglePopup={this.toggleConfirmDeletePopup}
                        theme={theme}
                        delete={() => this.deleteAddress()}
                    />
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withTheme(withRouter(CustomerAddresses)));
