import React from "react";
import { View } from "react-native";
import RenderHTML from "react-native-render-html";

export default function ContentText({ text }) {
    // console.log(text);
    if (text) {
        // text = text.replaceAll("<p>", "&lt;span&gt;");
        // text = text.replaceAll("</p>", "&lt;/span&gt;&lt;/br&gt;");

        text = text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");
        // console.log(text);
    }
    return <View nativeID="contentText">{text && <RenderHTML source={{ html: htmlDecode(text) }} />}</View>;
}

function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}
