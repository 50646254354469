import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import moment from "moment";
import React, { Component } from "react";
import { Linking, StyleSheet, View, Platform } from "react-native";
import { ActivityIndicator, DefaultTheme, Icon, Modal, PaperProvider, Portal, Text } from "react-native-paper";
import { de, registerTranslation } from "react-native-paper-dates";
import { ToastProvider } from "react-native-toast-notifications";
import Toast from "react-native-toast-notifications";
import { connect, Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Config from "./Config";
import { config } from "./src/AzureConfig";
import MainLayout from "./src/layouts/MainLayout";
import { PrivateRoute } from "./src/routers/PrivateRoute";
import { Route, Router, Switch } from "./src/routers/routing";
import CookingTerminal from "./src/screens/cookingTerminal/CookingTerminal";
import Customer from "./src/screens/customerScreens/Customer";
import CustomerAddresses from "./src/screens/customerScreens/CustomerAddresses";
import CustomerProfile from "./src/screens/customerScreens/CustomerProfile";
import OrderTracking from "./src/screens/orderTracking/OrderTracking";
import AddToCartDialog from "./src/screens/shop/addToCartDialog/AddToCartDialog";
import AddToWeeklyPlanDialog from "./src/screens/shop/addToWeeklyPlanDialog/AddToWeeklyPlanDialog";
import FullCategory from "./src/screens/shop/FullCategory";
import ProductDetails from "./src/screens/shop/ProductDetails";
import Shop from "./src/screens/shop/Shop";
import ShoppingList from "./src/screens/shoppingList/ShoppingList";
import WeeklyPlan from "./src/screens/weeklyPlan/WeeklyPlan";
import { checkIfArticleSizeHasPrices, getContrastColor, getPriceOfArticleSize } from "./src/shared/helpers";
import { styles } from "./src/shared/styles";
import { getCustomer, setCustomer } from "./src/store/actions/accountActions";
import { ADD_ORDER_ITEM } from "./src/store/actions/cartActions";
import { addRecipesToWeeklyPlan, updateCookingProcess } from "./src/store/actions/onlineShopCategoriesActions";
import { getSettings } from "./src/store/actions/settingsActions";
import { persistor, store } from "./src/store/store";

const backendConfig = new Config();

registerTranslation("de", de);

moment.locale("de");

export default class App extends Component {
    constructor(props) {
        super(props);

        if (Platform.OS === "web") {
            require("./src/shared/web.css");
        }

        this.state = {
            windowWidth: window.innerWidth,
            theme: {
                ...DefaultTheme,
                // version: 2,
                dark: true,
                roundness: 2,
            },
            loading: true,
            addToWeeklyPlanVisible: false,
            addToCartVisible: false,
            selectedCustomerCourse: undefined,
            selectedArticleSize: undefined,
            selectedRecipe: undefined,
            selectedCookingProcess: undefined,
            selectedArticle: undefined,
            customerUid: undefined,
            portalVisible: false,
            datePickerVisible: true,
            selectedDate: new moment(),
            currentTask: undefined,
            errorText: undefined,
            redirectCounter: 10,
            isAuthenticated: false,
            error: "",
            user: {},
            mobileMenuOpen: false,
            cartOpen: false,
            customerUid: "",
            paymentLink: "",
            headerHeight: "0px",
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.toggleAddToWeeklyPlanDialog = this.toggleAddToWeeklyPlanDialog.bind(this);
        this.showAddRecipeToWeeklyPlanPopup = this.showAddRecipeToWeeklyPlanPopup.bind(this);
        this.addRecipeToWeeklyPlan = this.addRecipeToWeeklyPlan.bind(this);
        this.toggleAddToCartDialog = this.toggleAddToCartDialog.bind(this);
        this.showAddArticleToCartPopup = this.showAddArticleToCartPopup.bind(this);
        this.addArticleToCart = this.addArticleToCart.bind(this);
        this.toggleCart = this.toggleCart.bind(this);
        this.toggleCustomerData = this.toggleCustomerData.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setRedirectCounter = this.setRedirectCounter.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.updateCookingProcess = this.updateCookingProcess.bind(this);
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
        this.updateCustomer = this.updateCustomer.bind(this);
        this.cookRecipe = this.cookRecipe.bind(this);
        this.publicClientApplication = new PublicClientApplication(config);
    }

    async updateCustomer(azureId) {
        store.dispatch(getCustomer(azureId)).then((response) => {
            let customer = {
                customerUid: response.customerUid,
                azureId: response.azureId,
                firstName: response.firstName,
                familyName: response.familyName,
                addresses: JSON.parse(response.addresses),
                zipCode: response.zipCode,
                title: response.title,
                email: response.email,
                houseNumber: response.houseNumber,
                phoneNumber: response.phonenumber,
                customerCourses: JSON.parse(response.customerCourses),
            };
            store.dispatch(setCustomer(customer));
        });
    }

    async login() {
        try {
            await this.publicClientApplication
                .loginPopup({
                    scopes: config.scopes,
                    prompt: "select_account",
                })
                .then((response) => {
                    this.updateCustomer(response.uniqueId);
                    this.setState({ customerUid: response.uniqueId });
                    localStorage.setItem("customersUid", response.uniqueId);
                });
        } catch (err) {
            this.setState({ isAuthenticated: false, user: {}, error: err });
        }
    }

    logout() {
        store.dispatch(setCustomer(undefined));
        localStorage.removeItem("customersUid");
        this.publicClientApplication.logout();
    }

    componentDidMount() {
        store.dispatch(getSettings()).then((res) => {
            if (res) {
                this.setState({ loading: false, theme: res });
                this.publicClientApplication = new PublicClientApplication(config);
            }
        });
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    updateDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }

    updateHeaderHeight = (height) => {
        this.setState({ headerHeight: height });
    };

    toggleAddToWeeklyPlanDialog() {
        var visibility = !this.state.addToWeeklyPlanVisible;
        document.body.style.overflow = visibility ? "hidden" : "auto";
        this.setState({
            addToWeeklyPlanVisible: visibility,
            portalVisible: visibility,
        });
    }

    toggleAddToCartDialog() {
        var visibility = !this.state.addToCartVisible;
        document.body.style.overflow = visibility ? "hidden" : "auto";
        this.setState({
            addToCartVisible: visibility,
            portalVisible: visibility,
        });
    }

    showAddRecipeToWeeklyPlanPopup(
        selectedRecipe,
        customerUid,
        selectedCookingProcess,
        selectedArticleSize = undefined
    ) {
        document.body.style.overflow = "hidden";
        this.setState({
            selectedRecipe: selectedRecipe,
            addToWeeklyPlanVisible: true,
            customerUid: customerUid,
            portalVisible: true,
            selectedCookingProcess: selectedCookingProcess,
            startCookingProcessImmediately: false,
            selectedArticleSize: selectedArticleSize,
        });
    }

    showAddArticleToCartPopup(selectedArticle, customerUid, orderType, priceGroupsId, selectedArticleSize = undefined) {
        document.body.style.overflow = "hidden";
        this.setState({
            selectedArticle: selectedArticle,
            addToCartVisible: true,
            customerUid: customerUid,
            portalVisible: true,
            orderType: orderType,
            priceGroupsId: priceGroupsId,
            selectedArticleSize: selectedArticleSize,
        });
    }

    addRecipeToWeeklyPlan(customerCoursesId, articleSizesId, recipesId, dayToCook, amount, urlBasePath) {
        this.setState({
            addToWeeklyPlanLoading: true,
        });

        var recipesToWeeklyPlanDto = {
            customersUid: this.state.customerUid,
            RecipesForWeeklyPlan: [
                {
                    recipesId: recipesId,
                    articleSizesId: articleSizesId,
                    amount: amount,
                    dayToCook: dayToCook,
                    customerCourse: customerCoursesId,
                },
            ],
        };
        store.dispatch(addRecipesToWeeklyPlan(recipesToWeeklyPlanDto)).then((result) => {
            this.setState({
                selectedRecipe: undefined,
                addToWeeklyPlanLoading: false,
            });
            this.toggleAddToWeeklyPlanDialog();
            if (result.cookingProcessId > 0) {
                if (this.state.startCookingProcessImmediately) {
                    // window.open(
                    //     "https://demo.bios.gluecklichegaeste.de/#/guest/allCookingProcessesCustomer/" +
                    //         this.state.customerUid +
                    //         "/c/" +
                    //         result.cookingProcessId
                    // );
                    this.setState(
                        {
                            cookingProcessId: result.cookingProcessId,
                            urlBasePath: urlBasePath,
                        },
                        () => {
                            document.getElementById("popupLink").click();
                        }
                    );
                }
            } else {
                alert("Fehler beim Anlegen des Kochprozesses");
            }
        });
    }

    updateCookingProcess(customerCoursesId, articleSizesId, dayToCook, amount, cookingProcessId) {
        var updateCookingProcessDto = {
            customersUid: this.state.customerUid,
            articleSizesId: articleSizesId,
            amount: amount,
            dayToCook: dayToCook,
            customerCourse: customerCoursesId,
            cookingProcessId: cookingProcessId,
        };
        store.dispatch(updateCookingProcess(updateCookingProcessDto)).then((result) => {
            this.setState({
                addToWeeklyPlanVisible: false,
                selectedCookingProcess: undefined,
                portalVisible: false,
            });
        });
    }

    addArticleToCart(
        articleSize,
        article,
        amount,
        optionalOrderType = this.state.orderType,
        optionalPriceGroupsId = this.state.priceGroupsId
    ) {
        const articleCopy = { ...article, amount: amount };

        const orderItem = {
            entry: articleCopy,
            ArticleSize: articleSize,
            Price: getPriceOfArticleSize(articleSize, optionalOrderType.id, optionalPriceGroupsId),
            orderType: optionalOrderType,
            dayToCook: new moment(),
        };

        document.body.style.overflow = "auto";
        store.dispatch({ type: ADD_ORDER_ITEM, orderItem });
        this.setState({
            addToCartVisible: false,
            selectedArticle: undefined,
            portalVisible: false,
        });
    }

    toggleCart() {
        var visibility = !this.state.cartOpen;
        this.setState({
            cartOpen: visibility,
            portalVisible: visibility,
        });
    }

    toggleMobileMenu() {
        var visibility = !this.state.mobileMenuOpen;
        this.setState({
            mobileMenuOpen: visibility,
            portalVisible: visibility,
        });
    }

    toggleCustomerData() {
        var visibility = !this.state.customerDataOpen;
        document.body.style.overflow = visibility ? "hidden" : "auto";
        this.setState({
            customerDataOpen: visibility,
            portalVisible: visibility,
            cartOpen: false,
        });
    }

    setLoading(currentTask, error, paymentLink = "") {
        this.setState({
            currentTask: currentTask,
            errorText: error,
            orderProcessingActive: currentTask || error,
            portalVisible: true,
            paymentLink: paymentLink,
        });
    }

    setRedirectCounter(counter) {
        if (counter >= 0)
            this.setState({
                redirectCounter: counter,
            });
        else this.setState({ portalVisible: false, redirectCounter: 10 });
    }

    cookRecipe(selectedRecipe, customerUid, selectedArticleSize = undefined) {
        document.body.style.overflow = "hidden";
        this.setState({
            selectedRecipe: selectedRecipe,
            addToWeeklyPlanVisible: true,
            customerUid: customerUid,
            portalVisible: true,
            startCookingProcessImmediately: true,
            selectedArticleSize: selectedArticleSize,
        });
    }

    dismissOrderProcessing = () => {
        this.setState({ orderProcessingActive: false });
    };

    render() {
        const { theme, loading } = this.state;

        return (
            <Provider store={store}>
                <ToastProvider>
                    <Toast ref={(ref) => (global["toast"] = ref)} />
                    <MsalProvider instance={this.publicClientApplication}>
                        <PersistGate loading={null} persistor={persistor}>
                            <Router>
                                <PaperProvider theme={theme}>
                                    <a
                                        id="popupLink"
                                        rel="noreferrer noopener"
                                        style={{ display: "none" }}
                                        target="_blank"
                                        href={
                                            store.getState().settings.store.urlBasePath +
                                            "guest/allCookingProcessesCustomer/" +
                                            this.state.customerUid +
                                            "/c/" +
                                            this.state.cookingProcessId
                                        }
                                    >
                                        Kochmonitor
                                    </a>
                                    <View
                                        style={{
                                            ...StyleSheet.flatten(styles.mainContainer),
                                            backgroundColor: theme.colors.background,
                                        }}
                                    >
                                        <View
                                            style={
                                                this.state.portalVisible
                                                    ? styles.screenContainerPortal
                                                    : styles.screenContainer
                                            }
                                        >
                                            {!this.state.loading && (
                                                <MainLayout
                                                    toggleLoginPopup={this.login}
                                                    logout={this.logout}
                                                    toggleCart={this.toggleCart}
                                                    toggleMobileMenu={this.toggleMobileMenu}
                                                    toggleCustomerData={this.toggleCustomerData}
                                                    cartOpen={this.state.cartOpen}
                                                    mobileMenuOpen={this.state.mobileMenuOpen}
                                                    customerDataOpen={this.state.customerDataOpen}
                                                    setLoading={this.setLoading}
                                                    setRedirectCounter={this.setRedirectCounter}
                                                    redirectCounter={this.state.redirectCounter}
                                                    updateCustomer={this.updateCustomer}
                                                    updateHeaderHeight={this.updateHeaderHeight}
                                                >
                                                    <Switch>
                                                        <Route
                                                            path="/"
                                                            exact
                                                            render={() => (
                                                                <Shop
                                                                    showAddRecipeToWeeklyPlanPopup={
                                                                        this.showAddRecipeToWeeklyPlanPopup
                                                                    }
                                                                    showAddArticleToCartPopup={
                                                                        this.showAddArticleToCartPopup
                                                                    }
                                                                    cookRecipe={this.cookRecipe}
                                                                    onlineShopItemType={-1}
                                                                    toggleLoginPopup={this.login}
                                                                />
                                                            )}
                                                        />
                                                        <Route
                                                            path="/shop"
                                                            exact
                                                            render={() => (
                                                                <Shop
                                                                    showAddRecipeToWeeklyPlanPopup={
                                                                        this.showAddRecipeToWeeklyPlanPopup
                                                                    }
                                                                    showAddArticleToCartPopup={
                                                                        this.showAddArticleToCartPopup
                                                                    }
                                                                    cookRecipe={this.cookRecipe}
                                                                    onlineShopItemType={0}
                                                                    toggleLoginPopup={this.login}
                                                                />
                                                            )}
                                                        />
                                                        <Route
                                                            path="/recipes"
                                                            exact
                                                            render={() => (
                                                                <Shop
                                                                    showAddRecipeToWeeklyPlanPopup={
                                                                        this.showAddRecipeToWeeklyPlanPopup
                                                                    }
                                                                    showAddArticleToCartPopup={
                                                                        this.showAddArticleToCartPopup
                                                                    }
                                                                    cookRecipe={this.cookRecipe}
                                                                    onlineShopItemType={1}
                                                                    toggleLoginPopup={this.login}
                                                                />
                                                            )}
                                                        />
                                                        <PrivateRoute
                                                            path="/wochenplan"
                                                            exact
                                                            render={() => (
                                                                <WeeklyPlan
                                                                    showAddRecipeToWeeklyPlanPopup={
                                                                        this.showAddRecipeToWeeklyPlanPopup
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <PrivateRoute
                                                            path="/cookingTerminal"
                                                            exact
                                                            component={CookingTerminal}
                                                        />
                                                        <PrivateRoute
                                                            path="/shoppingList"
                                                            exact
                                                            component={ShoppingList}
                                                        />
                                                        <Route
                                                            path="/category/:id/:backend"
                                                            exact
                                                            render={() => (
                                                                <FullCategory
                                                                    showAddRecipeToWeeklyPlanPopup={
                                                                        this.showAddRecipeToWeeklyPlanPopup
                                                                    }
                                                                    showAddArticleToCartPopup={
                                                                        this.showAddArticleToCartPopup
                                                                    }
                                                                    cookRecipe={this.cookRecipe}
                                                                    toggleLoginPopup={this.login}
                                                                />
                                                            )}
                                                        />
                                                        <Route
                                                            path="/ProductDetails/:type/:id/:backend"
                                                            exact
                                                            render={() => (
                                                                <ProductDetails
                                                                    showAddRecipeToWeeklyPlanPopup={
                                                                        this.showAddRecipeToWeeklyPlanPopup
                                                                    }
                                                                    showAddArticleToCartPopup={
                                                                        this.showAddArticleToCartPopup
                                                                    }
                                                                    cookRecipe={this.cookRecipe}
                                                                    addArticleToCart={this.addArticleToCart}
                                                                    toggleLoginPopup={this.login}
                                                                    headerHeight={this.state.headerHeight}
                                                                />
                                                            )}
                                                        />
                                                        <PrivateRoute
                                                            path="/customer"
                                                            exact
                                                            render={() => <Customer update={this.updateCustomer} />}
                                                        />
                                                        <PrivateRoute
                                                            path="/customer/addresses"
                                                            exact
                                                            render={() => (
                                                                <CustomerAddresses
                                                                    update={this.updateCustomer}
                                                                    showBackButton={true}
                                                                />
                                                            )}
                                                        />
                                                        <PrivateRoute
                                                            path="/customer/profile"
                                                            exact
                                                            render={() => (
                                                                <CustomerProfile update={this.updateCustomer} />
                                                            )}
                                                        />
                                                        <Route
                                                            path="/orderTracking/:id?/:paymentSuccessfull?/:returnString?"
                                                            exact
                                                            render={(props) => <OrderTracking {...props} />}
                                                        />
                                                    </Switch>
                                                </MainLayout>
                                            )}
                                        </View>
                                    </View>
                                    <Portal>
                                        <View
                                            style={{
                                                maxHeight: "100vh",
                                                position: "fixed",
                                                top: 0,
                                                width: "100%",
                                                height: "100%",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            {this.state.currentTask && (
                                                <Portal
                                                    visible={this.state.orderProcessingActive}
                                                    dismissable={true}
                                                    onDismiss={this.dismissOrderProcessing}
                                                >
                                                    {this.state.portalVisible && this.state.redirectCounter > 0 && (
                                                        <View style={styles.orderProcessingOverlayContainer}>
                                                            <View style={styles.orderProcessingOverlay}>
                                                                <Text style={{ fontSize: 18 }}>
                                                                    {this.state.currentTask}...
                                                                    {this.state.redirectCounter < 10
                                                                        ? this.state.redirectCounter
                                                                        : ""}
                                                                    {this.state.paymentLink && (
                                                                        <Text
                                                                            style={{ color: "blue" }}
                                                                            onPress={() => {
                                                                                const url = this.state.paymentLink;

                                                                                if (Platform.OS === "web") {
                                                                                    window.location.href = url;
                                                                                } else {
                                                                                    Linking.canOpenURL(url)
                                                                                        .then((canOpen) => {
                                                                                            if (canOpen) {
                                                                                                Linking.openURL(url);
                                                                                            } else {
                                                                                                console.log(
                                                                                                    "Cannot open URL: " +
                                                                                                        url
                                                                                                );
                                                                                            }
                                                                                        })
                                                                                        .catch((err) => {
                                                                                            console.log(err);
                                                                                        });
                                                                                }

                                                                                this.setState({
                                                                                    orderProcessingActive: false,
                                                                                });
                                                                            }}
                                                                        >
                                                                            {" hier"}
                                                                        </Text>
                                                                    )}
                                                                </Text>
                                                                <ActivityIndicator
                                                                    style={{ marginTop: 15 }}
                                                                    size="large"
                                                                />
                                                            </View>
                                                        </View>
                                                    )}
                                                </Portal>
                                            )}
                                            {this.state.orderProcessingActive && this.state.errorText && (
                                                <View style={styles.orderProcessingOverlayContainer}>
                                                    <View style={styles.orderProcessingOverlay}>
                                                        <Text
                                                            variant="headlineMedium"
                                                            style={[
                                                                styles.overlayErrorText,
                                                                { color: theme.colors.error },
                                                            ]}
                                                        >
                                                            {this.state.errorText}
                                                        </Text>
                                                        <View
                                                            style={[
                                                                styles.overlayErrorIconContainer,
                                                                { backgroundColor: theme.colors.error },
                                                            ]}
                                                        >
                                                            <Icon
                                                                source="exclamation-thick"
                                                                color={getContrastColor(theme.colors.error)}
                                                                size={20}
                                                            />
                                                        </View>
                                                    </View>
                                                </View>
                                            )}
                                            {loading && (
                                                <View
                                                    style={{
                                                        ...StyleSheet.flatten(styles.loadingContainer),
                                                        backgroundColor: "#FFFFF",
                                                    }}
                                                >
                                                    <ActivityIndicator size="large" />
                                                </View>
                                            )}
                                            {this.state.selectedRecipe && (
                                                <AddToWeeklyPlanDialog
                                                    addToWeeklyPlanVisible={this.state.addToWeeklyPlanVisible}
                                                    toggleAddToWeeklyPlanDialog={this.toggleAddToWeeklyPlanDialog}
                                                    selectedRecipe={this.state.selectedRecipe}
                                                    selectedCookingProcess={this.state.selectedCookingProcess}
                                                    loading={this.state.addToWeeklyPlanLoading}
                                                    articleSizes={
                                                        this.state.selectedRecipe
                                                            ? this.state.selectedRecipe.articleSizes
                                                            : undefined
                                                    }
                                                    recipesId={
                                                        this.state.selectedRecipe ? this.state.selectedRecipe.id : 0
                                                    }
                                                    addRecipeToWeeklyPlan={this.addRecipeToWeeklyPlan}
                                                    updateCookingProcess={this.updateCookingProcess}
                                                    startCookingProcessImmediately={
                                                        this.state.startCookingProcessImmediately
                                                    }
                                                    selectedArticleSize={this.state.selectedArticleSize}
                                                />
                                            )}
                                            {this.state.selectedArticle && (
                                                <AddToCartDialog
                                                    addToCartVisible={this.state.addToCartVisible}
                                                    toggleAddToCartDialog={this.toggleAddToCartDialog}
                                                    selectedArticle={this.state.selectedArticle}
                                                    articleSizes={
                                                        this.state.selectedArticle
                                                            ? this.state.selectedArticle.articleSizes.filter(
                                                                  (articleSize) =>
                                                                      checkIfArticleSizeHasPrices(
                                                                          articleSize,
                                                                          this.state.orderType.id,
                                                                          this.state.priceGroupsId
                                                                      )
                                                              )
                                                            : []
                                                    }
                                                    articlesId={
                                                        this.state.selectedArticle ? this.state.selectedArticle.id : 0
                                                    }
                                                    addArticleToCart={this.addArticleToCart}
                                                    selectedArticleSize={this.state.selectedArticleSize}
                                                />
                                            )}
                                        </View>
                                    </Portal>
                                </PaperProvider>
                            </Router>
                        </PersistGate>
                    </MsalProvider>
                </ToastProvider>
            </Provider>
        );
    }
}
