import React from "react";
import { Paragraph } from "react-native-paper";
import moment from "moment";
import { View } from "react-native";

export default function WeeklyPlanDays({ props, monday }) {
    return (
        <View
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
                backgroundColor: props.theme.colors.background,
                flex: 1,
            }}
        >
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph>Mo.</Paragraph>
                <Paragraph>{monday.format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph>Di.</Paragraph>
                <Paragraph>{moment(monday).add(1, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph>Mi.</Paragraph>
                <Paragraph>{moment(monday).add(2, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph>Do.</Paragraph>
                <Paragraph>{moment(monday).add(3, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph>Fr.</Paragraph>
                <Paragraph>{moment(monday).add(4, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph>Sa.</Paragraph>
                <Paragraph>{moment(monday).add(5, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph>So.</Paragraph>
                <Paragraph>{moment(monday).add(6, "days").format("D.M.")}</Paragraph>
            </View>
        </View>
    );
}
