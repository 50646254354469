import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";
import { store } from "../../store/store";

const config = new Config();

export function getArticlesForOnlineShop(instanceUrl) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ instanceUrl: instanceUrl.replaceAll("_", ".") }),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/getArticlesForOnlineShop", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => store.dispatch(setArticles(json, instanceUrl.replaceAll("_", "."))));
    };
}

export function getRecipesForOnlineShop(instanceUrl) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/getRecipesForOnlineShop", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => store.dispatch(setRecipes(json, instanceUrl.replaceAll("_", "."))));
    };
}

export function getRecommendationsForOnlineShop(instanceUrl) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ instanceUrl: instanceUrl.replaceAll("_", ".") }),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/getRecommendationsForOnlineShop", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => store.dispatch(setRecommendations(json, instanceUrl.replaceAll("_", "."))));
    };
}

export function getCurrentRecommendationsForOnlineShop(instanceUrl) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(
            instanceUrl.replaceAll("_", ".") + "/OnlineShop/GetCurrentRecommendationsForOnlineShop",
            requestOptions
        )
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => store.dispatch(setCurrentRecommendations(json, instanceUrl.replaceAll("_", "."))));
    };
}

export function addRecipesToWeeklyPlan(recipesToWeeklyPlanDto) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(recipesToWeeklyPlanDto),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/OnlineShop/AddRecipesToWeeklyPlan", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function updateCookingProcess(updateCookingProcessDto) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updateCookingProcessDto),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/OnlineShop/updateCookingProcess", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getCategoryById(instanceUrl, categoriesId) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/GetCategoryById/" + categoriesId, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getArticleSizesByRecipesId(instanceUrl, recipesId) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(
            instanceUrl.replaceAll("_", ".") + "/OnlineShop/GetArticleSizesByRecipesId/" + recipesId,
            requestOptions
        )
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}
export function getIngredientsInOnlineShopwithRecipesId(instanceUrl, recipesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(recipesId),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(
            instanceUrl.replaceAll("_", ".") + "/OnlineShop/GetIngredientsInOnlineShopWithRecipesId/",
            requestOptions
        )
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}
export function getIngredientsInOnlineShopwithArticlesId(instanceUrl, articlesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(articlesId),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(
            instanceUrl.replaceAll("_", ".") + "/OnlineShop/GetIngredientsInOnlineShopWithArticlesId/",
            requestOptions
        )
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getBiosInstances() {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/getBiosInstances", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getRecipesForOnlineShopForWeeklyPlan(instanceUrl) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(config.backendHost + "/OnlineShop/GetRecipesForOnlineShopForWeeklyPlan", requestOptions).then(
            (response) => handleResponse(response),
            (err) => console.log(err)
        );
    };
}

export function getSimilarArticles(instanceUrl, articlesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(articlesId),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/getSimilarArticles", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getOnlineShopItemByArticlesId(instanceUrl, articlesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(articlesId),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/getOnlineShopItemByArticlesId", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getOnlineShopItemByRecipesId(instanceUrl, recipesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(recipesId),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/getOnlineShopItemByRecipesId", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getSimilarRecipes(instanceUrl, articlesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(articlesId),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/getSimilarRecipes", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getAltAndTitleByGuidFilenName(instanceUrl, guidFileName) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(guidFileName),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        // console.log(instanceUrl);
        return fetch(instanceUrl.replaceAll("_", ".") + "/media/getAltAndTitleByGuidFilenName", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getArticleRatings(instanceUrl, articlesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(articlesId),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/getArticleRatings", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}
export function getRecipeRatings(instanceUrl, recipesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(recipesId),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/getRecipeRatings", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function rateArticle(instanceUrl, articlesId, customerUid, rating, ratingText) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            articlesId: articlesId,
            customerUid: customerUid,
            rating: rating,
            ratingText: ratingText,
        }),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/Articles/rateArticle", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}
export function rateRecipe(instanceUrl, recipesId, customerUid, rating, ratingText) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            recipesId: recipesId,
            customerUid: customerUid,
            rating: rating,
            ratingText: ratingText,
        }),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/Recipes/rateRecipe", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export const SET_ONLINESHOPITEMTYPE = "SET_ONLINESHOPITEMTYPE";
export const setOnlineShopItemType = (type) => {
    return {
        type: SET_ONLINESHOPITEMTYPE,
        onlineShopItemType: type,
    };
};

export const SET_RECIPES = "SET_RECIPES";
export const setRecipes = (categories, url) => {
    return {
        type: SET_RECIPES,
        payload: categories,
        url: url,
    };
};
export const SET_ARTICLES = "SET_ARTICLES";
export const setArticles = (categories, url) => {
    return {
        type: SET_ARTICLES,
        payload: categories,
        url: url,
    };
};
export const SET_RECOMMENDATIONS = "SET_RECOMMENDATIONS";
export const setRecommendations = (recommendations, url) => {
    return {
        type: SET_RECOMMENDATIONS,
        payload: recommendations,
        url: url,
    };
};
export const SET_CURRENT_RECOMMENDATIONS = "SET_CURRENT_RECOMMENDATIONS";
export const setCurrentRecommendations = (currentRecommendations) => {
    return {
        type: SET_CURRENT_RECOMMENDATIONS,
        payload: currentRecommendations,
    };
};

export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY";
export const setCurrentCategory = (currentCategory) => {
    return {
        type: SET_CURRENT_CATEGORY,
        payload: currentCategory,
    };
};

export function getArticleDetailsByArticlesId(instanceUrl, articlesId) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(
            instanceUrl.replaceAll("_", ".") + "/ArticleDetailsRow/getArticleDetailsByArticlesId/" + articlesId,
            requestOptions
        )
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getAssignedNutritionalValues(instanceUrl) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    } else {
        if (!instanceUrl) {
            instanceUrl = config.backendHost;
        }
    }

    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/Articles/GetAssignedNutritionalValues", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}
