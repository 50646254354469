import React, { Component } from "react";
import { Pressable, View } from "react-native";
import { ProgressBar, Text } from "react-native-paper";

export default class RatingOverview extends Component {
    constructor(props) {
        super(props);

        this.possibleRatings = [5, 4, 3, 2, 1];
    }

    render() {
        return (
            <View
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: this.props.mobile ? 20 : 50,
                    width: this.props.mobile ? "100%" : 300,
                }}
            >
                <Pressable onPress={() => this.props.filterRatings(-1)}>
                    <Text style={{ fontSize: 10, marginBottom: 10 }}>{this.props.ratings.length + " Bewertungen"}</Text>
                </Pressable>
                {this.possibleRatings.map((numberOfStars, index) => {
                    return (
                        <Pressable key={index} onPress={() => this.props.filterRatings(numberOfStars)}>
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    marginBottom: 10,
                                }}
                            >
                                <Text>{numberOfStars + (numberOfStars === 1 ? " Stern:   " : " Sterne: ")}</Text>

                                <ProgressBar
                                    style={{ height: 12, width: 100, marginLeft: 5 }}
                                    progress={
                                        this.props.ratings.filter((rating) => rating.rating === numberOfStars).length /
                                        this.props.ratings.length
                                    }
                                />
                            </View>
                        </Pressable>
                    );
                })}
            </View>
        );
    }
}
