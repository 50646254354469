import React, { Component, PureComponent } from "react";
import { FlatList, View } from "react-native";
import { Button, IconButton, Text, Title, withTheme } from "react-native-paper";
import { withRouter } from "react-router";
import ShopItem from "./ShopItem";
import { ShopStyles } from "./ShopStyles";
import { Ionicons } from "@expo/vector-icons";
import { Colors } from "react-native-paper";
import { store } from "../../store/store";
import { setCurrentCategory } from "../../store/actions/onlineShopCategoriesActions";

class Category extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            scrollingPosition: 0,
            numberOfTiles: 0,
            hoveredItem: undefined,
        };
        this.ref = React.createRef();
        this.renderItem = this.renderItem.bind(this);
        this.goToCategory = this.goToCategory.bind(this);
        this.scrollLeft = this.scrollLeft.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.setHoveredItem = this.setHoveredItem.bind(this);
        this.renderCell = this.renderCell.bind(this);
    }

    renderItem(item) {
        const { mobile } = this.props;
        return (
            <ShopItem
                item={item}
                url={this.props.category.url}
                mobile={mobile}
                setHoveredItem={this.setHoveredItem}
                hoveredItem={this.state.hoveredItem}
                showAddRecipeToWeeklyPlanPopup={this.props.showAddRecipeToWeeklyPlanPopup}
                showAddArticleToCartPopup={this.props.showAddArticleToCartPopup}
                cookRecipe={this.props.cookRecipe}
                toggleLoginPopup={this.props.toggleLoginPopup}
            />
        );
    }

    goToCategory() {
        const { history, mobile } = this.props;
        store.dispatch(setCurrentCategory(this.props.category));
        history.push(
            "/category/" + this.props.category.categoriesId + "/" + encodeURIComponent(this.props.category.url)
        );
    }

    scrollLeft() {
        this.ref.scrollToIndex({
            animated: true,
            index: this.state.scrollingPosition - 3,
        });

        this.setState({ scrollingPosition: this.state.scrollingPosition - 3 });
    }

    scrollRight() {
        this.ref.scrollToIndex({
            animated: true,
            index: this.state.scrollingPosition + 3,
        });

        this.setState({ scrollingPosition: this.state.scrollingPosition + 3 });
    }

    setHoveredItem(id) {
        this.setState({
            hoveredItem: id,
        });
    }

    renderCell({ index, style, ...props }) {
        const zIndex = {
            zIndex: this.state.hoveredItem && props.item.id === this.state.hoveredItem ? 2 : 0,
        };

        return <View style={[style, zIndex]} {...props} />;
    }

    render() {
        const theme = this.props.theme;
        const mobile = this.props.mobile;
        const numberOfTiles = this.props.numberOfTiles;
        return (
            <View key={this.props.key}>
                {this.props.category && (
                    <React.Fragment>
                        <Title
                            style={{
                                marginLeft: mobile ? 20 : 50,
                                fontSize: mobile ? 18 : 28,
                                marginBottom: mobile ? 0 : -70,
                                marginTop: mobile ? 10 : -40,
                                marginRight: mobile ? 20 : 50,
                                zIndex: this.state.hoveredItem ? 0 : 9999,
                            }}
                        >
                            {this.props.category.name}

                            {this.props.category.categoriesId !== -1 && (
                                <Button
                                    style={{ fontSize: mobile ? 10 : 12 }}
                                    labelStyle={{ marginBottom: mobile ? 0 : 0 }}
                                    onPress={this.goToCategory}
                                    rippleColor="transparent"
                                >
                                    Mehr...
                                </Button>
                            )}
                        </Title>
                        <View style={mobile ? ShopStyles.CategoryMobile : ShopStyles.Category}>
                            <FlatList
                                data={this.props.onlineShopItems}
                                showsHorizontalScrollIndicator={false}
                                horizontal={true}
                                renderItem={this.renderItem}
                                maxToRenderPerBatch={6}
                                style={{
                                    marginLeft: mobile ? 20 : 40,
                                    marginRight: mobile ? 20 : 50,
                                }}
                                contentContainerStyle={{
                                    // height: mobile ? 120 : 350,
                                    height: mobile ? 220 : 350,
                                    alignItems: "center",
                                    overflow: "visible",
                                }}
                                CellRendererComponent={this.renderCell}
                                initialNumToRender={6}
                                ref={(ref) => (this.ref = ref)}
                                keyExtractor={(item, index) => item.id}
                            />
                            {this.props.onlineShopItems.length >= numberOfTiles - 1 && //Scroll anfang
                                this.state.scrollingPosition < this.props.onlineShopItems.length - numberOfTiles && //Scroll ende
                                !mobile && (
                                    <Ionicons
                                        name="chevron-forward"
                                        color={theme.colors.primary}
                                        size={40}
                                        style={ShopStyles.nextButton}
                                        onPress={this.scrollRight}
                                    ></Ionicons>
                                )}
                            {this.state.scrollingPosition > 0 && !mobile && (
                                <Ionicons
                                    name="chevron-back"
                                    style={ShopStyles.previousButton}
                                    onPress={this.scrollLeft}
                                    size={40}
                                    color={theme.colors.primary}
                                />
                            )}
                        </View>
                    </React.Fragment>
                )}
            </View>
        );
    }
}

export default withRouter(withTheme(Category));
