import React, { Component } from "react";
import { View, Text } from "react-native";
import { ActivityIndicator, Button, Dialog, Paragraph, withTheme } from "react-native-paper";
import { connect } from "react-redux";

import { getContrastColor } from "../../../shared/helpers";
import { Picker } from "@react-native-picker/picker";
import AutocompleteRecipe from "./AutocompleteRecipe";
import { styles } from "./ChooseRecipeDialogStyles";

class ChooseRecipeDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedArticleSizeId: undefined,
            selectedRecipe: undefined,
            amount: 1,
        };

        this.addRecipeToWeeklyPlan = this.addRecipeToWeeklyPlan.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedCustomerCoursesId !== prevProps.selectedCustomerCoursesId ||
            this.props.selectedDate !== prevProps.selectedDate
        ) {
            this.setState({
                selectedArticleSizeId: undefined,
                selectedRecipe: undefined,
                articleSizes: undefined,
                amount: 1,
            });
        }
    }

    onRecipeSelect = (recipe) => {
        this.setState({
            selectedRecipe: recipe,
            selectedRecipeError: false,
            articleSizes: recipe ? recipe.articleSizes : undefined,
            selectedArticleSizeId:
                recipe && recipe.articleSizes.length > 0 ? recipe.articleSizes[0].articleSizesId : undefined,
        });
    };

    addRecipeToWeeklyPlan() {
        var errorFound = false;
        if (!this.state.selectedRecipe) {
            this.setState({
                selectedRecipeError: true,
            });
            errorFound = true;
        } else {
            this.setState({
                selectedRecipeError: false,
            });
        }
        if (!this.state.selectedArticleSizeId) {
            this.setState({
                selectedArticleSizesIdError: true,
            });
            errorFound = true;
        } else {
            this.setState({
                selectedArticleSizesIdError: false,
            });
        }
        if (!errorFound) {
            this.props.addRecipeToWeeklyPlan(
                this.props.selectedCustomerCoursesId,
                this.state.selectedArticleSizeId,
                this.state.selectedRecipe.id,
                this.props.selectedDate,
                this.state.amount,
                this.props.settings.urlBasePath
            );
        }
    }

    render() {
        return (
            <Dialog
                visible={this.props.chooseRecipeDialogVisible}
                onDismiss={this.props.toggleChooseRecipeDialog}
                style={{ marginLeft: "auto", marginRight: "auto" }}
            >
                <Dialog.Title>Wähle ein Rezept aus</Dialog.Title>
                <Dialog.Content style={{ zIndex: 10 }}>
                    {this.props.loading && (
                        <View
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ActivityIndicator
                                size={"large"}
                                animating={true}
                                color={this.props.theme.colors.primary}
                            />
                        </View>
                    )}
                    {this.props.recipes && this.props.recipes.length >= 1 && (
                        <View style={{ marginBottom: 20, zIndex: 10 }}>
                            <AutocompleteRecipe
                                recipes={this.props.recipes}
                                onRecipeSelect={this.onRecipeSelect}
                                searchLabel="Rezept suchen"
                                selectedRecipeLabel="Ausgewähltes Rezept:"
                                inputBorderColor={this.props.theme.colors.primary}
                                inputBackgroundColor={this.props.theme.colors.surface}
                                inputTextColor={getContrastColor(this.props.theme.colors.surface)}
                                listBorderColor={this.props.theme.colors.primary}
                                listBackgroundColor={this.props.theme.colors.surface}
                                listTextColor={getContrastColor(this.props.theme.colors.surface)}
                                hoverBackgroundColor={this.props.theme.colors.primary}
                                hoverTextColor={getContrastColor(this.props.theme.colors.primary)}
                            />

                            {this.state.selectedRecipeError && (
                                <Paragraph style={{ color: this.props.theme.colors.error }}>
                                    Dies ist eine Pflichtangabe
                                </Paragraph>
                            )}
                        </View>
                    )}
                    {this.state.articleSizes && this.state.articleSizes.length > 1 && (
                        <View style={{ marginBottom: 10 }}>
                            <Text style={styles.chooseRecipeDialogSelectLabel}>Größe</Text>
                            <Picker
                                selectedValue={this.state.selectedArticleSizeId}
                                onValueChange={(itemValue) => this.setState({ selectedArticleSizeId: itemValue })}
                                style={[
                                    styles.chooseRecipeDialogSelect,
                                    {
                                        backgroundColor: this.props.theme.colors.surface,
                                        borderColor: this.props.theme.colors.primary,
                                        color: getContrastColor(this.props.theme.colors.surface),
                                    },
                                ]}
                            >
                                {this.state.articleSizes.map((size) => (
                                    <Picker.Item
                                        key={size.articleSizesId}
                                        label={size.Size.name}
                                        value={size.articleSizesId}
                                    />
                                ))}
                            </Picker>
                        </View>
                    )}
                    <View style={{ marginBottom: "10px" }}>
                        <Text style={styles.chooseRecipeDialogSelectLabel}>Menge</Text>
                        <Picker
                            selectedValue={this.state.amount}
                            onValueChange={(itemValue) => this.setState({ amount: itemValue })}
                            style={[
                                styles.chooseRecipeDialogSelect,
                                {
                                    backgroundColor: this.props.theme.colors.surface,
                                    borderColor: this.props.theme.colors.primary,
                                    color: getContrastColor(this.props.theme.colors.surface),
                                },
                            ]}
                        >
                            {[...Array(11).keys()].map((number) => (
                                <Picker.Item key={number} label={`${number}`} value={number} />
                            ))}
                        </Picker>
                    </View>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button
                        textColor={getContrastColor(this.props.theme.colors.primary)}
                        style={{
                            backgroundColor: this.props.theme.colors.notification,
                            marginRight: 20,
                            paddingLeft: 15,
                            paddingRight: 15,
                        }}
                        uppercase={false}
                        onPress={this.props.toggleChooseRecipeDialog}
                        disabled={this.props.loading}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        textColor={getContrastColor(this.props.theme.colors.primary)}
                        style={{
                            backgroundColor: this.props.theme.colors.primary,
                            marginRight: 0,
                            paddingLeft: 15,
                            paddingRight: 15,
                        }}
                        uppercase={false}
                        onPress={this.addRecipeToWeeklyPlan}
                        disabled={this.props.loading}
                    >
                        Hinzufügen
                    </Button>
                </Dialog.Actions>
            </Dialog>
        );
    }
}
function mapStateToProps(state) {
    const { settings } = state;
    return { settings };
}

export default connect(mapStateToProps)(withTheme(ChooseRecipeDialog));
