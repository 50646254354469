import moment from "moment";
import React, { Component } from "react";
import { View, Text } from "react-native";
import FontAwesome5Icon from "react-native-vector-icons/FontAwesome5";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { checkIfArticleSizeHasPrices, getContrastColor } from "../../shared/helpers";
import { styles } from "../../shared/styles";
import { UPDATE_ORDER_ITEM } from "../../store/actions/cartActions";
import { store } from "../../store/store";
import { Picker } from "@react-native-picker/picker";
import { cartStyles } from "./CartStyles";
import { Card } from "react-native-paper";

class CartItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: parseInt(this.props.orderItem.entry.amount),
            calculatedPrice: 0,
            selectedArticleSizeId: props.orderItem.ArticleSize.articleSizesId,
        };

        this.articleInformation = this.articleInformation.bind(this);
        this.articlePrice = this.articlePrice.bind(this);
        this.calculatePrice = this.calculatePrice.bind(this);
        this.increaseAmount = this.increaseAmount.bind(this);
        this.decreaseAmount = this.decreaseAmount.bind(this);
        this.updateOrderItem = this.updateOrderItem.bind(this);
        this.calculatePriceByNewSize = this.calculatePriceByNewSize.bind(this);
        this.changeArticleSize = this.changeArticleSize.bind(this);
        this.updateOrderItemByNewSize = this.updateOrderItemByNewSize.bind(this);
    }

    componentDidMount() {
        this.setState({
            calculatedPrice: this.calculatePrice(this.state.amount),
        });
    }
    articleInformation() {
        const { theme } = this.props.settings;
        const day = moment.unix(this.props.orderItem.dayToCook).format("dd DD.MM.YYYY");
        const articleSizesWithPrices = this.props.orderItem.entry.articleSizes.filter((articleSize) =>
            checkIfArticleSizeHasPrices(
                articleSize,
                this.props.orderItem.Price.orderTypesId,
                this.props.orderItem.Price.priceGroupsId
            )
        );
        return (
            <View style={{ width: "100%" }}>
                {articleSizesWithPrices.length > 1 && (
                    <View>
                        <Text style={cartStyles.cartSelectLabel}>Größe</Text>
                        <Picker
                            selectedValue={parseInt(this.state.selectedArticleSizeId, 10)}
                            onValueChange={(itemValue) => this.changeArticleSize(itemValue)}
                            style={[
                                cartStyles.cartSelect,
                                {
                                    backgroundColor: theme.colors.surface,
                                    borderColor: theme.colors.primary,
                                    color: getContrastColor(theme.colors.surface),
                                },
                            ]}
                            key={this.state.selectedArticleSizeId}
                        >
                            {articleSizesWithPrices.map((articleSize, index) => (
                                <Picker.Item
                                    key={index}
                                    label={articleSize.Size.name}
                                    value={parseInt(articleSize.articleSizesId, 10)}
                                />
                            ))}
                        </Picker>
                    </View>
                )}
                {articleSizesWithPrices.length === 1 && (
                    <Text style={cartStyles.cartItemSize}>{this.props.orderItem.ArticleSize.Size.name}</Text>
                )}
                <View style={cartStyles.cartItemAmountBox}>
                    <View style={cartStyles.cartItemAmountBoxButtons}>
                        <FontAwesome5Icon name="minus-circle" onPress={this.decreaseAmount} size={16} />
                        <Text style={cartStyles.cartItemAmountBoxText}>{this.state.amount}</Text>
                        <FontAwesome5Icon name="plus-circle" onPress={this.increaseAmount} size={16} />
                    </View>
                    <View>{this.articlePrice()}</View>
                </View>
            </View>
        );
    }

    calculatePrice(amount) {
        const singlePrice = this.props.orderItem.Price ? this.props.orderItem.Price.price1 : "";

        if (singlePrice) {
            return singlePrice * amount;
        }

        return singlePrice;
    }

    calculatePriceByNewSize(articleSize) {
        if (!articleSize) {
            console.error("Ungültige Artikelgröße in calculatePriceByNewSize");
            return 0;
        }

        var newPrice = articleSize.Prices.find(
            (price) =>
                price.orderTypesId === this.props.orderItem.Price.orderTypesId &&
                price.priceGroupsId === this.props.orderItem.Price.priceGroupsId
        );
        const singlePrice = newPrice ? newPrice.price1 : "";

        if (singlePrice) {
            return singlePrice * this.state.amount;
        }

        return singlePrice;
    }

    changeArticleSize(selectedArticleSizeId) {
        const articleSizeId = parseInt(selectedArticleSizeId, 10);
        const selectedArticleSize = this.props.orderItem.entry.articleSizes.find(
            (size) => size.articleSizesId === articleSizeId
        );

        if (!selectedArticleSize) {
            console.error("Ausgewählte Artikelgröße nicht gefunden.");
            return;
        }

        this.setState(
            {
                selectedArticleSizeId: articleSizeId,
                calculatedPrice: this.calculatePriceByNewSize(selectedArticleSize),
            },
            () => {
                this.updateOrderItemByNewSize(selectedArticleSize);
            }
        );
    }

    increaseAmount() {
        const newAmount = this.state.amount + 1;
        if (newAmount <= this.props.settings.store.maxPortionNumber) {
            this.setState(
                {
                    amount: newAmount,
                    calculatedPrice: this.calculatePrice(newAmount),
                },
                () => this.updateOrderItem(newAmount)
            );
        }
    }

    decreaseAmount() {
        const newAmount = this.state.amount - 1;
        if (newAmount >= 1) {
            this.setState(
                {
                    amount: newAmount,
                    calculatedPrice: this.calculatePrice(newAmount),
                },
                () => this.updateOrderItem(newAmount)
            );
        }
    }

    updateOrderItem(amount) {
        const orderItem = {
            entry: { ...this.props.orderItem.entry, amount: amount },
            weeklyPlanComponent: this.props.orderItem.weeklyPlanComponent,
            weeklyPlanDay: this.props.orderItem.weeklyPlanDay,
            ArticleSize: this.props.orderItem.ArticleSize,
            Price: this.props.orderItem.Price,
            orderItemId: this.props.orderItem.orderItemId,
        };

        store.dispatch({ type: UPDATE_ORDER_ITEM, orderItem });
    }

    updateOrderItemByNewSize(articleSize) {
        var price = articleSize.Prices.find(
            (price) =>
                price.orderTypesId === this.props.orderItem.Price.orderTypesId &&
                price.priceGroupsId === this.props.orderItem.Price.priceGroupsId
        );

        const orderItem = {
            entry: this.props.orderItem.entry,
            weeklyPlanComponent: this.props.orderItem.weeklyPlanComponent,
            weeklyPlanDay: this.props.orderItem.weeklyPlanDay,
            ArticleSize: articleSize,
            Price: price,
            orderItemId: this.props.orderItem.orderItemId,
        };

        store.dispatch({ type: UPDATE_ORDER_ITEM, orderItem });
    }

    articlePrice() {
        return (
            <Text style={cartStyles.cartItemPrice}>
                <NumberFormat
                    value={this.state.calculatedPrice}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator="."
                    suffix=" €"
                    displayType="text"
                />
            </Text>
        );
    }

    render() {
        const { theme } = this.props.settings;
        return (
            <>
                {this.props.orderItem && (
                    <Card
                        testID="orderItem"
                        style={[cartStyles.cartItemCard, { backgroundColor: theme.colors.surface }]}
                        contentStyle={cartStyles.cartItemCardContainer}
                    >
                        {this.props.orderItem.entry && (
                            <Card.Content style={cartStyles.cartItemCardContent}>
                                <Text
                                    numberOfLines={1}
                                    style={[
                                        cartStyles.cartItemTitle,
                                        { color: getContrastColor(theme.colors.surface) },
                                    ]}
                                >
                                    {this.props.orderItem.entry.name}
                                </Text>
                                <View style={cartStyles.cartItemArticleInformation}>{this.articleInformation()}</View>
                                <FontAwesome5Icon
                                    name="times"
                                    color="#000"
                                    size={14}
                                    style={[styles.overlayCloseIcon, cartStyles.cartItemCloseButton]}
                                    onPress={() => {
                                        this.props.removeOrderItem(this.props.orderItem);
                                    }}
                                />
                            </Card.Content>
                        )}
                    </Card>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { settings } = state;
    return { settings };
}

export default connect(mapStateToProps)(CartItem);
