import React from "react";
import { View } from "react-native";
import NumberFormat from "react-number-format";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { Text } from "react-native-paper";

export default function ArticleList(props) {
    return (
        <View style={orderTrackingStyles.articleList}>
            <View>
                <Text variant="headlineMedium">Artikel</Text>
            </View>
            <View>
                {props.cartJson.map((cartItem, index) => {
                    return (
                        <View
                            key={index}
                            style={
                                cartItem.articleCount > 0
                                    ? orderTrackingStyles.cartRow
                                    : orderTrackingStyles.sumToPayRow
                            }
                        >
                            <View style={orderTrackingStyles.articleTitle}>
                                {cartItem.articleCount > 0 && (
                                    <Text style={orderTrackingStyles.text}>{cartItem.articleCount}x</Text>
                                )}
                                <Text style={orderTrackingStyles.text}>{cartItem.name}</Text>
                                {cartItem.comment && (
                                    <View style={orderTrackingStyles.comment}>
                                        <Text style={orderTrackingStyles.text}>{cartItem.comment}</Text>
                                    </View>
                                )}
                                {cartItem.articleCount > 1 && (
                                    <View style={orderTrackingStyles.priceSingle}>
                                        <Text style={orderTrackingStyles.text}>({cartItem.singleArticlePrice}) </Text>
                                    </View>
                                )}
                            </View>
                            <View style={orderTrackingStyles.price}>
                                <View style={orderTrackingStyles.priceTotal}>
                                    <Text style={orderTrackingStyles.text}>
                                        <NumberFormat
                                            value={cartItem.totalArticlePrice / 100}
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator="."
                                            suffix=" €"
                                            displayType="text"
                                        />
                                    </Text>
                                </View>
                            </View>
                        </View>
                    );
                })}
            </View>
        </View>
    );
}
