import React, { PureComponent } from "react";
import { ScrollView, View } from "react-native";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { desktopBreakpoint, withHooksHOC } from "../../shared/helpers";
import Config from "../../../Config";
import { getBiosInstances, setOnlineShopItemType } from "../../store/actions/onlineShopCategoriesActions";
import { store } from "../../store/store";
import ShopHeader from "./ShopHeader";
import { ShopStyles } from "./ShopStyles";
import Store from "./Store";

const config = new Config();

class Shop extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            biosInstances: [],
        };

        this.showAddRecipeToWeeklyPlanPopup = this.showAddRecipeToWeeklyPlanPopup.bind(this);
        this.showAddArticleToCartPopup = this.showAddArticleToCartPopup.bind(this);
        this.cookRecipe = this.cookRecipe.bind(this);
    }

    componentDidMount() {
        store.dispatch(setOnlineShopItemType(this.props.onlineShopItemType));
        store.dispatch(getBiosInstances()).then((result) => {
            if (result) {
                result.push({ url: config.backendHost });

                this.setState({
                    biosInstances: result,
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.onlineShopItemType !== this.props.onlineShopItemType) {
            store.dispatch(setOnlineShopItemType(this.props.onlineShopItemType));
        }
    }

    showAddRecipeToWeeklyPlanPopup(recipe) {
        this.props.showAddRecipeToWeeklyPlanPopup(recipe, this.props.account.customer.customerUid);
    }

    showAddArticleToCartPopup(article) {
        this.props.showAddArticleToCartPopup(
            article,
            this.props.account.customer ? this.props.account.customer.customerUid : undefined,
            this.props.settings.store.orderTypes[0],
            this.props.settings.store.priceGroups[0].priceGroupsId
        );
    }

    cookRecipe(recipe) {
        this.props.cookRecipe(recipe, this.props.account.customer.customerUid);
    }

    render() {
        let mobile = this.props.windowSize[0] < desktopBreakpoint;
        return (
            <ScrollView
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ flex: 1 }}
            >
                <ShopHeader
                    mobile={mobile}
                    showAddRecipeToWeeklyPlanPopup={this.showAddRecipeToWeeklyPlanPopup}
                    showAddArticleToCartPopup={this.showAddArticleToCartPopup}
                    onlineShopItemType={this.props.onlineShopItemType}
                />
                <View style={mobile ? ShopStyles.containerMobile : ShopStyles.container}>
                    <View style={{ width: "100%" }}>
                        {this.state.biosInstances.map((instance) => {
                            return (
                                <Store
                                    url={instance.url}
                                    key={instance.url}
                                    showAddRecipeToWeeklyPlanPopup={this.showAddRecipeToWeeklyPlanPopup}
                                    showAddArticleToCartPopup={this.showAddArticleToCartPopup}
                                    cookRecipe={this.cookRecipe}
                                    onlineShopItemType={this.props.onlineShopItemType}
                                    toggleLoginPopup={this.props.toggleLoginPopup}
                                />
                            );
                        })}
                    </View>
                </View>
            </ScrollView>
        );
    }
}
function mapStateToProps(state) {
    const { settings, account, onlineShopCategories } = state;
    return { settings, account, onlineShopCategories };
}
export default compose(connect(mapStateToProps), withRouter, withHooksHOC)(Shop);
