import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export function getPaymentLink(uid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            uid: uid,
            backendHost: config.backendHost,
            shopUrl: config.shopUrl.slice(-1) != "/" ? config.shopUrl + "/" : config.shopUrl,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Payment/getPaymentLink", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function checkAndBookPayment(tid, uid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            tid: tid,
            uid: uid,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Payment/checkAndBookPayment", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getWebshopPaymentTypes() {
    const requestOptions = {
        method: "GET",
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Payment/getWebshopPaymentTypes", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
