const { StyleSheet } = require("react-native");

export const styles = StyleSheet.create({
    addToCartDialogSelect: {
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 5,
    },
    addToCartDialogSelectLabel: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 15,
    },
    addToCartDialogButton: {
        paddingHorizontal: 15,
        paddingVertical: 2,
        marginLeft: 5,
    },
});
