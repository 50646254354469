import { StyleSheet } from "react-native";
import { Colors } from "react-native-paper";

export const styles = StyleSheet.create({
    contentContainer: {
        flex: 1,
        padding: 10,
    },
    datePicker: {
        width: 320,
        height: 260,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    mainContainer: {
        flex: 1,
        flexDirection: "row",
    },
    screenContainer: {
        flex: 1,
    },
    screenContainerPortal: {
        flex: 1,
        // height: "100vh",
        overflow: "hidden",
    },
    sidebarContainer: {
        width: 250,
    },
    loadingContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "white",
    },
    orderProcessingOverlayContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: 10,
    },
    orderProcessingOverlay: {
        justifyContent: "center",
        alignItems: "center",
        height: 250,
        width: 350,
        padding: 20,
        fontSize: 14,
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "black",
        borderRadius: "13px",
        position: "fixed",
        top: "40%",
    },
    overlayErrorText: {
        marginBottom: 15,
        textAlign: "center",
    },
    overlayErrorIconContainer: {
        padding: 7,
        borderRadius: 20,
    },
    picker: {
        borderWidth: 0,
        minHeight: 40,
        fontSize: 16,
    },
    pickerError: {
        fontSize: 12,
        margin: 5,
    },
    pickerWrapper: {
        borderBottomWidth: 1,
        transform: [{ translateX: 0 }],
        marginBottom: 10,
        width: "100%",
    },
    pickerErrorWrapper: {
        borderBottomWidth: 1,
        marginBottom: 0,
        width: "100%",
        transform: "transform",
    },
    overlayCloseIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 10,
        width: 30,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    backdrop: {
        position: "absolute",
        zIndex: 0,
        width: "100%",
        height: "100%",
    },
    modalContainerStyle: {},
});
