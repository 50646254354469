import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { DataTable, Text } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";
import { formatNumberToLocalString } from "../../../shared/helpers";
export default function NutritionalValues({ data }) {
    const [groupedData, setGroupedData] = useState(null);
    const [selected, setSelected] = useState(["Durchschnittliche Nährwertangaben"]);
    const groupByNutritionalValueGroup = (data) => {
        return data.reduce((acc, item) => {
            const { nutritionalValueGroup } = item.NutritionalValueDummy;
            if (!acc[nutritionalValueGroup]) {
                acc[nutritionalValueGroup] = [];
            }
            acc[nutritionalValueGroup].push(item);
            return acc;
        }, {});
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (data) {
            setGroupedData(groupByNutritionalValueGroup(data));
        }
    }, [data]);

    return (
        <React.Fragment>
            {data.length === 0 && (
                <DataTable>
                    <DataTable.Row>
                        <DataTable.Cell>Keine Daten vorhanden</DataTable.Cell>
                    </DataTable.Row>
                </DataTable>
            )}
            {data.length > 0 && (
                <View style={{ width: "100%" }}>
                    {windowWidth > 800 ? null : (
                        <View
                            style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                marginTop: 15,
                                marginRight: 10,
                            }}
                        >
                            <Text style={{ fontSize: 11 }}>Angaben pro 100 g</Text>
                        </View>
                    )}

                    {groupedData &&
                        Object.keys(groupedData)
                            .sort((a, b) => {
                                if (a === "Durchschnittliche Nährwertangaben") return -1;
                                if (b === "Durchschnittliche Nährwertangaben") return 1;
                                return a.localeCompare(b);
                            })
                            .map((key, index) => {
                                return (
                                    <DataTable key={key} style={{ marginBottom: 20 }}>
                                        <DataTable.Header>
                                            <DataTable.Title
                                                onPress={() =>
                                                    setSelected(
                                                        selected.includes(key)
                                                            ? selected.filter((x) => x !== key)
                                                            : [...selected, key]
                                                    )
                                                }
                                            >
                                                {key && key !== "null" ? key : ""}
                                            </DataTable.Title>
                                            <DataTable.Title
                                                onPress={() =>
                                                    setSelected(
                                                        selected.includes(key)
                                                            ? selected.filter((x) => x !== key)
                                                            : [...selected, key]
                                                    )
                                                }
                                                numeric
                                            >
                                                {selected.includes(key) ? (
                                                    <Ionicons name="chevron-up" style={{ cursor: "pointer" }} />
                                                ) : (
                                                    <Ionicons name="chevron-down" style={{ cursor: "pointer" }} />
                                                )}
                                            </DataTable.Title>
                                            <DataTable.Title style={{ maxWidth: 30, paddingLeft: 5 }}></DataTable.Title>
                                        </DataTable.Header>
                                        {selected.includes(key) &&
                                            groupedData[key].map((item, index) => {
                                                const { NutritionalValue } = item;
                                                const { NutritionalValueDummy } = item;
                                                return (
                                                    <DataTable.Row key={index}>
                                                        <DataTable.Cell>
                                                            {NutritionalValue
                                                                ? NutritionalValue.name
                                                                : NutritionalValueDummy.name}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell numeric>
                                                            {formatNumberToLocalString(item.value)}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell style={{ maxWidth: 40, paddingLeft: 5 }}>
                                                            {NutritionalValue
                                                                ? NutritionalValue.unit
                                                                : NutritionalValueDummy.unit}
                                                        </DataTable.Cell>
                                                    </DataTable.Row>
                                                );
                                            })}
                                    </DataTable>
                                );
                            })}
                </View>
            )}
        </React.Fragment>
    );
}
