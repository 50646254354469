import { store } from "../store";
import { REMOVE_ALL_ORDER_ITEMS } from "./cartActions";
import { handleResponse } from "../../helpers/requestHelpers";
import Config from "../../../Config";

const config = new Config();

export function getWeeklyPlan(instanceUrl, customersUid, date) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid: customersUid, date: date }),
    };
    return function (dispatch) {
        return fetch(instanceUrl.replaceAll("_", ".") + "/OnlineShop/GetCustomerWeeklyPlan", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function getAllCourses() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/GetAllCourses", requestOptions).then(
            (response) => handleResponse(response),
            (err) => console.log(err)
        );
    };
}

export function deleteCustomerCookingProcess(instanceUrl, customersUid, cookingProcessId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid: customersUid, cookingProcessId: cookingProcessId }),
    };
    return function (dispatch) {
        return fetch(
            instanceUrl.replaceAll("_", ".") + "/OnlineShop/deleteCustomerCookingProcess",
            requestOptions
        ).then((response) => handleResponse(response));
    };
}
