import React, { Component } from "react";
import { Card, Title, Text, Avatar } from "react-native-paper";
import { View } from "react-native-web";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import { setOnlineShopItemType } from "../../store/actions/onlineShopCategoriesActions";
import { store } from "../../store/store";
import { Dimensions } from "react-native";
import { desktopBreakpoint, getContrastColor, tabletBreadpoint } from "../../shared/helpers";

/**
 * for Icons visit: https://pictogrammers.com/library/mdi/
 */

class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }
    componentDidMount() {
        store.dispatch(setOnlineShopItemType(-2));
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    render() {
        const { theme } = this.props.settings;
        const { windowWidth } = this.state;
        return (
            <React.Fragment>
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <CustomIconButton
                        icon="arrow-left"
                        tooltip="Zurück"
                        position="right"
                        onPress={() => this.props.history.push("/")}
                    ></CustomIconButton>
                    <Title style={{ marginTop: 10 }}>{"Hallo " + this.props.account.customer.firstName}</Title>
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        maxWidth: "1280px",
                        width: "100%",
                        marginLeft: windowWidth <= desktopBreakpoint ? "auto" : 15,
                        marginRight: windowWidth <= desktopBreakpoint ? "auto" : 15,
                    }}
                >
                    <Card
                        style={{
                            width:
                                windowWidth <= tabletBreadpoint
                                    ? "calc(100% - 10px)"
                                    : windowWidth <= desktopBreakpoint
                                    ? "calc( (100% / 3) - 10px)"
                                    : "calc( (100% / 4) - 10px)",
                            display: "flex",
                            flexWrap: "wrap",
                            margin: 5,
                            cursor: "pointer",
                            minHeight: 200,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onPress={() => this.props.history.push("/customer/addresses/")}
                    >
                        <Card.Title title={"Adressen"} titleStyle={{ textAlign: "center" }} />
                        <Card.Content style={{ margin: "auto" }}>
                            <View
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Avatar.Icon
                                    icon="home-edit"
                                    style={{
                                        backgroundColor: theme.colors.primary,
                                    }}
                                    color={getContrastColor(theme.colors.primary)}
                                    size={38}
                                />
                                <Text style={{ marginTop: 15 }}>Adressen verwalten</Text>
                            </View>
                        </Card.Content>
                    </Card>
                    <Card
                        style={{
                            width:
                                windowWidth <= tabletBreadpoint
                                    ? "calc(100% - 10px)"
                                    : windowWidth <= desktopBreakpoint
                                    ? "calc( (100% / 3) - 10px)"
                                    : "calc( (100% / 4) - 10px)",
                            display: "flex",
                            flexWrap: "wrap",
                            margin: 5,
                            cursor: "pointer",
                            minHeight: 200,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onPress={() => this.props.history.push("/customer/profile/")}
                    >
                        <Card.Title title={"Kundendaten"} titleStyle={{ textAlign: "center" }} />
                        <Card.Content style={{ margin: "auto" }}>
                            <View
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Avatar.Icon
                                    icon="account-edit"
                                    style={{
                                        backgroundColor: theme.colors.primary,
                                    }}
                                    color={getContrastColor(theme.colors.primary)}
                                    size={38}
                                />
                                <Text style={{ marginTop: 15 }}>Daten verwalten</Text>
                            </View>
                        </Card.Content>
                    </Card>
                </View>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withRouter(Customer));
