import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Pressable, Text, View } from "react-native";
import { Paragraph } from "react-native-paper";
import { useSelector } from "react-redux";

export default function DaysNavigation({
    monday,
    onSelect = (day) => {
        console.log(day);
    },
}) {
    const settings = useSelector((state) => state.settings);
    const [selected, setSelected] = useState(1);

    const prevMondayRef = useRef();

    useEffect(() => {
        prevMondayRef.current = moment(monday);
        onSelect(selected);
    }, [selected]);

    if (!monday.isSame(prevMondayRef.current, "week")) {
        prevMondayRef.current = moment(monday);
        setSelected(1);
    }

    return (
        <View
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
                flex: 1,
            }}
        >
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Pressable onPress={() => setSelected(1)}>
                    <Paragraph
                        style={{
                            opacity: selected === 1 ? 1 : 0.5,
                        }}
                    >
                        Mo.
                    </Paragraph>
                    <Paragraph
                        style={{
                            opacity: selected === 1 ? 1 : 0.5,
                        }}
                    >
                        {monday.format("D.M.")}
                    </Paragraph>
                </Pressable>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Pressable onPress={() => setSelected(2)}>
                    <Paragraph
                        style={{
                            opacity: selected === 2 ? 1 : 0.5,
                        }}
                    >
                        Di.
                    </Paragraph>
                    <Paragraph
                        style={{
                            opacity: selected === 2 ? 1 : 0.5,
                        }}
                    >
                        {moment(monday).add(1, "days").format("D.M.")}
                    </Paragraph>
                </Pressable>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Pressable onPress={() => setSelected(3)}>
                    <Paragraph
                        style={{
                            opacity: selected === 3 ? 1 : 0.5,
                        }}
                    >
                        Mi.
                    </Paragraph>
                    <Paragraph
                        style={{
                            opacity: selected === 3 ? 1 : 0.5,
                        }}
                    >
                        {moment(monday).add(2, "days").format("D.M.")}
                    </Paragraph>
                </Pressable>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Pressable onPress={() => setSelected(4)}>
                    <Paragraph
                        style={{
                            opacity: selected === 4 ? 1 : 0.5,
                        }}
                    >
                        Do.
                    </Paragraph>
                    <Paragraph
                        style={{
                            opacity: selected === 4 ? 1 : 0.5,
                        }}
                    >
                        {moment(monday).add(3, "days").format("D.M.")}
                    </Paragraph>
                </Pressable>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Pressable onPress={() => setSelected(5)}>
                    <Paragraph
                        style={{
                            opacity: selected === 5 ? 1 : 0.5,
                        }}
                    >
                        Fr.
                    </Paragraph>
                    <Paragraph
                        style={{
                            opacity: selected === 5 ? 1 : 0.5,
                        }}
                    >
                        {moment(monday).add(4, "days").format("D.M.")}
                    </Paragraph>
                </Pressable>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Pressable onPress={() => setSelected(6)}>
                    <Paragraph
                        style={{
                            opacity: selected === 6 ? 1 : 0.5,
                        }}
                    >
                        Sa.
                    </Paragraph>
                    <Paragraph
                        style={{
                            opacity: selected === 6 ? 1 : 0.5,
                        }}
                    >
                        {moment(monday).add(5, "days").format("D.M.")}
                    </Paragraph>
                </Pressable>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Pressable onPress={() => setSelected(7)}>
                    <Paragraph
                        style={{
                            opacity: selected === 7 ? 1 : 0.5,
                        }}
                    >
                        So.
                    </Paragraph>
                    <Paragraph
                        style={{
                            opacity: selected === 7 ? 1 : 0.5,
                        }}
                    >
                        {moment(monday).add(6, "days").format("D.M.")}
                    </Paragraph>
                </Pressable>
            </View>
        </View>
    );
}
