import React, { Component } from "react";
import { Dimensions, Linking, View, Platform } from "react-native";
import { connect } from "react-redux";

import Config from "../../Config";
import CustomerData from "../screens/customerScreens/CustomerData";
import { checkIfArticleSizeHasPrice, desktopBreakpoint, filterOnlineShopItems } from "../shared/helpers";
import { getBiosInstances } from "../store/actions/onlineShopCategoriesActions";
import { postOrder } from "../store/actions/orderActions";
import { getPaymentLink } from "../store/actions/paymentActions";
import { store } from "../store/store";
import Footer from "./footer/Footer";
import CategoriesHeader from "./header/CategoriesHeader";
import Header from "./header/Header";
import SubHeader from "./header/SubHeader";
import { MainLayoutStyles } from "./MainLayoutStyles";
import Cart from "./sidebars/Cart";
import MobileMenu from "./sidebars/MobileMenu";

const config = new Config();

class MainLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTask: "",
            errorText: "",
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            recipesFound: false,
            biosInstances: [],
        };

        this.createOrder = this.createOrder.bind(this);
        this.handleDoOrder = this.handleDoOrder.bind(this);
        this.redirectCountdown = this.redirectCountdown.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.updateCategories = this.updateCategories.bind(this);
        this.headerHeight = "0px";
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.onlineShopCategories.onlineShopItemType !== this.props.onlineShopCategories.onlineShopItemType) {
            this.updateCategories();
        }
        if (this.headerHeight !== this.prevHeaderHeight) {
            this.props.updateHeaderHeight(this.headerHeight);
            this.prevHeaderHeight = this.headerHeight;
        }
    }

    newHeaderHeight(headerHeight) {
        if (this.headerHeight !== this.prevHeaderHeight) {
            this.props.updateHeaderHeight(this.headerHeight);
            this.prevHeaderHeight = this.headerHeight;
        }
        this.headerHeight = headerHeight;
    }

    updateCategories() {
        store.dispatch(getBiosInstances()).then((result) => {
            if (result) {
                this.setState({ biosInstances: result });
                var categories = [];
                result.push({ url: config.backendHost });
                result.forEach((instance) => {
                    if (
                        this.props.onlineShopCategories["recipes_" + instance.url] &&
                        this.props.onlineShopCategories["recipes_" + instance.url][0]
                    ) {
                        this.props.onlineShopCategories["recipes_" + instance.url][0].forEach((category) => {
                            if (
                                category.showInOnlineShopHeader &&
                                (this.props.onlineShopCategories.onlineShopItemType === -1 ||
                                    category.onlineShopItemType === this.props.onlineShopCategories.onlineShopItemType)
                            ) {
                                categories.push({ ...category, isRecipe: true });
                            }
                        });
                    }
                    if (
                        this.props.onlineShopCategories["articles_" + instance.url] &&
                        this.props.onlineShopCategories["articles_" + instance.url][0]
                    ) {
                        this.props.onlineShopCategories["articles_" + instance.url][0].forEach((category) => {
                            if (
                                category.showInOnlineShopHeader &&
                                (this.props.onlineShopCategories.onlineShopItemType === -1 ||
                                    category.onlineShopItemType === this.props.onlineShopCategories.onlineShopItemType)
                            ) {
                                categories.push({ ...category, isRecipe: false });
                            }
                        });
                    }
                });
                this.setState({
                    categories: categories,
                });
            }
        });
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    handleDoOrder(customerData) {
        const { toggleCustomerData } = this.props;
        toggleCustomerData();
        this.props.setLoading("Bestellung wird aufgenommen", undefined);
        const order = this.createOrder(customerData);
        // console.log(order);
        store
            .dispatch(postOrder(order))
            .then((json) => {
                this.props.setLoading("Bezahlvorgang startet gleich", undefined);
                store
                    .dispatch(getPaymentLink(json.uid))
                    .then((result) => {
                        if (result.result.status_code == 100) {
                            this.props.setLoading(
                                "Du wirst weitergeleitet zum Bezahlvorgang in",
                                undefined,
                                result.result.redirect_url
                            );
                            this.redirectCountdown(result.result.redirect_url);
                        } else {
                            this.props.setLoading(undefined, "Es ist zu einem Fehler beim Bezahlvorgang gekommen");
                        }
                    })
                    .catch(() => {
                        this.props.setLoading(undefined, "Bestellung konnte nicht aufgenommen werden");
                    });
            })
            .catch(() => {
                this.props.setLoading(undefined, "Bestellung konnte nicht aufgenommen werden");
            });
    }

    createOrder(customerData) {
        const { cart, zipCode, customer } = this.props;
        let deliveryDate = zipCode && zipCode.nextDeliveryDate ? zipCode.nextDeliveryDate : "";
        let cartValue = 0;
        cart.orderItems.forEach((item) => (cartValue += item.Price.price1));
        let billingAddress = customerData.billingAddress ? JSON.parse(customerData.billingAddress) : undefined;
        let deliveryAddress = customerData.deliveryAddress ? JSON.parse(customerData.deliveryAddress) : undefined;
        return {
            bonuscodes: [],
            customData: {},
            orderTypesId: 6,
            customDataTemplate: {},
            requestedDeliveryTime: new Date().toISOString(),
            printBill: customerData.printBill,
            printRecipe: customerData.printRecipe,
            comment: customerData.comment,
            deliverLumpSum:
                cartValue < this.props.settings.store.deliverFreeValue ? this.props.settings.store.deliverLumpSum : 0,

            minOrderValue: this.props.settings.store.minOrderValue,

            customer: {
                allowAdvertise: "false",
                customerUid: this.props.account.customer ? this.props.account.customer.customerUid : "",
                azureId: this.props.account.customer ? this.props.account.customer.azureId : "",
                city: deliveryAddress ? deliveryAddress.Street.City.name : customerData.txtCity,
                email: customerData.email,
                title: deliveryAddress ? deliveryAddress.title : customerData.title,
                firstName: deliveryAddress ? deliveryAddress.firstName : customerData.firstName,
                houseNumber: deliveryAddress ? deliveryAddress.housenumber : customerData.housenumber,
                lastName: deliveryAddress ? deliveryAddress.lastName : customerData.lastName,
                phoneNumber: customerData.phoneNumber,
                street: deliveryAddress ? deliveryAddress.Street.name : customerData.txtStreet,
                zipcode: deliveryAddress ? deliveryAddress.Street.City.zipCode : customerData.txtZipCode,
                company: deliveryAddress ? deliveryAddress.company : customerData.company,
                supplement: deliveryAddress ? deliveryAddress.addressDetails : customerData.addressDetails,
                useDifferentBillingAddress: deliveryAddress
                    ? deliveryAddress == billingAddress
                        ? false
                        : true
                    : customerData.useDifferentBillingAddress,
                billingTitle: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.title
                        : deliveryAddress.title
                    : customerData.billingTitle,
                billingFirstName: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.firstName
                        : deliveryAddress.firstName
                    : customerData.billingFirstName,
                billingLastName: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.lastName
                        : deliveryAddress.lastName
                    : customerData.billingLastName,
                billingZipcode: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.Street.City.zipCode
                        : deliveryAddress.Street.City.zipCode
                    : customerData.billingZipCode,
                billingCity: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.Street.City.name
                        : deliveryAddress.Street.City.name
                    : customerData.billingCity,
                billingStreet: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.Street.name
                        : deliveryAddress.Street.name
                    : customerData.billingStreet,
                billingHouseNumber: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.housenumber
                        : deliveryAddress.housenumber
                    : customerData.billingHousenumber,
                billingCompany: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.company
                        : deliveryAddress.company
                    : customerData.billingHousenumber,
                billingSupplement: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.addressDetails
                        : deliveryAddress.addressDetails
                    : customerData.addressDetails,
            },
            orderItems: cart.orderItems.map((item) => ({
                amount: item.entry.amount,
                articlesId: item.entry.id,
                articlesName: item.entry.name,
                price: item.Price.price1,
                requestedDeliveryTime: new Date().toISOString(),
                sizesId: item.ArticleSize.sizesId,
                dayToCook: new Date().toISOString(),
            })),

            paymentBillOptions: 0,
            paymentBillType: 1,
            paymentTypesId: 50,
            storeId: 9999,
        };
    }

    redirectCountdown(url) {
        var counter = setInterval(() => {
            if (this.props.redirectCounter <= 0) {
                if (Platform.OS === "web") {
                    window.location.href = url;
                    clearInterval(counter);
                } else {
                    Linking.canOpenURL(url)
                        .then((canOpen) => {
                            if (canOpen) {
                                Linking.openURL(url).then(() => {
                                    clearInterval(counter);
                                });
                            } else {
                                console.log("Cannot open URL: " + url);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }

            this.props.setRedirectCounter(this.props.redirectCounter - 1);
        }, 1000);
    }

    getIsoTimeString(date) {
        const returnDate = new Date(parseInt(date) * 1000);
        returnDate.setHours(returnDate.getHours() + 5);
        return returnDate.toISOString();
    }

    render() {
        const { windowWidth } = this.state;
        const {
            children,
            toggleLoginPopup,
            logout,
            toggleCustomerData,
            toggleCart,
            toggleMobileMenu,
            cartOpen,
            mobileMenuOpen,
            customerDataOpen,
        } = this.props;

        var filteredCategories = [];
        if (this.state.categories) {
            {
                this.state.categories.forEach((category) => {
                    var filteredOnlineShopItems = filterOnlineShopItems(
                        category.OnlineShopItems,
                        this.props.settings.searchText
                    );
                    if (
                        filteredOnlineShopItems.length > 0 &&
                        this.props.onlineShopCategories.onlineShopItemType > -1 &&
                        this.props.onlineShopCategories.onlineShopItemType === category.onlineShopItemType &&
                        filteredCategories.filter((cat) => cat.categoriesId === category.categoriesId).length === 0
                    ) {
                        filteredCategories.push(category);
                    }
                });
            }
        }

        this.headerHeight =
            windowWidth > desktopBreakpoint
                ? this.props.onlineShopCategories.onlineShopItemType > -1 && filteredCategories.length > 0
                    ? "152px"
                    : "96px"
                : "64px";
        this.newHeaderHeight(this.headerHeight);

        return (
            <View nativeID="mainLayout" style={MainLayoutStyles.container}>
                <Header
                    toggleLoginPopup={toggleLoginPopup}
                    logout={logout}
                    onMenuClick={toggleMobileMenu}
                    onCartClick={toggleCart}
                />
                {windowWidth > desktopBreakpoint && <SubHeader biosInstances={this.state.biosInstances} />}
                {windowWidth > desktopBreakpoint &&
                    this.props.onlineShopCategories.onlineShopItemType > -1 &&
                    filteredCategories.length > 0 && <CategoriesHeader filteredCategories={filteredCategories} />}
                <View
                    style={{
                        height: this.headerHeight,
                    }}
                />
                <MobileMenu
                    open={mobileMenuOpen}
                    onBackdropPress={toggleMobileMenu}
                    toggleLoginPopup={toggleLoginPopup}
                    logout={logout}
                    biosInstances={this.state.biosInstances}
                    filteredCategories={filteredCategories}
                />
                <Cart open={cartOpen} onBackdropPress={toggleCart} toggleCustomerData={toggleCustomerData} />
                <CustomerData
                    open={customerDataOpen}
                    handleSubmit={this.handleDoOrder}
                    hideCustomerData={toggleCustomerData}
                    isRegistration={false}
                    submitButtonName={"Bestellen"}
                    updateCustomer={this.props.updateCustomer}
                />
                {children}
                {windowWidth < desktopBreakpoint && <View style={{ height: "64px" }} />}
                {windowWidth < desktopBreakpoint && (
                    <Footer
                        toggleLoginPopup={toggleLoginPopup}
                        logout={logout}
                        onMenuClick={toggleMobileMenu}
                        onCartClick={toggleCart}
                    />
                )}
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { cart, settings, account, onlineShopCategories } = state;
    return { cart, settings, account, onlineShopCategories };
}
export default connect(mapStateToProps)(MainLayout);
