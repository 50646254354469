import React, { Component } from "react";
import { Dimensions, View } from "react-native";
import { Appbar, Icon, IconButton, Menu, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { withRouter } from "../../routers/routing";
import { footerStyles } from "./FooterStyle";
import Config from "../../../Config";
import { withHooksHOC, getContrastColor } from "../../shared/helpers";
import CustomMenu from "../../helpers/overlayMenu/CustomMenu";
import CustomMenuItem from "../../helpers/overlayMenu/CustomMenuItem";

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    handleLogin() {
        const { account } = this.props;

        if (account.customer) {
            this.props.logout();
        } else {
            this.props.toggleLoginPopup();
        }
    }

    render() {
        const { theme, onMenuClick, account, onCartClick } = this.props;
        return (
            <React.Fragment>
                <Appbar.Header style={[footerStyles.appbar, { backgroundColor: theme.colors.primary }]}>
                    <View nativeID="buttons" style={footerStyles.buttons}>
                        <IconButton
                            iconColor={getContrastColor(theme.colors.primary)}
                            icon="menu"
                            onPress={() => onMenuClick()}
                        />
                        <IconButton
                            iconColor={getContrastColor(theme.colors.primary)}
                            icon="cart"
                            onPress={() => onCartClick()}
                        />
                        {account.customer && (
                            <View style={{ zIndex: 2000 }}>
                                <CustomMenu
                                    anchor={
                                        <Icon
                                            source="account-outline"
                                            size={28}
                                            color={getContrastColor(theme.colors.primary)}
                                        />
                                    }
                                    direction="up"
                                    menuAlignment="center"
                                    menuItemBackgroundColor={theme.colors.accent}
                                    menuItemTextColor={getContrastColor(theme.colors.accent)}
                                >
                                    <CustomMenuItem
                                        onPress={() => this.props.history.push("/customer/")}
                                        title="Mein Konto"
                                    />
                                    <CustomMenuItem onPress={() => this.handleLogin()} title="Logout" />
                                </CustomMenu>
                            </View>
                        )}
                        {!account.customer && (
                            <IconButton
                                iconColor={getContrastColor(theme.colors.primary)}
                                icon="login-variant"
                                onPress={() => this.handleLogin()}
                            />
                        )}
                    </View>
                </Appbar.Header>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(withTheme(Footer))));
