import { Formik } from "formik";
import React, { Component } from "react";
import { Dimensions, ScrollView, StyleSheet, View, Pressable, Text } from "react-native";
import { Button, Card, Checkbox, Portal, RadioButton, Title, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import * as yup from "yup";

import { createYupSchema, desktopBreakpoint, getContrastColor } from "../../shared/helpers";
import { styles } from "../../shared/styles";
import { getWebshopPaymentTypes } from "../../store/actions/paymentActions";
import CustomerCheckoutAddAddress from "./CustomerCheckoutAddAddress";
import { customerDataStyles } from "./CustomerDataStyles";
import CustomInputField from "../../helpers/inputField/CustomInputField";

class CustomerData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            webshopPaymentTypes: [],
            useDifferentBillingAddress: false,
            customerData: null,
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };

        this.formElements = this.formElements.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.customerDataForm = React.createRef();
        this.buildInitialValues = this.buildInitialValues.bind(this);
        this.getPaymentTypes = this.getPaymentTypes.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    componentWillUnmount() {
        document.body.style.overflow = "scroll";
    }
    formElements(props) {
        return this.props.settings.customerData.fields.map((field, index) => {
            const error = props.errors.hasOwnProperty(field.id) && props.errors[field.id];
            if (!this.props.isRegistration || field.showInRegistration) {
                if (field.type === "text") {
                    const { customer } = this.props.account;
                    const { windowWidth } = this.state;
                    let isBillingAddressAttribute = false;

                    switch (field.id) {
                        case "firstName":
                            if (customer) props.values[field.id] = customer.firstName;
                            break;
                        case "lastName":
                            if (customer) props.values[field.id] = customer.familyName;
                            break;
                        case "email":
                            if (customer) props.values[field.id] = customer.email;
                            break;
                        case "phoneNumber":
                            if (customer) props.values[field.id] = customer.phoneNumber;
                            break;
                        case "billingCity":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingStreet":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingHousenumber":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingZipCode":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingTitle":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingFirstName":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingLastName":
                            isBillingAddressAttribute = true;
                            break;
                    }

                    return (
                        ((customer && field.showWhenLoggedIn) ||
                            (!customer && field.showWhenNotLoggedIn && !isBillingAddressAttribute) ||
                            (!customer && isBillingAddressAttribute && props.values["useDifferentBillingAddress"])) && (
                            <View
                                key={field.id}
                                id={field.id}
                                style={
                                    (customerDataStyles.fieldRow,
                                    windowWidth <= desktopBreakpoint
                                        ? customerDataStyles.fieldFormFullRow
                                        : customerDataStyles.fieldFormSplitRow)
                                }
                            >
                                <CustomInputField
                                    key={index}
                                    label={field.placeholder}
                                    disabled={field.disabled}
                                    onChange={props.handleChange(field.id)}
                                    value={props.values[field.id] ? props.values[field.id] : ""}
                                    error={props.errors[field.id] ? true : false}
                                    helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                                />
                            </View>
                        )
                    );
                }
                if (field.type === "password") {
                    return (
                        <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                            <CustomInputField
                                key={index}
                                type="password"
                                label={field.placeholder}
                                disabled={field.disabled}
                                onChange={props.handleChange(field.id)}
                                value={props.values[field.id] ? props.values[field.id] : ""}
                                error={props.errors[field.id] ? true : false}
                                helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                            />
                        </View>
                    );
                }

                if (field.type === "textarea") {
                    return (
                        <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                            <CustomInputField
                                key={index}
                                multiline={true}
                                minRows={5}
                                label={field.placeholder}
                                disabled={field.disabled}
                                onChange={props.handleChange(field.id)}
                                value={props.values[field.id] ? props.values[field.id] : ""}
                                error={props.errors[field.id] ? true : false}
                                helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                            />
                        </View>
                    );
                }

                if (field.type === "printBillCheckbox") {
                    return (
                        <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                            <Checkbox.Item
                                label="Rechnung drucken"
                                status={props.values[field.id] ? "checked" : "unchecked"}
                                onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                                uncheckedColor={this.props.theme.colors.primary}
                                color={getContrastColor(this.props.theme.colors.primary)}
                                style={[
                                    customerDataStyles.checkbox,
                                    {
                                        backgroundColor: props.values[field.id]
                                            ? this.props.theme.colors.primary
                                            : this.props.theme.colors.surface,
                                        borderColor: this.props.theme.colors.primary,
                                    },
                                ]}
                                labelStyle={{
                                    color: props.values[field.id]
                                        ? getContrastColor(this.props.theme.colors.primary)
                                        : getContrastColor(this.props.theme.colors.surface),
                                }}
                                position="leading"
                                rippleColor="transparent"
                                mode="android"
                            />
                        </View>
                    );
                }

                // if (field.type === "printRecipeCheckbox") {
                //     return (
                //         <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                // <Checkbox.Item
                //     label="Rezept drucken"
                //     status={props.values[field.id] ? "checked" : "unchecked"}
                //     onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                //     uncheckedColor={this.props.theme.colors.primary}
                //     color={getContrastColor(this.props.theme.colors.primary)}
                //     style={[
                //         customerDataStyles.checkbox,
                //         {
                //             backgroundColor: props.values[field.id]
                //                 ? this.props.theme.colors.primary
                //                 : this.props.theme.colors.surface,
                //             borderColor: this.props.theme.colors.primary,
                //         },
                //     ]}
                //     labelStyle={{
                //         color: props.values[field.id]
                //             ? getContrastColor(this.props.theme.colors.primary)
                //             : getContrastColor(this.props.theme.colors.surface),
                //     }}
                //     position="leading"
                //     rippleColor="transparent"
                //     mode="android"
                // />;
                //         </View>
                //     );
                // }

                if (field.id === "title") {
                    const { customer } = this.props.account;
                    const [checked, setChecked] = React.useState("Herr");

                    React.useEffect(() => {
                        if (!props.values[field.id]) {
                            props.setFieldValue(field.id, "Herr");
                        }
                    }, [props.values[field.id]]);

                    return (
                        !customer && (
                            <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                                <Text>Anrede</Text>
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <Pressable
                                        onPress={(event) => {
                                            props.setFieldValue(field.id, "Herr");
                                            setChecked("Herr");
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                                            <RadioButton.IOS
                                                value="Herr"
                                                status={checked === "Herr" ? "checked" : "unchecked"}
                                                onPress={(event) => {
                                                    props.setFieldValue(field.id, "Herr");
                                                    setChecked("Herr");
                                                }}
                                            />
                                            <Text>Herr</Text>
                                        </View>
                                    </Pressable>
                                    <Pressable
                                        onPress={(event) => {
                                            props.setFieldValue(field.id, "Frau");
                                            setChecked("Frau");
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                                            <RadioButton.IOS
                                                value="Frau"
                                                status={checked === "Frau" ? "checked" : "unchecked"}
                                                onPress={(event) => {
                                                    props.setFieldValue(field.id, "Frau");
                                                    setChecked("Frau");
                                                }}
                                            />
                                            <Text>Frau</Text>
                                        </View>
                                    </Pressable>
                                    <Pressable
                                        onPress={(event) => {
                                            props.setFieldValue(field.id, "Divers");
                                            setChecked("Divers");
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                                            <RadioButton.IOS
                                                value="Divers"
                                                status={checked === "Divers" ? "checked" : "unchecked"}
                                                onPress={(event) => {
                                                    props.setFieldValue(field.id, "Divers");
                                                    setChecked("Divers");
                                                }}
                                            />
                                            <Text>Divers</Text>
                                        </View>
                                    </Pressable>
                                </View>
                                {error && (
                                    <View>
                                        <Text style={styles.pickerError}>
                                            {props.errors[field.id] ? props.errors[field.id] : ""}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        )
                    );
                }
                if (field.type === "useDifferentBillingAddress") {
                    return (
                        <View key={field.id} id={field.id} style={[customerDataStyles.fieldRow, { marginTop: 15 }]}>
                            <Checkbox.Item
                                label="Abweichende Rechnungsadresse"
                                status={props.values[field.id] ? "checked" : "unchecked"}
                                onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                                uncheckedColor={this.props.theme.colors.primary}
                                color={getContrastColor(this.props.theme.colors.primary)}
                                style={[
                                    customerDataStyles.checkbox,
                                    {
                                        backgroundColor: props.values[field.id]
                                            ? this.props.theme.colors.primary
                                            : this.props.theme.colors.surface,
                                        borderColor: this.props.theme.colors.primary,
                                    },
                                ]}
                                labelStyle={{
                                    color: props.values[field.id]
                                        ? getContrastColor(this.props.theme.colors.primary)
                                        : getContrastColor(this.props.theme.colors.surface),
                                }}
                                position="leading"
                                rippleColor="transparent"
                                mode="android"
                            />
                        </View>
                    );
                }

                if (field.type === "selectAddress") {
                    const { customer } = this.props.account;
                    const { theme } = this.props;
                    const { windowWidth } = this.state;
                    var tempAddresses = [];
                    if (customer) {
                        tempAddresses = customer.addresses;
                    }
                    const [selectedAddress, setSelectedAddress] = React.useState(0);

                    const sortedDeliveryAddresses = tempAddresses.sort((a, b) => {
                        if (a.isDefaultDeliveryAddress) return -1;
                        if (b.isDefaultDeliveryAddress) return 1;
                        return a.addressId - b.addressId;
                    });

                    const sortedBillingAddresses = tempAddresses.sort((a, b) => {
                        if (a.isDefaultBillingAddress) return -1;
                        if (b.isDefaultBillingAddress) return 1;
                        return a.addressId - b.addressId;
                    });

                    return (
                        customer &&
                        (field.id === "deliveryAddress" || props.values["useDifferentBillingAddress"]) && (
                            <View key={field.id} id={field.id} style={{ width: "100%" }}>
                                <Text style={{ width: "100%", marginBottom: 15, marginLeft: 10, fontWeight: "bold" }}>
                                    {field.id === "deliveryAddress" ? "Lieferadresse" : "Rechnungsadresse"}
                                </Text>
                                <View
                                    style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%" }}
                                >
                                    {sortedDeliveryAddresses &&
                                        sortedDeliveryAddresses.map((address, index) => {
                                            return (
                                                <Card
                                                    onPress={(event) => {
                                                        props.setFieldValue(field.id, JSON.stringify(address));
                                                        setSelectedAddress(index);
                                                    }}
                                                    style={{
                                                        backgroundColor:
                                                            selectedAddress === index
                                                                ? theme.colors.primary
                                                                : theme.colors.surface,
                                                        borderColor: theme.colors.primary,
                                                        borderWidth: "1px",
                                                        borderStyle: "solid",
                                                        width:
                                                            windowWidth <= 768
                                                                ? "calc(100% - 10px)"
                                                                : "calc(33% - 10px)",
                                                        display: "flex",
                                                        margin: 5,
                                                        cursor: "pointer",
                                                    }}
                                                    key={index}
                                                >
                                                    <Card.Title
                                                        titleStyle={{
                                                            color:
                                                                selectedAddress === index
                                                                    ? getContrastColor(theme.colors.primary)
                                                                    : theme.colors.onSurface,
                                                        }}
                                                        title={address.firstName + " " + address.lastName}
                                                    />
                                                    <Card.Content>
                                                        <Text
                                                            style={{
                                                                color:
                                                                    selectedAddress === index
                                                                        ? getContrastColor(theme.colors.primary)
                                                                        : theme.colors.onSurface,
                                                            }}
                                                        >
                                                            {address.Street.name +
                                                                " " +
                                                                address.housenumber +
                                                                "\n" +
                                                                address.Street.City.zipCode +
                                                                " " +
                                                                address.Street.City.name +
                                                                "\n\n" +
                                                                (address.company ? address.company : "\n") +
                                                                "\n" +
                                                                (address.addressDetails
                                                                    ? address.addressDetails
                                                                    : "\n")}
                                                        </Text>
                                                    </Card.Content>
                                                </Card>
                                            );
                                        })}
                                    <CustomerCheckoutAddAddress update={this.props.updateCustomer} />
                                </View>
                            </View>
                        )
                    );
                }
            }

            return "";
        });
    }

    async buildInitialValues() {
        const initialValues = {};
        const { customer } = this.props.account;

        await this.props.settings.customerData.fields.forEach((field) => {
            let initialValue = field.value || "";
            if (field.id === "printBill" || field.id === "printRecipe") {
                initialValue = false;
            } else if (field.id === "billingAddress") {
                if (customer && customer.addresses) {
                    let address = customer.addresses.filter((address) => address.isDefaultBillingAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else if (field.id === "deliveryAddress") {
                if (customer && customer.addresses) {
                    let address = customer.addresses.filter((address) => address.isDefaultDeliveryAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else {
                if (this.props.account.customer) {
                    initialValue = this.props.account.customer[field.id]
                        ? this.props.account.customer[field.id]
                        : initialValue;
                }
            }

            initialValues[field.id] = field.value || initialValue;
        });

        if (this.props.account.customer) {
            if (this.customerDataForm.current) {
                this.customerDataForm.current.setValues(initialValues, false);
            }
        }
        return initialValues;
    }

    getPaymentTypes() {
        let paymentTypes = getWebshopPaymentTypes();
        if (paymentTypes) this.setState({ webshopPaymentTypes: paymentTypes });
    }

    render() {
        const { open, theme, hideCustomerData, mobile } = this.props;
        const { windowWidth } = this.state;

        const { customer } = this.props.account;
        const initialValues = this.buildInitialValues();
        const yepSchema = this.props.settings.customerData.fields.reduce(createYupSchema, {});
        const validateSchema = yup.object().shape(yepSchema);
        return (
            <Portal
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "100px",
                    height: "auto",
                    overflow: "hidden",
                }}
            >
                {open && (
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: "80vh",
                            position: "fixed",
                            top: "10vh",
                            left: windowWidth <= desktopBreakpoint ? "5%" : "23%",
                            width: windowWidth <= desktopBreakpoint ? "90%" : 850,
                            zIndex: 1,
                            overflow: "hidden",
                            backgroundColor: theme.colors.surface,
                        }}
                    >
                        <ScrollView style={customerDataStyles.container}>
                            <View style={{ width: windowWidth <= desktopBreakpoint ? "100%" : 850, height: "80vh" }}>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validateSchema}
                                    onSubmit={(values) => {
                                        this.props.handleSubmit(values);
                                    }}
                                    innerRef={this.customerDataForm}
                                >
                                    {(props) => (
                                        <View style={customerDataStyles.fieldsWrapper}>
                                            <Title>Kundendaten</Title>
                                            {this.formElements(props)}
                                            <View style={{ width: "100%" }}></View>
                                            <View
                                                style={
                                                    windowWidth <= desktopBreakpoint
                                                        ? customerDataStyles.containerFullRow
                                                        : customerDataStyles.containerSplitRow
                                                }
                                            >
                                                <Button
                                                    textColor={getContrastColor(theme.colors.primary)}
                                                    style={{
                                                        backgroundColor: theme.colors.notification,
                                                        marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                                                        marginBottom: windowWidth <= desktopBreakpoint ? 10 : 10,
                                                        width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                                                    }}
                                                    onPress={hideCustomerData}
                                                    uppercase={false}
                                                >
                                                    Abbrechen
                                                </Button>

                                                <Button
                                                    textColor={getContrastColor(theme.colors.primary)}
                                                    style={{
                                                        backgroundColor: theme.colors.primary,
                                                        marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                                                        marginBottom: windowWidth <= desktopBreakpoint ? 10 : 10,
                                                        width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                                                    }}
                                                    onPress={() => {
                                                        this.props.handleSubmit(props.values);
                                                    }}
                                                    uppercase={false}
                                                >
                                                    {this.props.submitButtonName}
                                                </Button>
                                            </View>
                                        </View>
                                    )}
                                </Formik>
                            </View>
                        </ScrollView>
                    </View>
                )}
                {open && (
                    <Pressable onPress={hideCustomerData} style={StyleSheet.absoluteFill}>
                        <View
                            style={{ ...StyleSheet.flatten(styles.backdrop), backgroundColor: theme.colors.backdrop }}
                        ></View>
                    </Pressable>
                )}
            </Portal>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, zipCode } = state;
    return { settings, account, zipCode };
}

export default connect(mapStateToProps)(withTheme(CustomerData));
