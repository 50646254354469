import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, Card, Text, TextInput } from "react-native-paper";
import { View } from "react-native-web";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import { updateOnlineShopCustomer } from "../../store/actions/accountActions";
import { store } from "../../store/store";
import CustomInputField from "../../helpers/inputField/CustomInputField";
import { getContrastColor } from "../../shared/helpers";
import * as yup from "yup";

const yupObject = yup.object().shape({
    firstName: yup.string().required("Bitte einen Vornamen eingeben"),
    familyName: yup.string().required("Bitte einen Nachnamen eingeben"),
    phoneNumber: yup.string().required("Bitte eine Telefonnummer eingeben"),
});

class CustomerProfile extends Component {
    render() {
        const { customer } = this.props.account;
        const { theme } = this.props.settings;
        return (
            <View>
                <CustomIconButton
                    icon="arrow-left"
                    tooltip="Zurück"
                    position="right"
                    onPress={() => this.props.history.push("/customer/")}
                ></CustomIconButton>
                <View
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Card style={{ width: 350, borderRadius: 13, marginTop: 50, marginBottom: 50 }}>
                        <Formik
                            initialValues={{
                                firstName: customer.firstName,
                                familyName: customer.familyName,
                                email: customer.email,
                                phoneNumber: customer.phoneNumber,
                            }}
                            validationSchema={yupObject}
                            onSubmit={(values) => {
                                toast.show("Änderungen werden gespeichert...", { type: "info" });

                                store
                                    .dispatch(
                                        updateOnlineShopCustomer(
                                            customer.customerUid,
                                            customer.azureId,
                                            values.firstName,
                                            values.familyName,
                                            values.phoneNumber
                                        )
                                    )
                                    .then((response) => {
                                        toast.show("Änderungen wurden gespeichert", { type: "success" });
                                        this.props.update(customer.azureId);
                                    });
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: 30,
                                    }}
                                >
                                    <CustomInputField
                                        label="Vorname"
                                        onChange={handleChange("firstName")}
                                        value={values.firstName}
                                        error={errors.firstName ? true : false}
                                        helperText={errors.firstName ? errors.firstName : ""}
                                    />

                                    <CustomInputField
                                        label="Nachname"
                                        onChange={handleChange("familyName")}
                                        value={values.familyName}
                                        error={errors.familyName ? true : false}
                                        helperText={errors.familyName ? errors.familyName : ""}
                                    />

                                    <CustomInputField
                                        label="E-Mail"
                                        disabled={true}
                                        type="email"
                                        onChange={handleChange("email")}
                                        value={values.email}
                                    />

                                    <CustomInputField
                                        label="Telefonnummer"
                                        onChange={handleChange("phoneNumber")}
                                        value={values.phoneNumber}
                                        error={errors.phoneNumber ? true : false}
                                        helperText={errors.phoneNumber ? errors.phoneNumber : ""}
                                    />

                                    <Button
                                        type="submit"
                                        onPress={handleSubmit}
                                        textColor={getContrastColor(theme.colors.primary)}
                                        style={{
                                            backgroundColor: theme.colors.primary,
                                            textTransform: "none",
                                            marginTop: 50,
                                            marginBottom: 50,
                                        }}
                                        uppercase={false}
                                    >
                                        Änderungen speichern
                                    </Button>
                                </View>
                            )}
                        </Formik>
                    </Card>
                </View>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withRouter(CustomerProfile));
