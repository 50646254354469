import React, { Component, PureComponent } from "react";
import { FlatList, View } from "react-native";
import { IconButton, Title } from "react-native-paper";
import { withRouter } from "react-router";
import { getCategoryById, getRecommendationsForOnlineShop } from "../../store/actions/onlineShopCategoriesActions";
import { store } from "../../store/store";
import ShopItem from "./ShopItem";
import { ShopStyles } from "./ShopStyles";
import { connect } from "react-redux";
import { desktopBreakpoint, filterOnlineShopItems, useWindowSize, withHooksHOC } from "../../shared/helpers";

class FullCategory extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            category: undefined,
            numberOfTiles: 0,
            hoveredItem: undefined,
        };

        this.setHoveredItem = this.setHoveredItem.bind(this);
        this.renderCell = this.renderCell.bind(this);
        this.showAddRecipeToWeeklyPlanPopup = this.showAddRecipeToWeeklyPlanPopup.bind(this);
        this.showAddArticleToCartPopup = this.showAddArticleToCartPopup.bind(this);
        this.cookRecipe = this.cookRecipe.bind(this);
        this.loadOnlineShopItems = this.loadOnlineShopItems.bind(this);
    }

    componentDidMount() {
        this.loadOnlineShopItems();
    }

    componentDidUpdate() {
        if (this.props) {
            if (
                this.state.id !== this.props.match.params.id ||
                this.state.backend !== this.props.match.params.backend
            ) {
                this.loadOnlineShopItems();
            }
        }
    }

    loadOnlineShopItems() {
        const { params } = this.props.match;
        if (
            this.props.onlineShopCategories.currentCategory &&
            this.props.onlineShopCategories.currentCategory.categoriesId !== parseInt(this.props.match.params.id)
        ) {
            return;
        }

        this.setState({
            id: params.id,
            backend: params.backend,
        });
        if (this.props.onlineShopCategories.currentCategory) {
            this.setState({
                category: this.props.onlineShopCategories.currentCategory,
            });
        } else {
            if (params.id >= 0)
                store.dispatch(getCategoryById(decodeURIComponent(params.backend), params.id)).then((result) => {
                    if (result) this.setState({ category: result });
                });
            else
                store.dispatch(getRecommendationsForOnlineShop(decodeURIComponent(params.backend))).then((result) => {
                    {
                        if (result)
                            this.setState({
                                category: result.filter((category) => category.categoriesId == params.id)[0],
                            });
                    }
                });
        }
    }

    showAddRecipeToWeeklyPlanPopup(recipe) {
        this.props.showAddRecipeToWeeklyPlanPopup(recipe, this.props.account.customer.customerUid);
    }

    showAddArticleToCartPopup(article) {
        this.props.showAddArticleToCartPopup(
            article,
            this.props.account.customer ? this.props.account.customer.customerUid : undefined,
            this.props.settings.store.orderTypes[0],
            this.props.settings.store.priceGroups[0].priceGroupsId
        );
    }

    cookRecipe(recipe) {
        this.props.cookRecipe(recipe, this.props.account.customer.customerUid);
    }

    setHoveredItem(id) {
        this.setState({
            hoveredItem: id,
        });
    }

    renderCell({ index, style, ...props }) {
        const zIndex = {
            zIndex: this.state.hoveredItem && props.item.id === this.state.hoveredItem ? 2 : 0,
        };
        const marginTop = {
            marginTop: 40,
        };
        return <View style={[style, zIndex, marginTop]} {...props} />;
    }

    render() {
        const { params } = this.props.match;
        const { history } = this.props;
        let mobile = this.props.windowSize[0] < desktopBreakpoint;
        var filteredOnlineShopItems = this.state.category
            ? filterOnlineShopItems(this.state.category.OnlineShopItems, this.props.settings.searchText)
            : [];
        var columnCount = Math.floor((this.props.windowSize[0] - 90) / 320);
        return (
            <View>
                {this.state.category && (
                    <View style={ShopStyles.CategoryContainer}>
                        <View style={ShopStyles.CategoryRow}>
                            <IconButton icon="arrow-left" onPress={() => history.push("/")} />
                            <Title>{this.state.category.name}</Title>
                        </View>
                        <View style={{ overflow: "visible" }}>
                            {columnCount > 1 && (
                                <FlatList
                                    data={filteredOnlineShopItems}
                                    showsHorizontalScrollIndicator={false}
                                    contentContainerStyle={{
                                        marginBottom: 50,
                                        marginLeft: 50,
                                        marginRight: 50,
                                        alignItems: "center",
                                        overflow: "visible",
                                    }}
                                    style={{ overflowX: "visible", overflowY: "visible" }}
                                    numColumns={columnCount}
                                    vertical
                                    CellRendererComponent={this.renderCell}
                                    columnWrapperStyle={{
                                        justifyContent: "space-between",
                                        flexWrap: "wrap",
                                        flex: 1,
                                    }}
                                    renderItem={(item) => (
                                        <ShopItem
                                            item={item}
                                            setHoveredItem={this.setHoveredItem}
                                            url={this.state.category.url}
                                            mobile={mobile}
                                            hoveredItem={this.state.hoveredItem}
                                            showAddRecipeToWeeklyPlanPopup={this.showAddRecipeToWeeklyPlanPopup}
                                            showAddArticleToCartPopup={this.showAddArticleToCartPopup}
                                            cookRecipe={this.cookRecipe}
                                        />
                                    )}
                                    key={columnCount}
                                />
                            )}
                            {columnCount <= 1 && (
                                <FlatList
                                    data={this.state.category.OnlineShopItems}
                                    showsHorizontalScrollIndicator={false}
                                    contentContainerStyle={{
                                        marginBottom: 50,
                                        marginLeft: 50,
                                        marginRight: 50,
                                        alignItems: "center",
                                        overflow: "visible",
                                    }}
                                    style={{ overflowX: "visible", overflowY: "visible" }}
                                    numColumns={columnCount}
                                    vertical
                                    CellRendererComponent={this.renderCell}
                                    renderItem={(item) => (
                                        <ShopItem
                                            item={item}
                                            setHoveredItem={this.setHoveredItem}
                                            url={this.state.category.url}
                                            mobile={mobile}
                                            hoveredItem={this.state.hoveredItem}
                                            showAddRecipeToWeeklyPlanPopup={this.showAddRecipeToWeeklyPlanPopup}
                                            showAddArticleToCartPopup={this.showAddArticleToCartPopup}
                                            cookRecipe={this.cookRecipe}
                                        />
                                    )}
                                    key={columnCount}
                                />
                            )}
                        </View>
                    </View>
                )}
            </View>
        );
    }
}
function mapStateToProps(state) {
    const { onlineShopCategories, account, settings } = state;
    return { onlineShopCategories, account, settings };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(FullCategory)));
