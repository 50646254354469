import React from "react";
import { View } from "react-native";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { Text } from "react-native-paper";

export default function CustomerInformations(props) {
    const { customer } = props;
    return (
        <View>
            <View>
                <Text variant="headlineSmall">Kundendaten</Text>
            </View>
            <View style={orderTrackingStyles.text}>
                <Text style={orderTrackingStyles.text}>{customer.fullName}</Text>
                {customer.company && <Text>{customer.company}</Text>}
                <Text style={orderTrackingStyles.text}>
                    {customer.txtStreet} {customer.housenumber}
                </Text>
                <Text style={orderTrackingStyles.text}>
                    {customer.txtZipCode} {customer.txtCity}
                </Text>
                <Text style={orderTrackingStyles.text}>{customer.email}</Text>
                {customer.phoneNumbers && <Text>{customer.phoneNumbers}</Text>}
            </View>
            {customer.useDifferentBillingAddress && (
                <View style={orderTrackingStyles.billingAddress}>
                    <Text variant="headlineSmall" style={orderTrackingStyles.text}>
                        Rechnungsadresse
                    </Text>
                    <Text style={orderTrackingStyles.text}>{customer.billingFullName}</Text>
                    {customer.billingCompany && <Text>{customer.billingCompany}</Text>}
                    <Text style={orderTrackingStyles.text}>
                        {customer.billingStreet} {customer.billingHousenumber}
                    </Text>
                    <Text style={orderTrackingStyles.text}>
                        {customer.billingZipcode} {customer.billingCity}
                    </Text>
                </View>
            )}
        </View>
    );
}
