import React, { Component } from "react";
import { Button, DataTable, IconButton, withTheme } from "react-native-paper";
import { withHooksHOC } from "../../../shared/helpers";

class Ingredients extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ingredientAdded: false,
            selectedIndex: undefined,
        };

        this.addIngredientToCart = this.addIngredientToCart.bind(this);
    }

    async addIngredientToCart(article, index) {
        this.setState({ ingredientAdded: true, selectedIndex: index });
        await delay(500);
        this.setState({ ingredientAdded: false, selectedIndex: index });
        this.props.addIngredientToCart(article);
    }

    render() {
        var addToCardButtonsVisible = false;
        const { windowSize, theme } = this.props;
        const mobile = windowSize[0] < 700;
        if (this.props.data) {
            this.props.data.forEach((item) => {
                if (
                    item.article &&
                    item.article.webshopEnabled &&
                    item.article.ArticleSizes.find((size) => size.Size && !size.Size.isDefault) &&
                    item.article.ArticleSizes.find((size) => size.Size && !size.Size.isDefault).Prices.length > 0
                ) {
                    addToCardButtonsVisible = true;
                }
            });
        }

        return (
            <DataTable>
                {this.props.data && this.props.data.length === 0 && (
                    <DataTable.Row>
                        <DataTable.Cell>Keine</DataTable.Cell>
                    </DataTable.Row>
                )}
                {this.props.data && this.props.data.length > 0 && (
                    <>
                        {this.props.isArticle &&
                            this.props.data.map((item, index) => (
                                <DataTable.Row key={index}>
                                    <DataTable.Cell>{item.name}</DataTable.Cell>
                                    {addToCardButtonsVisible && !this.state.ingredientAdded && (
                                        <DataTable.Cell style={{ display: "flex", justifyContent: "center" }}>
                                            {item.article &&
                                                item.article.webshopEnabled &&
                                                item.article.ArticleSizes.find(
                                                    (size) => size.Size && !size.Size.isDefault
                                                ) &&
                                                item.article.ArticleSizes.find(
                                                    (size) => size.Size && !size.Size.isDefault
                                                ).Prices.length > 0 && (
                                                    <React.Fragment>
                                                        {!mobile && (
                                                            <Button
                                                                icon="plus"
                                                                mode="contained"
                                                                onPress={() =>
                                                                    this.addIngredientToCart(item.article, index)
                                                                }
                                                            >
                                                                in den Warenkorb
                                                            </Button>
                                                        )}
                                                        {mobile && (
                                                            <IconButton
                                                                icon="cart"
                                                                iconColor={theme.colors.primary}
                                                                onPress={() =>
                                                                    this.addIngredientToCart(item.article, index)
                                                                }
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                )}
                                        </DataTable.Cell>
                                    )}
                                    {addToCardButtonsVisible &&
                                        this.state.ingredientAdded &&
                                        this.state.selectedIndex === index && (
                                            <DataTable.Cell style={{ display: "flex", justifyContent: "center" }}>
                                                <React.Fragment>
                                                    {!mobile && (
                                                        <Button icon="check" mode="contained">
                                                            hinzugefügt
                                                        </Button>
                                                    )}
                                                    {mobile && (
                                                        <IconButton icon="check" iconColor={theme.colors.primary} />
                                                    )}
                                                </React.Fragment>
                                            </DataTable.Cell>
                                        )}
                                </DataTable.Row>
                            ))}
                        {!this.props.isArticle && (
                            <DataTable.Header>
                                <DataTable.Title>Name</DataTable.Title>
                                {addToCardButtonsVisible && <DataTable.Title></DataTable.Title>}
                                <DataTable.Title numeric>Menge</DataTable.Title>
                            </DataTable.Header>
                        )}
                        {!this.props.isArticle &&
                            this.props.data.map((item, index) => {
                                return (
                                    <DataTable.Row key={index}>
                                        <DataTable.Cell>{item.name}</DataTable.Cell>
                                        {addToCardButtonsVisible &&
                                            (!this.state.ingredientAdded || this.state.selectedIndex !== index) && (
                                                <DataTable.Cell style={{ display: "flex", justifyContent: "center" }}>
                                                    {item.article &&
                                                        item.article.webshopEnabled &&
                                                        item.article.ArticleSizes.find(
                                                            (size) => size.Size && !size.Size.isDefault
                                                        ) &&
                                                        item.article.ArticleSizes.find(
                                                            (size) => size.Size && !size.Size.isDefault
                                                        ).Prices.length > 0 && (
                                                            <React.Fragment>
                                                                {!mobile && (
                                                                    <Button
                                                                        icon="plus"
                                                                        mode="contained"
                                                                        onPress={() =>
                                                                            this.addIngredientToCart(
                                                                                item.article,
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        in den Warenkorb
                                                                    </Button>
                                                                )}
                                                                {mobile && (
                                                                    <IconButton
                                                                        icon="cart"
                                                                        iconColor={theme.colors.primary}
                                                                        onPress={() =>
                                                                            this.addIngredientToCart(
                                                                                item.article,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                </DataTable.Cell>
                                            )}
                                        {addToCardButtonsVisible &&
                                            this.state.ingredientAdded &&
                                            this.state.selectedIndex === index && (
                                                <DataTable.Cell style={{ display: "flex", justifyContent: "center" }}>
                                                    <React.Fragment>
                                                        {!mobile && (
                                                            <Button icon="check" mode="contained">
                                                                hinzugefügt
                                                            </Button>
                                                        )}
                                                        {mobile && (
                                                            <IconButton icon="check" iconColor={theme.colors.primary} />
                                                        )}
                                                    </React.Fragment>
                                                </DataTable.Cell>
                                            )}
                                        <DataTable.Cell numeric>{item.grammage} g</DataTable.Cell>
                                    </DataTable.Row>
                                );
                            })}
                    </>
                )}
            </DataTable>
        );
    }
}

function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export default withHooksHOC(withTheme(Ingredients));
