import { StyleSheet } from "react-native";

export const cartStyles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 5,
        minWidth: 190,
    },
    articleContainer: {
        flexGrow: 1,
        paddingBottom: 10,
    },
    emptyCartText: {
        marginHorizontal: 15,
        marginVertical: 20,
    },
    cartSelect: {
        paddingHorizontal: 7,
        paddingVertical: 4,
        borderRadius: 5,
        marginBottom: 10,
    },
    cartSelectLabel: {
        marginBottom: 5,
        fontSize: 12,
    },
    cartItemCard: {
        borderRadius: 10,
        marginBottom: 15,
    },
    cartItemSize: {
        fontSize: 12,
        marginBottom: 10,
    },
    cartItemCardContainer: {
        padding: 15,
        position: "relative",
    },
    cartItemCardContent: {
        paddingHorizontal: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
    },
    cartItemTitle: {
        fontSize: 14,
        fontWeight: "bold",
        marginBottom: 10,
    },
    cartItemAmountBox: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    cartItemAmountBoxButtons: {
        flexDirection: "row",
        alignItems: "center",
    },
    cartItemAmountBoxText: {
        marginHorizontal: 7,
    },
    cartItemPrice: {
        fontSize: 14,
        fontWeight: "bold",
    },

    cartItemCloseButton: {
        top: -10,
        right: -10,
        width: 23,
        height: 23,
        position: "absolute",
    },
});
