import React, { Component } from "react";
import { Dimensions, Pressable, StyleSheet, View } from "react-native";
import { Appbar, Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";

import { Link, withRouter } from "../../routers/routing";
import { desktopBreakpoint, getContrastColor, withHooksHOC } from "../../shared/helpers";
import { setCurrentCategory } from "../../store/actions/onlineShopCategoriesActions";
import { store } from "../../store/store";
import { headerStyles } from "./HeaderStyle";

class CategoriesHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    render() {
        let mobile = this.props.windowSize[0] < desktopBreakpoint;
        const { theme } = this.props;
        return (
            <React.Fragment>
                <Appbar.Header
                    style={{
                        ...StyleSheet.flatten(headerStyles.appcategoriesbar),
                        backgroundColor: theme.colors.surface,
                    }}
                >
                    <View nativeID="menu" style={headerStyles.submenu}>
                        <View nativeID="links" style={headerStyles.links}>
                            {this.props.filteredCategories.map((category) => {
                                return (
                                    <Pressable
                                        key={category.categoriesId + "_" + category.url + "_" + category.isRecipe}
                                    >
                                        <Text>
                                            <Link
                                                style={{
                                                    ...StyleSheet.flatten(headerStyles.link),
                                                    color: getContrastColor(theme.colors.surface),
                                                }}
                                                to={
                                                    "/category/" +
                                                    category.categoriesId +
                                                    "/" +
                                                    encodeURIComponent(category.url)
                                                }
                                                onClick={() => store.dispatch(setCurrentCategory(category))}
                                            >
                                                {category.name}
                                            </Link>
                                        </Text>
                                    </Pressable>
                                );
                            })}
                        </View>
                    </View>
                </Appbar.Header>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, onlineShopCategories } = state;
    return { settings, account, onlineShopCategories };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(withTheme(CategoriesHeader))));
