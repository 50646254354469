import React from "react";
import { View } from "react-native";
import { Divider, Headline, IconButton, Paragraph } from "react-native-paper";
import { useSelector } from "react-redux";
import moment from "moment";

export default function Header({ monday, onNextWeek, onPrevWeek }) {
    const settings = useSelector((state) => state.settings);
    return (
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <IconButton icon={"chevron-left"} size={30} onPress={onPrevWeek} style={{ margin: 0 }} />
            <View style={{ alignItems: "center", paddingTop: 14 }}>
                <Paragraph>Woche (KW {monday.format("w")})</Paragraph>
                <Headline>
                    {monday.format("DD.MM.")} - {moment(monday).day(7).format("DD.MM.")}
                </Headline>
            </View>
            <IconButton icon={"chevron-right"} size={30} onPress={onNextWeek} style={{ margin: 0 }} />
        </View>
    );
}
